import React, {useState} from "react";
import PropTypes from "prop-types";

export default function Facet(props) {
  const [show, setShow] = useState(props.shownByDefault);

  return (
    <div className="accordion-item">
      <h2 className="accordion-header">
        <button
          className={show ? "accordion-button" : "accordion-button collapsed"}
          type="button"
          onClick={() => setShow(!show)}
        >
          {props.title}
        </button>
      </h2>
      <div id={props.title.toLowerCase().replace(" ", "-")} className={show ? "accordion-collapse" : "accordion-collapse collapse"}>
        <div className="accordion-body">
          {props.facetOptions}
        </div>
      </div>
    </div>
  );
}

Facet.propTypes = {
  shownByDefault: PropTypes.bool,
  title: PropTypes.string,
  facetOptions: PropTypes.array,
}