import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import PropTypes from "prop-types";
import Helpers from "../../../services/Helpers";
import Constants from "../../../services/Constants";

export default function MagicMealPlanForm(props) {
  let history = useHistory();
  const [name, setName] = useState("");
  const { makeUrlLookGood } = Helpers();
  const { MP_NAME_CAP: mpNameCap } = Constants();

  function handleSubmit(e) {
    e.preventDefault();
    props.create({ name: name }, (id) => {
      history.push(`/meal-plans/meal-planner/${makeUrlLookGood(id, name)}`);
    });
  }

  return (
    <div className="py-4 px-3 py-md-5 px-md-5 create-plan shadow-sm rounded">
      <h3 className="h-alt">Create a Meal Plan</h3>
      <form onSubmit={handleSubmit} className="shadow-sm">
        <div className="input-group search-wrap">
          <input
            type="text"
            className="form-control p-1 p-md-2"
            placeholder="Name your plan..."
            autoComplete="off"
            maxLength={mpNameCap}
            value={name}
            required={true}
            onChange={(e) => setName(e.target.value)}
          />
          <button id="button-addon2" className="btn btn-lg btn-primary bg-orange" type="submit">
            Let's do this!
          </button>
        </div>
      </form>
    </div>
  );
}

MagicMealPlanForm.propTypes = {
  create: PropTypes.func,
};
