import React from "react";
import PropTypes from "prop-types";

export default function MakeAheadToggle(props) {

  return (
    <div className="row">
      <div className="col-auto">
        <div className="form-check pe-2">
          <label className={props.listView ? "form-check-label ps-2 cursor-pointer" : "form-check-label cursor-pointer"}>
            <input
              className="form-check-input"
              type="checkbox"
              id="makeAhead"
              checked={props.toggled}
              onChange={props.toggle}
            />
            <span className="col">{props.content}</span>
          </label>
        </div>
      </div>
    </div>
  )
}

MakeAheadToggle.propTypes = {
  listView: PropTypes.bool,
  toggled: PropTypes.bool,
  content: PropTypes.element,
  toggle: PropTypes.func,
}