import React from "react";
import { useHistory } from "react-router-dom";
import Helpers from "../../../../services/Helpers";
import StashButton from "../../../Search/components/StashButton";
import HomepageVisibilityToggle from "./HomepageVisibilityToggle";
import HomepageCarousel from "./HomepageCarousel";

export default function HomepageFeaturedWizardPlans({ loading, component, items, visible, editMode, authenticated, modals, member, stashStore, toggleVisibility }) {
  let history = useHistory();
  const { splitHashtag } = Helpers();

  function stashButtons(item) {
    const stashItem = stashStore && stashStore.items.filter((i) => i.documentType === item.documentType).find((i) => i.documentId === item.documentId);
    let result = null;
    const add = function () {
      modals.showAddToMealPlan({
        documentId: item.documentId,
        documentType: item.documentType,
        name: item.title,
      });
    };

    result = (
      <>
        <div className="d-inline text-nowrap">
          {member.hasMP && (
            <button
              className="btn btn-sm btn-light me-1 mb-1"
              type="button"
              title="Add to Meal Planner"
              onClick={(event) => add(event)}
            >
              <span className="fa fa-plus fa-fw"/>
            </button>
          )}
          <StashButton
            documentId={item.documentId}
            documentType={item.documentType}
            smallButton={true}
            margin=" me-1 mb-1"
            title="Pin"
            active={stashItem === undefined ? false : stashItem.pinned}
            activeClassName="fas fa-thumbtack fa-fw"
            inactiveClassName="far fa-thumbtack fa-fw"
            toggle={stashStore && stashStore.togglePin}
          />
        </div>
        <div className="d-inline text-nowrap">
          <StashButton
            documentId={item.documentId}
            documentType={item.documentType}
            smallButton={true}
            margin=" me-1 mb-1"
            title="Save"
            active={stashItem === undefined ? false : stashItem.saved}
            activeClassName="fa fa-folder-bookmark fa-fw"
            inactiveClassName="far fa-folder-bookmark fa-fw"
            toggle={stashStore && stashStore.toggleSaved}
          />
          <StashButton
            documentId={item.documentId}
            documentType={item.documentType}
            smallButton={true}
            margin=" me-1 mb-1"
            title="Love"
            active={stashItem === undefined ? false : stashItem.loved}
            activeClassName="fa fa-heart fa-fw"
            inactiveClassName="far fa-heart fa-fw"
            toggle={stashStore && stashStore.toggleLove}
          />
        </div>
      </>
    );
    return result;
  }

  return visible || editMode ? (
    <section className={`row my-4 py-4 ${component.color}`}>
      <div className="col-12" style={{ position: "relative" }}>
        <div>
          <div className="row mb-1 ms-lg-2">
            <div className="col">
              <h5 className="homepage-section-title">
                {editMode ? <HomepageVisibilityToggle visible={visible} toggle={toggleVisibility} /> : null}
                <span className={editMode && !visible ? "text-muted" : ""}>{component.title}</span>
              </h5>
            </div>
          </div>
          <div className="row flex-row flex-nowrap home-row" style={{ opacity: editMode && !visible ? 0.25 : 1 }}>
            {loading ? (
              <span className="spinner-border" />
            ) : (
              // show content
              items.map((i) => (
                <article key={i.id} className="homepage-component-huge-item row align-items-center ms-lg-2">
                  <div className="col-12 col-md-7 p-2">
                    <HomepageCarousel id={i.id} photos={i.carouselPhotos} goTo={() => {
                      if (member.hasMP) {
                        history.push(i.link);
                      } else {
                        modals.showFullMembershipRequiredModal();
                      }
                    }} />
                  </div>
                  <div className="col-12 col-md-5 ps-md-0 pe-2">
                    <p className="document-title fs-2 lh-sm cursor-pointer" onClick={() => {
                      if (member.hasMP) {
                        history.push(i.link);
                      } else {
                        modals.showFullMembershipRequiredModal();
                      }
                    }}>
                      {splitHashtag(i.title)}
                    </p>
                    {authenticated ? stashButtons(i) : null}
                  </div>
                </article>
              ))
            )}
          </div>
        </div>
      </div>
    </section>
  ) : null;
}
