import React, { useEffect } from "react";

export default function AnonymousFooter() {
  useEffect(() => {
    const script = document.createElement("script");

    script.src = "https://app.ontraport.com/js/ontraport/opt_assets/drivers/opf.js";
    script.type = "text/javascript";
    script.setAttribute("async", "true");
    script.setAttribute("data-opf-uid", "p2c113108f18");
    script.setAttribute(
      "data-opf-params",
      "borderColor=#000000&borderSize=0px&embed=true&formHeight=78&formWidth=100%&popPosition=mc&instance=n1878719362"
    );

    const loveLetterCard = document.querySelector("#love-letter-card");
    loveLetterCard.appendChild(script);

    return () => {
      loveLetterCard.removeChild(script);
    };
  }, []);

  return (
    <div className="row gx-0 justify-content-md-center recipe-wrap mt-5">
      <div className="card bg-secondary px-2 py-1 px-md-5 py-md-3 d-none">
        <div className="card-body sales-card text-center" id="love-letter-card">
          <img
            src={`${process.env.PUBLIC_URL}/images/molly-patrick-co-founder-plant-fueled-life.png`}
            className="img-fluid"
            alt="molly-patrick co founder of clean food dirty girl"
          />
          <h2 className="text-white">Get a weekly dose of inspiration to eat more plants and celebrate imperfection.</h2>
          <p className="card-text text-white mb-1">
            Our Sweary Saturday Love Letters are written by our ex-boozer, ex-smoker, plant-loving co-founder, Molly Patrick.
          </p>
        </div>
      </div>
      <footer className="mt-auto d-print-none gx-0 p-0">
     
          <div className="d-flex flex-column flex-md-row align-items-center mt-4 footer">
            <div className="mr-md-auto logo">
              <a href="https://cleanfooddirtygirl.com/">
                <img src={`${process.env.PUBLIC_URL}/images/clean-food-dirty-girl-logo-long.png`} alt="Clean Food Dirty Girl" width="300" />
              </a>
              <div className="footer-fine-print">
                <p>
                  &copy; {new Date().getFullYear()} &nbsp;
                  <a href="https://cleanfooddirtygirl.com/privacy-policy-terms-use-disclaimer/" target="_blank" rel="noreferrer">
                    Terms & Privacy Policy
                  </a>
                </p>
              </div>
            </div>
            <nav className="ms-md-auto footer-icons pt-1 pt-md-0">
              <a className="p-2" href="https://www.facebook.com/cfdgirl" target="_blank" rel="noreferrer">
                <i className="fab fa-facebook" />
              </a>
              <a className="p-2" href="https://www.instagram.com/cleanfooddirtygirl/" target="_blank" rel="noreferrer">
                <i className="fab fa-instagram" />
              </a>
              <a className="p-2" href="https://open.spotify.com/show/5ViLN4goOjFHyVJs5nMDnG" target="_blank" rel="noreferrer">
                <i className="fab fa-spotify" />
              </a>
              <a className="p-2 strip-right" href="http://cleanfooddirtygirl.com/contact" target="_blank" rel="noreferrer">
                <i className="fas fa-circle-envelope" />
              </a>
            </nav>
          </div>
          <div className="d-flex p-0">
            <span className="footer-fine-print">
              All content found on this website (Clean Food Dirty Girl), including text, images, audio, or other formats were created for informational
              purposes only. Nothing contained in this website is medical advice or diagnosis nor should any information in this website be construed
              as such. You assume full responsibility for how you choose to use this information. We suggest you seek the advice of a physician before
              beginning any weight loss effort or program. Never disregard professional medical advice or delay in seeking it because of something you
              have read on this website.
            </span>
      
        </div>
      </footer>
    </div>
  );
}
