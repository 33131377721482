import React from "react";
import PropTypes from "prop-types";

export default function NutritionFactsRecipeComponent(props) {
  return (
    <li>{props.component.quantity} {props.component.name}</li>
  );
}

NutritionFactsRecipeComponent.propTypes = {
  component: PropTypes.object,
}