import "./NewSubscriptionForm.css";
import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { DateTime } from "luxon";
import Helpers from "../../../services/Helpers";
import PaymentLockedWarning from "../../../views/components/PaymentLockedWarning";
import Plans from "../../../views/Member/components/Plans";
import BillingInformation from "../../../views/Subscription/BillingInformation";
import SnailButton from "../../../views/components/SnailButton";
import Constants from "../../../services/Constants";

export default function NewSubscriptionForm(props) {
  const { isEmpty, isNotEmpty, paddedLast4 } = Helpers();
  const { planInternalNames } = Constants();
  const [selectedPlan, setSelectedPlan] = useState(planInternalNames.MP_QUARTERLY);
  const [termsAccepted, setTermsAccepted] = useState(false);
  const [cardReady, setCardReady] = useState(false);
  const [working, setWorking] = useState(false);

  const defaultCard = props.subStore.creditCards.find((cc) => cc.status === 3);

  useEffect(() => {
    // get proration from backend
    if (selectedPlan !== "") {
      props.subStore.getProration(selectedPlan);
    }
  }, [selectedPlan]);

  function subscribe(event) {
    event.preventDefault();
    setWorking(true);
    const plan = props.subStore.plans.find((p) => p.internalName === selectedPlan);
    props.subStore.addSubscription(plan, () => {
      setWorking(false);
      setTermsAccepted(false);
      props.hide();
    });
  }

  function recurrence() {
    let result = "";
    const plan = props.subStore.plans.find((p) => p.internalName === selectedPlan);
    if (isNotEmpty(plan)) {
      if (plan.internalName === planInternalNames.MP_MONTHLY || plan.internalName === planInternalNames.RV_MONTHLY) {
        result = "monthly";
      } else if (plan.internalName === planInternalNames.MP_QUARTERLY) {
        result = "every three months";
      } else if (plan.internalName === planInternalNames.MP_ANNUAL) {
        result = "every twelve months";
      }
    }
    return result;
  }

  function purchaseSummary() {
    let result = null;
    let chargeMessage = null;
    if (isNotEmpty(selectedPlan) && termsAccepted && cardReady) {
      if (isNotEmpty(defaultCard)) {
        const plan = props.subStore.plans.find((p) => p.internalName === selectedPlan);
        const now = DateTime.now();
        const currentBillingDate =
          props.subStore.subscription.length === 0 || props.memberStore.member.hasTrial
            ? now
            : DateTime.fromSeconds(props.subStore.subscription[0].nextBillingDate).minus({ days: 1 });
        let chargedDate = currentBillingDate.startOf("day") <= now.startOf("day") ? <strong>now</strong> : `on ${currentBillingDate.toLocaleString()}`;
        if (
          props.subStore.subscription.length > 0 &&
          props.subStore.subscription[0].canceled &&
          DateTime.fromSeconds(props.subStore.subscription[0].nextBillingDate).startOf("day") > now.startOf("day")
        ) {
          // --- current membership is cancelled but still active ---
          if (props.subStore.subscription[0].internalName.substring(0, 2) === selectedPlan.substring(0, 2)) {
            // --- reactivate is within same product, start new membership now, charge nothing now, display next billing date and amount ---
            chargeMessage = (
              <>
                <p className="px-3">
                  Your membership will immediately reactivate to {plan.name} and your membership will renew for{" "}
                  <span className="fw-bold">
                    ${plan.price} {chargedDate}
                  </span>
                  .
                </p>
              </>
            );
          } else if (selectedPlan.substring(0, 2) === "MP") {
            // --- reactivate is an upgrade, start new membership now, charge partial amount now, display next billing date and amount ---
            let creditMessage = <p className="px-3">This pro-rates your membership upgrade.</p>;
            chargeMessage = (
              <>
                <p className="px-3">
                  Your membership will immediately reactivate to {plan.name} and your default card will be{" "}
                  <span className="fw-bold"> charged ${props.subStore.proration.amount} now</span>. Your membership will then renew {chargedDate} for $
                  {plan.price}.
                </p>
                {creditMessage}
              </>
            );
          } else {
            // --- reactivate is a downgrade, keep current membership active until next billing date minus one day, charge nothing now, display next billing date and amount ---
            const lastDayOfCurrentMembership = currentBillingDate.minus({ days: 1 }).toLocaleString();
            chargeMessage = (
              <>
                <p className="px-3">
                  Your current membership will be <span className="fw-bold">active until {lastDayOfCurrentMembership}</span>. Your membership will change to{" "}
                  {plan.name} and your default credit card will be charged{" "}
                  <span className="fw-bold">
                    ${plan.price / 1} {chargedDate}
                  </span>
                  . Your membership will renew monthly.
                </p>
              </>
            );
          }
          result = (
            <>
              <h3 className="h-alt mt-3 mb-2">Purchase summary</h3>
              {chargeMessage}
            </>
          );
        } else if (
          props.subStore.subscription.length === 0 ||
          (props.subStore.subscription.length > 0 && props.subStore.subscription[0].internalName !== "MP_GIFT") ||
          props.memberStore.member.hasTrial
        ) {
          // --- no current membership, reactivate/add starts a new membership, charge now ---
          result = (
            <>
              <h3 className="h-alt mt-3 mb-2">Purchase summary</h3>
              <p className="px-3">
                Your card ending in <span className="fw-bold">{paddedLast4(defaultCard.last4)}</span> will be charged{" "}
                <strong>${plan.discount > 0 ? plan.price - plan.discount : plan.price}</strong> {chargedDate} and your membership renews{" "}
                {plan.discount > 0 ? `for $${plan.price} ` : null}
                {recurrence()}. You can cancel anytime.
              </p>
            </>
          );
        } else if (props.subStore.subscription.length > 0 && props.subStore.subscription[0].internalName === "MP_GIFT") {
          // --- current membership is a gift ---
          if (plan.internalName === planInternalNames.RV_MONTHLY) {
            // --- new membership is a downgrade, keep current gift active until end of gift minus one day, charge nothing now, display next billing date and amount ---
            const lastDayOfGiftMembership = currentBillingDate.minus({ days: 1 }).toLocaleString();
            result = (
              <>
                <h3 className="h-alt mt-3 mb-2">Purchase summary</h3>
                <p className="px-3">
                  Your Gift membership will remain <span className="fw-bold">active until {lastDayOfGiftMembership}</span>, then your {plan.name} membership
                  will start and your default credit card will be charged{" "}
                  <span className="fw-bold">
                    ${plan.price} {chargedDate}
                  </span>
                  . Your membership renews {recurrence()}. You can cancel anytime.
                </p>
              </>
            );
          } else {
            // --- new membership is for full access, change membership now and charge at end of gift ---
            result = (
              <>
                <h3 className="h-alt mt-3 mb-2">Purchase summary</h3>
                <p className="px-3">
                  Your Gift membership will immediately change to <span className="fw-bold">{plan.name}</span>. Your card ending in{" "}
                  <span className="fw-bold">{paddedLast4(defaultCard.last4)}</span> will be charged{" "}
                  <span className="fw-bold">
                    ${plan.price / 1} {chargedDate}
                  </span>{" "}
                  and your membership renews {recurrence()}. You can cancel anytime.
                </p>
              </>
            );
          }
        }
      }
    }
    return result;
  }

  const snailButton = (
    <SnailButton
      className="btn btn-primary btn-lg fw-bold"
      type="button"
      onClick={subscribe}
      disabled={working}
      text="Complete purchase"
      workingTexts={[props.reactivating ? "Reactivating..." : "Purchasing...", "Hang tight...", "Still working..."]}
      working={working}
    />
  );

  function subscribeButton() {
    let result;
    if (working) {
      result = <div className="d-grid gap-2 col-12 col-sm-6 mx-auto">{snailButton}</div>;
    } else if (isNotEmpty(selectedPlan) && props.subStore.creditCards.length > 0 && termsAccepted) {
      result = <div className="d-grid gap-2 col-12 col-sm-6 mx-auto">{snailButton}</div>;
    } else if (props.subStore.creditCards.length > 0 && isEmpty(defaultCard)) {
      result = (
        <div>
          <p>
            <span className="d-none d-sm-inline">Click</span>
            <span className="d-inline d-sm-none">Tap</span> on <strong>Make default</strong> on the card you want to use.
          </p>
        </div>
      );
    } else {
      result = (
        <div className="d-grid gap-2 col-12 col-sm-6 mx-auto">
          <button type="button" className="btn btn-primary btn-lg fw-bold" disabled={true}>
            Complete purchase
          </button>
        </div>
      );
    }
    return result;
  }

  const terms =
    cardReady && isNotEmpty(defaultCard) ? (
      <>
        <h3 className="h-alt mt-1 mb-2">Agree to the terms</h3>
        <p className="form-check mt-1">
          <small>
            <input
              className="form-check-input cursor-pointer"
              type="checkbox"
              checked={termsAccepted}
              id="terms"
              onChange={(e) => setTermsAccepted(e.target.checked)}
            />

            <label className="form-check-label cursor-pointer" htmlFor="terms">
              I have read, understood and agree to the{" "}
              <a
                href="https://cleanfooddirtygirl.com/mealplans-waiver-refund-policy/"
                target="_blank"
                rel="noopener noreferrer"
                className="text-black text-decoration-underline"
              >
                terms of purchase and waiver
              </a>
              .
            </label>
          </small>
        </p>
      </>
    ) : null;

  function main() {
    return (
      <div className="p-md-3">
        <Plans
          plans={props.subStore.plans}
          intro={
            <div className="intro">
              <h3 className="h-alt mb-3">Select a membership</h3>
            </div>
          }
          selectedPlan={selectedPlan}
          setSelectedPlan={setSelectedPlan}
          hasTrial={props.memberStore.member.hasTrial}
        />
        <h3 className="h-alt mb-3">Billing information</h3>
        <BillingInformation member={props.memberStore.member} subStore={props.subStore} signUpContext={false} setCardReady={setCardReady} />
        {terms}
        {purchaseSummary()}
        {cardReady ? <div className="mt-3">{subscribeButton()}</div> : null}
      </div>
    );
  }

  return <div>{props.memberStore.member.paymentLocked ? <PaymentLockedWarning /> : main()}</div>;
}

NewSubscriptionForm.propTypes = {
  memberStore: PropTypes.object,
  subStore: PropTypes.object,
  reactivating: PropTypes.bool,
  hide: PropTypes.func,
};
