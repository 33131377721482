import React from "react";
import PropTypes from "prop-types";
import Plan from "./Plan";
import Constants from "../../../services/Constants";

export default function Plans(props) {
  const { planInternalNames } = Constants();

  const monthly = props.plans.find((p) => p.internalName === planInternalNames.MP_MONTHLY);
  const quarterly = props.plans.find((p) => p.internalName === planInternalNames.MP_QUARTERLY);
  const annual = props.plans.find((p) => p.internalName === planInternalNames.MP_ANNUAL);
  const rvMonthly = props.plans.find((p) => p.internalName === planInternalNames.RV_MONTHLY);

  const monthlyTitle =
    monthly.discount > 0 ? (
      <>
        <span className="d-inline d-lg-none">
          <span className="fs-6 badge rounded-pill bg-primary ms-0">${monthly.discount} off</span>
          <br />
        </span>
        <span>Monthly</span>
      </>
    ) : (
      <>Monthly</>
    );
  const quarterlyTitle =
    quarterly.discount > 0 ? (
      <>
        <span className="d-inline d-lg-none">
          <span className="fs-6 badge rounded-pill bg-primary ms-0">${quarterly.discount} off</span>
          <br />
        </span>
        <span>Quarterly - most popular</span>
      </>
    ) : (
      <>Quarterly - most popular</>
    );
  const annualTitle =
    annual.discount > 0 ? (
      <>
        <span className="d-inline d-lg-none">
          <span className="fs-6 badge rounded-pill bg-primary ms-0">${annual.discount} off</span>
          <br />
        </span>
        <span>Annual - best value</span>
      </>
    ) : (
      <>Annual - best value</>
    );
  const rvMonthlyTitle =
    rvMonthly.discount > 0 ? (
      <>
        <span className="d-inline d-lg-none">
          <span className="fs-6 badge rounded-pill bg-primary ms-0">${rvMonthly.discount} off</span>
          <br />
        </span>
        <span>Monthly</span>
      </>
    ) : (
      <>Monthly</>
    );

  const monthlyPrice =
    monthly.discount > 0 ? (
      <>
        <span>
          <del>${monthly.price}</del> ${monthly.price - monthly.discount} for the first month{" "}
        </span>
        <span className="d-none d-lg-inline">
          <span className="badge rounded-pill bg-primary ms-0 align-text-top">${monthly.discount} off</span>
        </span>
      </>
    ) : (
      <>${monthly.price} per month</>
    );
  const quarterlyPrice =
    quarterly.discount > 0 ? (
      <>
        <span>
          <del>${quarterly.price}</del> ${quarterly.price - quarterly.discount} for the first three months{" "}
        </span>
        <span className="d-none d-lg-inline">
          <span className="badge rounded-pill bg-primary ms-0 align-text-top">${quarterly.discount} off</span>
        </span>
      </>
    ) : (
      <>${quarterly.price / 3} per month</>
    );
  const annualPrice =
    annual.discount > 0 ? (
      <>
        <span>
          <del>${annual.price}</del> ${annual.price - annual.discount} for the first year{" "}
        </span>
        <span className="d-none d-lg-inline">
          <span className="badge rounded-pill bg-primary ms-0 align-text-top">${annual.discount} off</span>
        </span>
      </>
    ) : (
      <>${annual.price / 12} per month</>
    );
  const rvMonthlyPrice =
    rvMonthly.discount > 0 ? (
      <>
        <span>
          <del>${rvMonthly.price}</del> ${rvMonthly.price - rvMonthly.discount} for the first month{" "}
        </span>
        <span className="d-none d-lg-inline">
          <span className="badge rounded-pill bg-primary ms-0 align-text-top">${rvMonthly.discount} off</span>
        </span>
      </>
    ) : (
      <>${rvMonthly.price} per month</>
    );

  const monthlyText = monthly.discount > 0 ? <>Then ${monthly.price} charged monthly</> : <>Renews monthly</>;
  const quarterlyText = quarterly.discount > 0 ? <>Then ${quarterly.price} charged every three months</> : <>${quarterly.price} charged every three months</>;
  const annualText = annual.discount > 0 ? <>Then ${annual.price} charged annually</> : <>${annual.price} charged annually</>;
  const rvMonthlyText = rvMonthly.discount > 0 ? <>Then ${rvMonthly.price} charged monthly</> : <>Renews monthly</>;

  return (
    <div id="pfmp" className="col-12 container-fluid p-0">
      {props.intro}
      <div className="row">
        <div className="mt-2 mb-2">
          <span className="fs-4 fw-bold display-4"><i class="fa-solid fa-hat-chef text-orange"></i> Meal Plan Club</span>
          <br />
          <span>Choose this option if you want our delicious meal plans, recipes, the ability to make your own meal plans, and more.</span>
        </div>
        <Plan
          plan={monthly}
          title={monthlyTitle}
          content={
            <div className="card-text">
              <p className="fs-5 mb-1">{monthlyPrice}</p>
              <p className="footer-text lh-sm">{monthlyText}</p>
            </div>
          }
          currentPlan={props.currentPlan}
          selectedPlan={props.selectedPlan}
          selectPlan={props.setSelectedPlan}
        />
        <Plan
          plan={quarterly}
          title={quarterlyTitle}
          content={
            <div className="card-text">
              <p className="fs-5 mb-1">{quarterlyPrice}</p>
              <p className="footer-text lh-sm">{quarterlyText}</p>
            </div>
          }
          currentPlan={props.currentPlan}
          selectedPlan={props.selectedPlan}
          selectPlan={props.setSelectedPlan}
        />
        <Plan
          plan={annual}
          title={annualTitle}
          content={
            <div className="card-text">
              <p className="fs-5 mb-1">{annualPrice}</p>
              <p className="footer-text lh-sm">{annualText}</p>
            </div>
          }
          currentPlan={props.currentPlan}
          selectedPlan={props.selectedPlan}
          selectPlan={props.setSelectedPlan}
        />
        <div className="mt-2 mb-2">
          <span className="fs-4 h-alt fw-bold"><i class="fa-solid fa-fork-knife text-green"></i> Recipe Club</span>
          <br />
          <span>Choose this option if you want recipes only. Meal plans, grocery lists, printing, and downloads are not included.</span>
        </div>
        <Plan
          plan={rvMonthly}
          title={rvMonthlyTitle}
          content={
            <div className="card-text">
              <p className="fs-5 mb-1">{rvMonthlyPrice}</p>
              <p className="footer-text lh-sm">{rvMonthlyText}</p>
            </div>
          }
          currentPlan={props.currentPlan}
          selectedPlan={props.selectedPlan}
          selectPlan={props.setSelectedPlan}
        />
      </div>
    </div>
  );
}

Plans.propTypes = {
  plans: PropTypes.array,
  intro: PropTypes.element,
  currentPlan: PropTypes.string,
  selectedPlan: PropTypes.string,
  setSelectedPlan: PropTypes.func,
  hasTrial: PropTypes.bool,
};
