import React, {useEffect, useState} from "react";
import PropTypes from "prop-types";
import GroceryListIngredient from "./GroceryListIngredient";

export default function GroceryListCategory(props) {
  const [working, setWorking] = useState(false);

  useEffect(() => {
    setWorking(false);
  }, [props.ingredients]);

  function renderItems() {
    let result = null;
    const checked = props.name.toLowerCase() === "items you have";
    const items = props.ingredients.map(i => (
      <GroceryListIngredient
        key={"ingredient-" + i.id}
        ingredient={i}
        checked={checked}
        updateIngredient={props.updateIngredient}
      />
    ));
    if (items.length > 0 && props.name.toLowerCase() === "items you have") {
      const uncheckAllButton = working ? (
        <button className="btn btn-link" type="button" disabled={true}>Unchecking...</button>
      ) : (
        <button
          className="btn btn-link"
          type="button"
          onClick={() => {
            setWorking(true);
            props.uncheckAll();
          }}
        >
          Uncheck All
        </button>
      );
      result = (
        <div className="row p-2 pt-2 pb-0">
        <div className="col-12">
          <div className="bg-white d-inline-block mt-4 p-2 pb-1 ms-md-3 text-md-center rounded-top">
              <h5 className="h-alt">{props.icon}{props.name}</h5>
          </div>
        </div>
         
          <div className="col-12 bg-white px-3 px-md-4 py-2">
          <div className="col-6">
            {uncheckAllButton}
          </div>
            <table>
              <tbody>
              {items}
              </tbody>
            </table>
          </div>
        </div>
      );
    } else if (items.length > 0) {
      result = (
        <div className="row p-2 pt-2 pb-0">
          <div className="col-12">
            <div className="bg-white d-inline-block mt-4 p-2 pb-1 ms-md-3 text-md-center rounded-top strip-margin-print">
                <h5 className="h-alt">{props.icon}{props.name}</h5>
            </div>
          </div>
          <div className="col-12 bg-white px-3 px-md-4 py-2 strip-margin-print">
            <table className="w-100">
              <tbody>
              {items}
              </tbody>
            </table>
          </div>
        </div>
      );
    }
    return result;
  }

  return renderItems();
}

GroceryListCategory.propTypes = {
  icon: PropTypes.element,
  name: PropTypes.string,
  ingredients: PropTypes.array,
  updateIngredient: PropTypes.func,
  uncheckAll: PropTypes.func,
}