import "./MemberForm.css";
import React, {useEffect, useReducer} from "react";
import PropTypes from "prop-types";
import Constants from "../../services/Constants";
import Helpers from "../../services/Helpers";
import {Link} from "react-router-dom";

const actions = {
  FIRSTNAME: "firstname",
  LASTNAME: "lastname",
  EMAIL: "email",
  PASSWORD: "password",
  SHOW_PASSWORD: "showPassword",
  SERVER_MESSAGE: "serverMessage",
  WORKING: "working",
  VALIDATE: "validate",
}

const initialState = {
  firstname: "",
  firstnameInvalid: false,
  lastname: "",
  lastnameInvalid: false,
  email: "",
  emailInvalid: false,
  password: "",
  passwordInvalid: false,
  showPassword: false,
  readyToSubmit: false,
  working: false,
}

export default function MemberForm(props) {
  const {authMessages} = Constants();
  const {isNotEmpty, validateEmail} = Helpers();
  function validatePassword(password) {
    return isNotEmpty(password) && password.length > 7;
  }
  function reducer(state, action) {
    if (action.type === actions.WORKING) {
      return {...state, working: action.data.working};
    } else if (action.type === actions.FIRSTNAME) {
      return {...state, firstname: action.data.value, firstnameInvalid: false};
    } else if (action.type === actions.LASTNAME) {
      return {...state, lastname: action.data.value, lastnameInvalid: false};
    } else if (action.type === actions.EMAIL) {
      if (props.message === authMessages.EMAIL_ALREADY_USED) {
        // When editing email, if the previous value was in conflict with an existing email, we reset the message to hide it
        props.resetMessage();
      }
      return {...state, email: action.data.value, emailInvalid: false};
    } else if (action.type === actions.PASSWORD) {
      return {...state, password: action.data.value, passwordInvalid: false};
    } else if (action.type === actions.SHOW_PASSWORD) {
      return {...state, showPassword: action.data.checked};
    } else if (action.type === actions.VALIDATE) {
      props.resetMessage();
      const firstnameValid = isNotEmpty(state.firstname);
      const lastnameValid = isNotEmpty(state.lastname);
      const emailValid = validateEmail(state.email);
      const passwordValid = validatePassword(state.password);
      const readyToSubmit = firstnameValid && lastnameValid && emailValid && passwordValid;
      return {...state, firstnameInvalid: !firstnameValid, lastnameInvalid: !lastnameValid, emailInvalid: !emailValid, passwordInvalid: !passwordValid, readyToSubmit: readyToSubmit}
    } else if (action.type === actions.SERVER_MESSAGE) {
      return {...state, readyToSubmit: false, working: false};
    }
    return state;
  }
  const [state, dispatch] = useReducer(reducer, initialState);

  useEffect(() => {
    return () => props.resetMessage();
  }, []);

  useEffect(() => {
    if (isNotEmpty(props.message)) {
      dispatch({
        type: actions.SERVER_MESSAGE
      });
    }
  }, [props.message]);

  useEffect(() => {
    if (state.readyToSubmit) {
      if (isNotEmpty(props.startTrial)) {
        props.setTrialThankYouAsNextPage();
      }
      dispatch({
        type: actions.WORKING,
        data: { working: true }
      });
    }
  }, [state.readyToSubmit]);

  useEffect(() => {
    if (state.readyToSubmit && state.working && isNotEmpty(props.signUp)) {
      props.signUp({firstname: state.firstname, lastname: state.lastname, email: state.email, password: state.password});
    }
    if (state.readyToSubmit && state.working && isNotEmpty(props.startTrial)) {
      props.startTrial({firstname: state.firstname, lastname: state.lastname, email: state.email, password: state.password});
    }
  }, [state.working]);

  function handleSubmit(event) {
    event.preventDefault();
    dispatch({
      type: actions.VALIDATE,
    });
  }

  function message() {
    let result = null;
    if (props.message === authMessages.EMAIL_ALREADY_USED) {
      result = (
        <div className="alert alert-primary" role="alert">
          Hey there! It looks like you've had the trial or are already a member. Please <Link to="/sign-in">sign in here</Link>. If you forgot your password, you can <Link to="/forgot-password">reset it here</Link>.
        </div>
      );
    } else if (isNotEmpty(props.startTrial) && props.message === authMessages.AUTHENTICATION_FAILED) {
      result = (
        <div className="alert alert-primary" role="alert">
          There's already an account with that email address. If you forgot your password, you can <Link to="/forgot-password">reset it here</Link>.
        </div>
      );
    }
    return result;
  }

  const button = state.working ? (
    <div className="d-grid gap-2 col-12 col-sm-6 mx-auto">
      <button
        className="btn btn-primary btn-lg fw-bold"
        disabled={true}
      >
        <span className="spinner-border spinner-border-sm"/>
        <span className="ms-1">{isNotEmpty(props.signUp) ? "Creating your account..." : "Starting your trial..."}</span>
      </button>
    </div>
  ) : (
    <div className="d-grid gap-2 col-12 col-sm-6 mx-auto">
      <button
        className="btn btn-primary btn-lg fw-bold"
        type="submit"
      >
        {isNotEmpty(props.signUp) ? "Create account" : "Start free trial"}
      </button>
    </div>
  );

  return (
    <div>
      {message()}
      <form id="member-form" onSubmit={handleSubmit} noValidate={true}>
        <div className="row">
          <div className="col-6 mb-3 pe-1">
            <label htmlFor="firstname" className="form-label">First name</label>
            <input
              type="text"
              className={state.firstnameInvalid ? "form-control is-invalid" : "form-control"}
              id="firstname"
              value={state.firstname}
              onChange={e => dispatch({ type: actions.FIRSTNAME, data: { value: e.target.value } })}
            />
            <div className="invalid-feedback">
              Please provide your first name.
            </div>
          </div>
          <div className="col-6 mb-3 ps-1">
            <label htmlFor="lastname" className="form-label">Last name</label>
            <input
              type="text"
              className={state.lastnameInvalid ? "form-control is-invalid" : "form-control"}
              id="lastname"
              value={state.lastname}
              onChange={e => dispatch({ type: actions.LASTNAME, data: { value: e.target.value } })}
            />
            <div className="invalid-feedback">
              Please provide your last name.
            </div>
          </div>
        </div>
        <div className="mb-3">
          <label htmlFor="email" className="form-label">Email address</label>
          <input
            type="email"
            className={state.emailInvalid ? "form-control is-invalid" : "form-control"}
            id="email"
            value={state.email}
            onChange={e => dispatch({ type: actions.EMAIL, data: { value: e.target.value } })}
          />
          <div className="invalid-feedback">
            Please provide a valid email address.
          </div>
        </div>
        <div className="mb-3">
          <label htmlFor="password" className="form-label">
            Password
          </label>
          <div className="input-group">
            <input
              type={state.showPassword ? "text" : "password"}
              autoComplete="off"
              className={state.passwordInvalid ? "form-control is-invalid" : "form-control"}
              id="password"
              value={state.password}
              onChange={e => dispatch({ type: actions.PASSWORD, data: { value: e.target.value } })}
            />
            <button 
              className="btn btn-outline-light"
              id="btn-show-password"
              type="button"
              onClick={() => dispatch({ type: actions.SHOW_PASSWORD, data: { checked: !state.showPassword } })}
              title={state.showPassword ? "Hide password" : "Show password"}
            >
              <i className={`far ${state.showPassword ? "fa-eye" : "fa-eye-slash"}`}></i>
            </button>
            <div className="invalid-feedback">
              Your password must be at least 8 characters long. You can do it!
            </div>
          </div>
        </div>
        <div>
          {button}
        </div>
      </form>
    </div>
  );
}

MemberForm.propTypes = {
  signUp: PropTypes.func,
  startTrial: PropTypes.func,
  resetMessage: PropTypes.func,
  message: PropTypes.string,
  setTrialThankYouAsNextPage: PropTypes.func,
}