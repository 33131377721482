import "./Gift.scss";
import React, {useEffect, useState} from "react";
import PropTypes from "prop-types";
import Helpers from "../../services/Helpers";
import {Link, Redirect, useLocation} from "react-router-dom";
import MemberForm from "../components/MemberForm";
import Footer from "../components/Footer";

export default function Gift(props) {
  let location = useLocation();
  const {isEmpty, isNotEmpty} = Helpers();
  const [redirect, setRedirect] = useState(false);
  const [redeemSuccess, setRedeemSuccess] = useState(false);
  const [redeemError, setRedeemError] = useState(false);
  const [working, setWorking] = useState(false);

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const redeemToken = params.get("redeem-token");
    if (isNotEmpty(redeemToken)) {
      props.giftStore.getGift(redeemToken);
    }
  }, [location]);

  useEffect(() => {
    return () => props.giftStore.resetGift();
  }, []);

  const redeemButton = working ? (
    <button
      className="btn btn-primary"
      disabled={true}
    >
      <span className="spinner-border spinner-border-sm"/>
      <span className="ms-1">Redeeming...</span>
    </button>
  ) : (
    <button
      className="btn btn-primary"
      type="button"
      onClick={() => {
        setWorking(true);
        const params = new URLSearchParams(location.search);
        const redeemToken = params.get("redeem-token");
        props.giftStore.redeemGift(redeemToken, success => {
          if (success) {
            props.memberStore.getMember();
            setRedeemSuccess(true);
            setWorking(false);
          } else {
            setRedeemError(true);
            setWorking(false);
          }
        });
      }}
    >
      <i className="fas fa-gift"/> Redeem my gift!
    </button>
  );

  const memberDetails = isNotEmpty(props.memberStore.member.id) ? (
    <div className="row gift gift-signedin">
      <div className="col">
      <p>You are currently signed in as:</p>
      <p>
        Name: <strong>{props.memberStore.member.firstname} {props.memberStore.member.lastname}</strong>
        <br/>
        Email Address: <strong>{props.memberStore.member.email}</strong>
      </p>
      <button
        className="btn btn-secondary"
        onClick={props.signOut}
      >
        <i className="fas fa-sign-out-alt"/> Sign Out
      </button>
      {isNotEmpty(props.giftStore.gift) ? (
        <div className="mt-4">
          <p>If this is correct, click on the "Redeem My Gift" button below to get started! Otherwise, you can sign out (above) and sign back in with another account.</p>
          {redeemButton}
        </div>
      ) : null}
    </div>
    </div>
  ) : (
    <div className="gift gift-sign-in">
      <p>Sign in to the portal and you will be on your way to redeeming your gift!</p>
      <button className="btn btn-primary" type="button" onClick={() => setRedirect(true)}>Sign In</button>
    </div>
  );

  const signUp = isEmpty(props.memberStore.member.id) ? (
    <div className="gift gift-not-member">
      <p>Not yet a member? Create one below - easy peasy!</p>
      <MemberForm signUp={props.signUp} resetMessage={props.resetMessage} message={props.message}/>
    </div>
  ) : null;

  function main() {
    let result = null;
    if (props.giftStore.giftResolved) {
      if (redeemSuccess) {
        result = (
          <div className="gift gift-sweet text-center">
          <h3 className="mb-3">Sweet! We've added your gift to your account.</h3>
            <p>Now it's time to enjoy all Clean Food Dirty Girl Meal Plan Club has to offer.</p>
            <p>Start by visiting the <Link to="/">portal</Link> and looking over the goods!</p>
          </div>
        );
      } else if (redeemError) {
        result = (
          <div className="gift gift-problem">
              <h3 className="mb-3">There was a problem when we tried to redeem your gift.</h3>
                <p className="text-center">Contact <a href="mailto:support@cleanfooddirtygirl.com">our support team</a> and we will get you sorted!</p>
          </div>
        );
      } else if (isNotEmpty(props.giftStore.gift) && isEmpty(props.giftStore.gift.redeemedAt)) {
        const month = props.giftStore.gift.monthsQuantity > 1 ? "months" : "month";
        result = (
          <div className="gift gift-signed-in">
              <div className="gift-graphic">
              <img src={`${process.env.PUBLIC_URL}/images/gift.png`}
                    alt="Clean Food Dirty Girl Gift Redemption" 
                    width="100"
                    />
              </div>
            <h3>
              You have been given <span className="fw-bold">{props.giftStore.gift.monthsQuantity}&nbsp;glorious {month}</span> of
              Clean Food Dirty Girl Meal Plan Club!
            </h3>
            {memberDetails}
            {signUp}
          </div>
        )
      }
    }
    return result;
  }

  return redirect ? (
    <Redirect
      to={{
        pathname: "/sign-in",
        state: {from: location},
      }}
    />
  ) : (
    <>
      <main id="gift" className="container-lg">
        {main()}
      </main>
      <Footer />
    </>
  );
}

Gift.propTypes = {
  memberStore: PropTypes.object,
  giftStore: PropTypes.object,
  message: PropTypes.string,
  signOut: PropTypes.func,
  signUp: PropTypes.func,
  resetMessage: PropTypes.func,
}