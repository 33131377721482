import React from "react";
import PropTypes from "prop-types";
import Lightbox from "react-image-lightbox";
import Helpers from "../../../services/Helpers";

export default function FullScreenImage(props) {
  const {assetUrl, isNotEmpty} = Helpers();

  return isNotEmpty(props.image) && props.opened ? (
    <Lightbox
      mainSrc={assetUrl(props.image)}
      imageCaption={props.caption}
      reactModalStyle={{overlay: {zIndex: 2000}}}
      onCloseRequest={props.onCloseRequest}
    />
  ) : null;
}

FullScreenImage.propTypes = {
  opened: PropTypes.bool,
  image: PropTypes.string,
  caption: PropTypes.string,
  onCloseRequest: PropTypes.func,
}