import { useEffect, useReducer } from "react";
import axios from "axios";
import Constants from "../Constants";
import Helpers from "../Helpers";

const actions = {
  LOAD_ANONYMOUS_DATA: "loadAnonymousData",
  LOAD_COMPONENTS: "loadComponents",
  LOAD_HIDDEN_COMPONENTS: "loadhiddenComponents",
  LOAD_MEMBER_DATA: "loadMemberData",
  LOAD_QUICK_LINKS: "loadQuickLinks",
};

const initialState = {
  hiddenComponents: { loading: true, ids: [] },
  components: [],
  blanks: { loading: true, components: [] },
  collections: { loading: true, items: [] },
  cookingJournal: { loading: true, items: [] },
  externalLinks: { loading: true, items: [] },
  featuredWizardPlans: {
    loading: true,
    items: [],
  },
  internalLinks: { loading: true, items: [] },
  membersRecipePhotos: { loading: true, items: [] },
  mostLovedRecipesThisWeek: { loading: true, items: [] },
  newAndNoteworthyRecipes: { loading: true, items: [] },
  quickLinks: { loading: true, items: [] },
  randomMembersLovedItems: { loading: true, items: [] },
  randomMembersSavedItems: { loading: true, items: [] },
  trendingThisWeek: { loading: true, items: [] },
};

export default function useHomepage(token, member, waitingForMember) {
  const { homepageComponentTypes } = Constants();
  const { buildQuery } = Helpers();
  function reducer(state, action) {
    if (action.type === actions.LOAD_ANONYMOUS_DATA) {
      return {
        ...state,
        blanks: { loading: false, components: action.data.blankComponents },
        collections: { loading: false, items: action.data.collectionItems },
        externalLinks: { loading: false, items: action.data.externalLinkItems },
        featuredWizardPlans: { loading: false, items: action.data.featuredWizardPlanItems },
        internalLinks: { loading: false, items: action.data.internalLinkItems },
        membersRecipePhotos: { loading: false, items: action.data.membersRecipePhotoItems },
        mostLovedRecipesThisWeek: { loading: false, items: action.data.mostLovedRecipesThisWeekItems },
        newAndNoteworthyRecipes: { loading: false, items: action.data.newAndNoteworthyRecipeItems },
        trendingThisWeek: { loading: false, items: action.data.trendingThisWeekItems },
      };
    } else if (action.type === actions.LOAD_COMPONENTS) {
      return { ...state, components: action.data.components };
    } else if (action.type === actions.LOAD_HIDDEN_COMPONENTS) {
      return { ...state, hiddenComponents: { loading: false, ids: action.data.ids } };
    } else if (action.type === actions.LOAD_MEMBER_DATA) {
      const filteredQuickLinks = member.hasRV ? action.data.quickLinksItems.filter(i => ["/meal-plans", "/coaching"].indexOf(i.link) === -1) : action.data.quickLinksItems;
      return {
        ...state,
        hiddenComponents: { loading: false, ids: action.data.hiddenComponentIds },
        cookingJournal: { loading: false, items: action.data.cookingJournalItems },
        quickLinks: { loading: false, items: filteredQuickLinks },
        randomMembersLovedItems: { loading: false, items: action.data.randomMembersLovedItems },
        randomMembersSavedItems: { loading: false, items: action.data.randomMembersSavedItems },
      };
    } else if (action.type === actions.LOAD_QUICK_LINKS) {
      const filteredQuickLinks = member.hasRV ? action.data.items.filter(i => ["/meal-plans", "/coaching"].indexOf(i.link) === -1) : action.data.items;
      return { ...state, quickLinks: { loading: false, items: filteredQuickLinks } };
    }
    return state;
  }
  const [state, dispatch] = useReducer(reducer, initialState);

  // When mounting, get homepage components
  useEffect(() => {
    axios({
      method: "get",
      headers: {
        "Content-Type": "application/json",
        "Authorization": `Bearer ${token}`
      },
      url: `${process.env.REACT_APP_API}/cms/homepage-components`,
    }).then(res => {
      dispatch({
        type: actions.LOAD_COMPONENTS,
        data: { components: res.data }
      });
    });
  }, []);

  // When homepage components are updated, get anonymous components
  useEffect(() => {
    if (state.components.length > 0) {
      const anonymousPromises = [];
      if (state.components.filter(c => c.componentType === homepageComponentTypes.COLLECTION).length > 0) {
        anonymousPromises.push(
          axios({
            method: "get",
            headers: {
              "Content-Type": "application/json"
            },
            url: `${process.env.REACT_APP_API}/cms/homepage-components/collections`,
          })
        );
      }
      if (state.components.filter(c => c.componentType === homepageComponentTypes.BLANK).length > 0) {
        anonymousPromises.push(
          axios({
            method: "get",
            headers: {
              "Content-Type": "application/json"
            },
            url: `${process.env.REACT_APP_API}/cms/homepage-components/blanks`,
          })
        );
      }
      if (state.components.filter(c => c.componentType === homepageComponentTypes.EXTERNAL_LINKS).length > 0) {
        anonymousPromises.push(
          axios({
            method: "get",
            headers: {
              "Content-Type": "application/json"
            },
            url: `${process.env.REACT_APP_API}/cms/homepage-components/external-links`,
          })
        );
      }
      if (state.components.filter(c => c.componentType === homepageComponentTypes.INTERNAL_LINKS).length > 0) {
        anonymousPromises.push(
          axios({
            method: "get",
            headers: {
              "Content-Type": "application/json"
            },
            url: `${process.env.REACT_APP_API}/cms/homepage-components/internal-links`,
          })
        );
      }
      for (let component of state.components) {
        if (component.componentType === homepageComponentTypes.FEATURED_WIZARD_PLANS) {
          anonymousPromises.push(
            axios({
              method: "get",
              headers: {
                "Content-Type": "application/json"
              },
              url: `${process.env.REACT_APP_API}/cms/homepage-components/featured-wizard-plans`,
            })
          );
        } else if (component.componentType === homepageComponentTypes.MEMBERS_RECIPE_PHOTOS) {
          anonymousPromises.push(
            axios({
              method: "get",
              headers: {
                "Content-Type": "application/json"
              },
              url: `${process.env.REACT_APP_API}/cms/homepage-components/members-recipe-photos`,
            })
          );
        } else if (component.componentType === homepageComponentTypes.MOST_LOVED_RECIPES_THIS_WEEK) {
          anonymousPromises.push(
            axios({
              method: "get",
              headers: {
                "Content-Type": "application/json"
              },
              url: `${process.env.REACT_APP_API}/cms/homepage-components/loved-this-week`,
            })
          );
        } else if (component.componentType === homepageComponentTypes.NEW_AND_NOTEWORTHY_RECIPES) {
          anonymousPromises.push(
            axios({
              method: "get",
              headers: {
                "Content-Type": "application/json"
              },
              url: `${process.env.REACT_APP_API}/cms/homepage-components/new-and-noteworthy-recipes`,
            })
          );
        } else if (component.componentType === homepageComponentTypes.TRENDING_THIS_WEEK) {
          anonymousPromises.push(
            axios({
              method: "get",
              headers: {
                "Content-Type": "application/json"
              },
              url: `${process.env.REACT_APP_API}/cms/homepage-components/trending-this-week`,
            })
          );
        }
      }
      Promise.all(anonymousPromises).then(responses => {
        const data = {
          blankComponents: [],
          collectionItems: [],
          externalLinkItems: [],
          featuredWizardPlanItems: [],
          internalLinkItems: [],
          membersRecipePhotoItems: [],
          mostLovedRecipesThisWeekItems: [],
          newAndNoteworthyRecipeItems: [],
          trendingThisWeekItems: [],
        };
        for (let res of responses) {
          if (res.config.url.indexOf("blanks") !== -1) {
            data.blankComponents = res.data;
          } else if (res.config.url.indexOf("collections") !== -1) {
            data.collectionItems = res.data;
          } else if (res.config.url.indexOf("external-links") !== -1) {
            data.externalLinkItems = res.data;
          } else if (res.config.url.indexOf("featured-wizard-plans") !== -1) {
            data.featuredWizardPlanItems = res.data;
          } else if (res.config.url.indexOf("internal-links") !== -1) {
            data.internalLinkItems = res.data;
          } else if (res.config.url.indexOf("members-recipe-photos") !== -1) {
            data.membersRecipePhotoItems = res.data;
          } else if (res.config.url.indexOf("loved-this-week") !== -1) {
            data.mostLovedRecipesThisWeekItems = res.data;
          } else if (res.config.url.indexOf("new-and-noteworthy-recipes") !== -1) {
            data.newAndNoteworthyRecipeItems = res.data;
          } else if (res.config.url.indexOf("trending-this-week") !== -1) {
            data.trendingThisWeekItems = res.data;
          }
        }
        dispatch({ type: actions.LOAD_ANONYMOUS_DATA, data: data });
      });
    }
  }, [state.components]);

  // When member and homepage components are updated, get member components
  useEffect(() => {
    if (state.components.length > 0 && !waitingForMember && (member.hasMP || member.hasRV)) {
      const memberPromises = [
        axios({
          method: "get",
          headers: {
            "Content-Type": "application/json",
            "Authorization": `Bearer ${token}`
          },
          url: `${process.env.REACT_APP_API}/cms/homepage-components/hidden-components`,
        })
      ];
      for (let component of state.components) {
        if (component.componentType === homepageComponentTypes.COOKING_JOURNAL) {
          memberPromises.push(
            axios({
              method: "get",
              headers: {
                "Content-Type": "application/json",
                "Authorization": `Bearer ${token}`
              },
              url: `${process.env.REACT_APP_API}/cms/homepage-components/cooking-journal`,
            })
          );
        } else if (component.componentType === homepageComponentTypes.QUICK_LINKS) {
          memberPromises.push(
            axios({
              method: "get",
              headers: {
                "Content-Type": "application/json",
                "Authorization": `Bearer ${token}`
              },
              url: `${process.env.REACT_APP_API}/cms/homepage-components/quick-links`,
            })
          )
        } else if (component.componentType === homepageComponentTypes.RANDOM_MEMBERS_LOVED_ITEMS) {
          memberPromises.push(
            axios({
              method: "get",
              headers: {
                "Content-Type": "application/json",
                "Authorization": `Bearer ${token}`
              },
              url: `${process.env.REACT_APP_API}/cms/homepage-components/random-loved`,
            })
          )
        } else if (component.componentType === homepageComponentTypes.RANDOM_MEMBERS_SAVED_ITEMS) {
          memberPromises.push(
            axios({
              method: "get",
              headers: {
                "Content-Type": "application/json",
                "Authorization": `Bearer ${token}`
              },
              url: `${process.env.REACT_APP_API}/cms/homepage-components/random-saved`,
            })
          )
        }
      }
      if (memberPromises.length > 0) {
        Promise.all(memberPromises).then(responses => {
          const data = {
            hiddenComponentIds: [],
            cookingJournalItems: [],
            quickLinksItems: [],
            randomMembersLovedItems: [],
            randomMembersSavedItems: [],
          }
          responses.forEach(res => {
            if (res.config.url.indexOf("hidden-components") !== -1) {
              data.hiddenComponentIds = res.data;
            } else if (res.config.url.indexOf("cooking-journal") !== -1) {
              data.cookingJournalItems = res.data;
            } else if (res.config.url.indexOf("quick-links") !== -1) {
              data.quickLinksItems = res.data;
            } else if (res.config.url.indexOf("random-loved") !== -1) {
              data.randomMembersLovedItems = res.data;
            } else if (res.config.url.indexOf("random-saved") !== -1) {
              data.randomMembersSavedItems = res.data;
            }
          });
          dispatch({ type: actions.LOAD_MEMBER_DATA, data: data });
        });
      }
    }
  }, [member, waitingForMember, state.components]);

  function toggleComponentVisibility(id) {
    if (state.hiddenComponents.ids.indexOf(id) === -1) {
      axios({
        method: "post",
        headers: {
          "Content-Type": "application/json",
          "Authorization": `Bearer ${token}`
        },
        url: `${process.env.REACT_APP_API}/cms/homepage-components/hidden-components`,
        data: id,
      }).then(res => {
        dispatch({ type: actions.LOAD_HIDDEN_COMPONENTS, data: { ids: res.data } });
      }).catch(() => {
      });
    } else {
      axios({
        method: "delete",
        headers: {
          "Content-Type": "application/json",
          "Authorization": `Bearer ${token}`
        },
        url: `${process.env.REACT_APP_API}/cms/homepage-components/hidden-components/${id}`,
      }).then(res => {
        dispatch({ type: actions.LOAD_HIDDEN_COMPONENTS, data: { ids: res.data } });
      }).catch(() => {
      });
    }
  }

  function toggleQuickLink(pathname, search) {
    const link = pathname + search;
    const ql = state.quickLinks.items.find(ql => ql.link === link);
    if (ql === undefined) {
      const data = {
        link: link,
        query: null,
      }
      if (pathname.indexOf("/search") !== -1) {
        data.query = buildQuery(search);
      }
      axios({
        method: "post",
        headers: {
          "Content-Type": "application/json",
          "Authorization": `Bearer ${token}`
        },
        url: `${process.env.REACT_APP_API}/cms/homepage-components/quick-links`,
        data: data,
      }).then(res => {
        dispatch({ type: actions.LOAD_QUICK_LINKS, data: { items: res.data } });
      }).catch(() => {
      });
    } else {
      axios({
        method: "delete",
        headers: {
          "Content-Type": "application/json",
          "Authorization": `Bearer ${token}`
        },
        url: `${process.env.REACT_APP_API}/cms/homepage-components/quick-links/${ql.id}`,
      }).then(res => {
        dispatch({ type: actions.LOAD_QUICK_LINKS, data: { items: res.data } });
      }).catch(() => {
      });
    }
  }

  function refreshQuickLinks() {
    axios({
      method: "get",
      headers: {
        "Content-Type": "application/json",
        "Authorization": `Bearer ${token}`
      },
      url: `${process.env.REACT_APP_API}/cms/homepage-components/quick-links`,
    }).then(res => {
      dispatch({ type: actions.LOAD_QUICK_LINKS, data: { items: res.data } });
    }).catch(() => {
    });
  }

  return {
    state: state,
    toggleComponentVisibility: toggleComponentVisibility,
    toggleQuickLink: toggleQuickLink,
    refreshQuickLinks: refreshQuickLinks,
  };
}
