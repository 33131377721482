import React, { useEffect } from "react";
import PropTypes from "prop-types";
import Footer from "../../components/Footer";
import { useLocation } from "react-router-dom";
import Helpers from "../../../services/Helpers";
import Document from "../../Search/components/Document";

export default function Featured(props) {
  const location = useLocation();
  const { pageTitle } = Helpers();

  useEffect(() => {
    document.title = pageTitle(location.pathname);
  }, [location]);

  const documents = props.items.map(d => (
    <Document
      key={d.id}
      authenticated={props.authenticated}
      member={props.member}
      stashStore={props.stashStore}
      modals={props.modals}
      document={d}
    />
  ));

  return (
    <>
      <main id="featured" className="container-lg">
        {props.banner}
        <h1 className="text-center page-title mb-3">{props.title}</h1>
        <div className="row bg-white py-2 px-sm-2 py-sm-3">
          {documents}
        </div>
      </main>
      <Footer />
    </>
  );
}

Featured.propTypes = {
  authenticated: PropTypes.bool,
  member: PropTypes.object,
  items: PropTypes.array,
  stashStore: PropTypes.object,
  modals: PropTypes.object,
  title: PropTypes.string,
  banner: PropTypes.element,
}