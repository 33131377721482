import React, {useEffect, useState} from "react";
import axios from "axios";
import Helpers from "../Helpers";

export default function useTileCollection(token, id) {
  const {isNotEmpty} = Helpers();
  const [tileCollection, setTileCollection] = useState({
    id: null,
    cssClass: "",
    title: "",
    text: "",
    tiles: []
  });

  useEffect(() => {
    if (isNotEmpty(token) && isNotEmpty(id)) {
      axios({
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          "Authorization": `Bearer ${token}`
        },
        url: `${process.env.REACT_APP_API}/cms/tile-collections/${id}`,
      }).then(res => {
        setTileCollection(res.data);
      }).catch(err => {
        console.log(err);
      });
    }
  }, [token, id]);


  return {tileCollection};
}