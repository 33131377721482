import React, {useState} from "react";
import PropTypes from "prop-types";
import {Link, Redirect} from "react-router-dom";
import Footer from "../components/Footer";

export default function ForgotPassword(props) {
  const [email, setEmail] = useState("");
  const [requested, setRequested] = useState(false);

  function handleSubmit(event) {
    event.preventDefault();
    props.createPasswordReset({email: email}, () => {
      setRequested(true);
    });
  }

  const main = requested ? (
    <p>Your email with instructions on how to reset your password is in the oven, getting crispy. It should arrive to
      your inbox within the next 15 minutes.</p>
  ) : (
    <form onSubmit={handleSubmit}>
      <div className="mb-3">
        <label htmlFor="input-email" className="form-label">
          Email Address
        </label>
        <input
          type="email"
          className="form-control"
          id="input-email"
          name="email"
          value={email}
          onChange={e => setEmail(e.target.value)}
        />
      </div>
      <button type="submit" className="btn btn-primary">Send me a link</button>
    </form>
  )

  return props.authenticated ? (
    <Redirect to={{pathname: "/"}}/>
  ) : (
    <>
      <main id="forgot-password" className="container-lg">
        <div className="align-items-center">
          <div className="forgot-password">
            <h1>Request a link to reset my password</h1>
            {main}
            <p className="mt-3">
              Not what you were looking for? <Link to="/sign-in" className="text-muted">Go back to sign in</Link>.
            </p>
          </div>
        </div>
      </main>
      <Footer/>
    </>
  );
}

ForgotPassword.propTypes = {
  authenticated: PropTypes.bool,
  createPasswordReset: PropTypes.func,
}