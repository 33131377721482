import NewSubscriptionForm from "./NewSubscriptionForm";

export default function AddSubscriptionModal({ memberStore, subStore, hideModal }) {
  const showForm = subStore.plans.length > 0;
  return (
    <div id="add-subscription-modal" className="modal" tabIndex={-1} data-bs-backdrop="static">
      <div className="modal-dialog modal-lg">
        <div className="modal-content">
          <div className="modal-header">
            <h1 className="modal-title">Become a member</h1>
            <button type="button" className="btn-close btn-close-black" onClick={() => hideModal("add-subscription-modal")} />
          </div>
          <div className="modal-body">
            {showForm && (
              <NewSubscriptionForm memberStore={memberStore} subStore={subStore} reactivating={false} hide={() => hideModal("add-subscription-modal")} />
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
