/**
 * Renders a button group for the StandaloneCreditCardForm component, conditionally including a cancel button.
 * This component wraps its children (typically a form submission button - SnailButton) and conditionally renders a cancel button
 * based on the `showCancelButton` prop. It supports default or large bootstrap Button styling through the `useLargeButtons` prop.
 *
 * @param {Object} props - The props for the component.
 * @param {boolean} props.disabled - If true, disables the cancel button.
 * @param {function} props.onClick - The function to call when the cancel button is clicked.
 * @param {boolean} props.showCancelButton - Controls the visibility of the cancel button.
 * @param {boolean} props.useLargeButtons - If true, uses large button styling for the cancel button.
 * @param {JSX.Element|JSX.Element[]} props.children - The child components to be rendered, typically form buttons.
 * @returns {JSX.Element} A div wrapping the cancel button (if `showCancelButton` is true) and the children.
 */
export default function CreditCardFormButtonGroup({ disabled, onClick, showCancelButton, useLargeButtons, children }) {
  return (
    <div className="d-grid gap-2 col-12 col-sm-6 mx-auto">
      {showCancelButton ? (
        <div className="row">
          <div className="d-grid gap-2 col-6 mx-auto pe-1">
            <button
              className={`btn btn-outline-primary ${useLargeButtons ? " btn-lg fw-bold" : ""} bg-white`}
              type="button"
              disabled={disabled}
              onClick={onClick}
            >
              Cancel
            </button>
          </div>
          <div className="d-grid gap-2 col-6 mx-auto ps-1">{children}</div>
        </div>
      ) : (
        children
      )}
    </div>
  );
}
