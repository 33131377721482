import React from "react";
import PropTypes from "prop-types";
import Helpers from "../../../services/Helpers";
import MarkdownViewer from "../../components/MarkdownViewer";

export default function NotesAndTips(props) {
  const {isNotEmpty} = Helpers();

  return isNotEmpty(props.notesAndTips) ? (
    <div>
      <div>
        <small>Note:</small>
      </div>
      <div>
        <small>
          <MarkdownViewer markdown={props.notesAndTips}/>
        </small>
      </div>
    </div>
  ) : null;
}

NotesAndTips.propTypes = {
  notesAndTips: PropTypes.string,
}
