import React from "react";
import { Modal } from "bootstrap";
import { useHistory } from "react-router-dom";

export default function FullMembershipRequiredModal() {
  let history = useHistory();

  function goToAccount() {
    const element = document.getElementById("full-membership-required-modal");
    const trialFeatureModal = Modal.getOrCreateInstance(element);
    trialFeatureModal.hide();
    history.push(`/account?m=become-a-member`); //TODO: this works, but make sure the backdrop really disappear on production build
  }

  return (
    <div className="modal" id="full-membership-required-modal" tabIndex={-1}>
      <div className="modal-dialog modal-dialog-centered modal-lg">
        <div className="modal-content">
          <div className="modal-header">
            <h1 className="modal-title h-alt">This content requires Meal Plan Club</h1>
            <button type="button" className="btn-close btn-close-black" data-bs-dismiss="modal" aria-label="Close" />
          </div>
          <div className="modal-body">
            <p>Upgrade your membership to open the goods.</p>
          </div>
          <div className="modal-footer">
            <button type="button" className="btn btn-outline-primary" data-bs-dismiss="modal">
              Not yet
            </button>
            <button type="button" className="btn btn-secondary me-auto" onClick={goToAccount}>
              Yes, let's do it!
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}
