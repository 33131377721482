import React, {useEffect, useState} from "react";
import PropTypes from "prop-types";
import FacetOption from "./FacetOption";
import {useHistory} from "react-router-dom";
import Facet from "./Facet";
import Constants from "../../../services/Constants";

export default function Facets(props) {
  let history = useHistory();
  const {searchOrigins} = Constants();
  const [firstLevelTags, setFirstLevelTags] = useState([]);
  const [secondLevelTags, setSecondLevelTags] = useState([]);

  useEffect(() => {
    if (props.cache.ready) {
      setFirstLevelTags(props.cache.firstLevelTags());
      setSecondLevelTags(props.cache.secondLevelTags());
    }
  }, [props.cache.ready]);

  function documentTypes() {
    let result = null;
    if (props.searchOrigin !== searchOrigins.PLANNER && props.store.state.facets.documentTypes.length > 0) {
      const types = props.store.state.facets.documentTypes.map(f => (
        <FacetOption
          key={f.facetName}
          locked={props.store.state.facetLocked}
          facet={f}
          checked={props.store.state.activeDocumentType === f.facetName}
          toggle={() => history.push(props.store.nextLocation(props.store.searchActions.FACET, f))}
        />
      ));
      result = <Facet shownByDefault={true} title="Content" facetOptions={types} />;
    }
    return result;
  }

  function wizardPlanTypes() {
    let result = null;
    if (props.store.state.facets.planTypeFacets.length > 0) {
      const types = props.store.state.facets.planTypeFacets.map(f => (
        <FacetOption
          key={f.facetName}
          locked={props.store.state.facetLocked}
          facet={f}
          checked={props.store.state.activePlanType === f.facetName}
          toggle={() => history.push(props.store.nextLocation(props.store.searchActions.FACET, f))}
        />
      ));
      result = <Facet shownByDefault={false} title="Meal plan types" facetOptions={types} />;
    }
    return result;
  }

  function categories() {
    let result = null;
    if (props.store.state.facets.tagFacets.length > 0) {
      const facets = props.store.state.facets.tagFacets.filter(f => firstLevelTags.find(t => t.tag === f.facetName) !== undefined);
      if (facets.length > 0) {
        const categories = facets.map(f => (
          <FacetOption
            key={f.facetName}
            locked={props.store.state.facetLocked}
            facet={f}
            checked={props.store.state.activeTagIds.indexOf(f.id) !== -1}
            toggle={() => history.push(props.store.nextLocation(props.store.searchActions.FACET, f))}
          />
        ));
        result = <Facet shownByDefault={false} title="Categories" facetOptions={categories} />;
      }
    }
    return result;
  }

  function subCategories() {
    let result = null;
    // display sub-categories only when there are categories or sub-categories checked
    if (props.store.state.facets.tagFacets.length > 0 && props.store.state.facets.tagFacets.filter(f => props.store.state.activeTagIds.indexOf(f.id) !== -1).length > 0) {
      const facets = props.store.state.facets.tagFacets.filter(f => secondLevelTags.find(t => t.tag === f.facetName) !== undefined);
      if (facets.length > 0) {
        const subCategories = facets.map(f => (
          <FacetOption
            key={f.facetName}
            locked={props.store.state.facetLocked}
            facet={f}
            checked={props.store.state.activeTagIds.indexOf(f.id) !== -1}
            toggle={() => history.push(props.store.nextLocation(props.store.searchActions.FACET, f))}
          />
        ));
        result = <Facet shownByDefault={true} title="Sub-categories" facetOptions={subCategories} />;
      }
    }
    return result;
  }

  function recipeTools() {
    let result = null;
    if (props.store.state.facets.toolFacets.length > 0) {
      const tools = props.store.state.facets.toolFacets.map(f => (
        <FacetOption
          key={f.facetName}
          locked={props.store.state.facetLocked}
          facet={f}
          checked={props.store.state.activeToolIds.indexOf(f.id) !== -1}
          toggle={() => history.push(props.store.nextLocation(props.store.searchActions.FACET, f))}
          image={props.cache.tools.find(t => t.id === f.id).icon}
        />
      ));
      result = <Facet shownByDefault={false} title="Recipe tools" facetOptions={tools} />;
    }
    return result;
  }

  function recipeCharacteristics() {
    let result = null;
    if (props.store.state.facets.characteristicFacets.length > 0) {
      const characteristics = props.store.state.facets.characteristicFacets.map(f => (
        <FacetOption
          key={f.facetName}
          locked={props.store.state.facetLocked}
          facet={f}
          checked={props.store.state.activeCharacteristicIds.indexOf(f.id) !== -1}
          toggle={() => history.push(props.store.nextLocation(props.store.searchActions.FACET, f))}
        />
      ));
      result = <Facet shownByDefault={false} title="Allergens" facetOptions={characteristics}  />;
    }
    return result;
  }

  function magicMealPlannerRecipes() {
    let result = null;
    if (props.store.state.facets.magicMealPlannerFacets.length === 1 && props.store.state.facets.magicMealPlannerFacets[0].count > 0) {
      const f = props.store.state.facets.magicMealPlannerFacets[0];
      result = (
        <Facet
          shownByDefault={false}
          title="Magic Meal Planner"
          facetOptions={[
            <FacetOption
              key={f.facetName}
              locked={props.store.state.facetLocked}
              facet={f}
              checked={props.store.state.recommendedOnly}
              toggle={() => history.push(props.store.nextLocation(props.store.searchActions.RECOMMENDED_ONLY))}
              tooltip="Checking this filter will show recipes that typically don't have many components and work well with or without batching so you can put together a week of yums. May you find your inner Wizard."
            />
          ]}
        />
      );
    }
    return result;
  }


  return (
    <div>
      {documentTypes()}
      {categories()}
      {subCategories()}
      {recipeCharacteristics()}
      {recipeTools()}
      {wizardPlanTypes()}
      {magicMealPlannerRecipes()}
    </div>
  )
}

Facets.propTypes = {
  cache: PropTypes.object,
  store: PropTypes.object,
  searchOrigin: PropTypes.string,
}