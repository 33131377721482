import "./SignUp.css";
import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { Link, Redirect, useLocation } from "react-router-dom";
import MemberForm from "../components/MemberForm";
import Helpers from "../../services/Helpers";
import SignUpPlan from "./components/SignUpPlan";
import Constants from "../../services/Constants";
import Testimonials from "../Subscription/Testimonials";
import PurchaseConfirmation from "../Subscription/PurchaseConfirmation";
import BillingInformation from "../Subscription/BillingInformation";

export default function SignUp(props) {
  const screens = {
    ACCOUNT_CREATION: "accountCreation",
    PLAN_SELECTION: "planSelection",
    BILLING_INFORMATION: "billingInformation",
    PURCHASE_CONFIRMATION: "purchaseConfirmation",
    WORKING: "working",
  };
  const location = useLocation();
  const { planInternalNames } = Constants();
  const { isNotEmpty, pageTitle } = Helpers();
  const [screen, setScreen] = useState(screens.WORKING);
  const [selectedPlan, setSelectedPlan] = useState("MP_QUARTERLY");

  useEffect(() => {
    document.title = pageTitle(location.pathname);
    let searchParams = new URLSearchParams(location.search);
    props.setUtmParams({
      origin: location.pathname,
      campaign: searchParams.get("utm_campaign"),
      source: searchParams.get("utm_source"),
      medium: searchParams.get("utm_medium"),
      content: searchParams.get("utm_content"),
      term: searchParams.get("utm_term"),
    });
  }, [location]);

  useEffect(() => {
    // When everything is ready, we can set the screen
    if (!props.memberStore.waitingForMember && !props.subStore.waitingForCreditCards) {
      if (props.authenticated && props.subStore.plans.length > 0) {
        setScreen(screens.PLAN_SELECTION);
      } else {
        setScreen(screens.ACCOUNT_CREATION);
      }
      window.scrollTo(0, 0);
    }
  }, [props.memberStore.waitingForMember, props.authenticated, props.subStore.waitingForCreditCards, props.subStore.plans]);

  useEffect(() => {
    if (screen === screens.BILLING_INFORMATION && isNotEmpty(props.subStore.subscriptionMessage)) {
      // Reset the error message when the customer goes back to the billing information area to add another card
      props.subStore.resetSubscriptionMessage();
    }
  }, [screen]);

  const monthly = props.subStore.plans.find((p) => p.internalName === planInternalNames.MP_MONTHLY);
  const quarterly = props.subStore.plans.find((p) => p.internalName === planInternalNames.MP_QUARTERLY);
  const annual = props.subStore.plans.find((p) => p.internalName === planInternalNames.MP_ANNUAL);
  const rvMonthly = props.subStore.plans.find((p) => p.internalName === planInternalNames.RV_MONTHLY);
  const purchaseConfirmationPlan = props.subStore.plans.find((p) => p.internalName === selectedPlan);

  function main() {
    const header = (
      <div className="row mb-3 align-items-center">
        <div className="col">
          <Link className="navbar-brand me-0" to="/">
          <img
            src={`${process.env.PUBLIC_URL}/images/Clean-Food-Dirty-Girl-Main-Logo.png`}
            className="header-logo" alt="Clean Food Dirty Girl"
          />
          </Link>
        </div>
        <div className="col-auto">
          {props.authenticated ? null : (
            <Link to="/sign-in" className="">
              Sign in
            </Link>
          )}
        </div>
      </div>
    );

    let content;
    if (screen === screens.ACCOUNT_CREATION && !props.authenticated) {
      content = (
        <>
          <div className="progress">
            <div className="progress-bar progress-bar-striped bg-primary" role="progressbar" style={{ width: "25%" }}></div>
          </div>
          <p className="mt-3">
            <strong>Hello, beautiful. Let's set up an account!</strong>
          </p>
          <MemberForm signUp={props.signUp} resetMessage={props.resetMessage} message={props.message} />
          <p className="mt-2 footer-text text-center">
            By creating an account, I agree to the{" "}
            <a id="terms-privacy-link" className="black-link" href="https://cleanfooddirtygirl.com/privacy-policy-terms-use-disclaimer/" target="_blank">
              Terms and Privacy Policy
            </a>
            .
          </p>
        </>
      );
    } else if (screen === screens.PLAN_SELECTION && !props.memberStore.member.hasMP && !props.memberStore.member.hasRV) {
      content = (
        <>
          <div className="progress">
            <div className="progress-bar progress-bar-striped bg-primary" role="progressbar" style={{ width: "50%" }}></div>
          </div>
          <div className="mt-3 mb-2">
            <span className="fs-4 fw-bold display-4"><i class="fa-solid fa-hat-chef text-orange"></i> Meal Plan Club</span>
            <br />
            <span>Choose this option if you want our delicious meal plans, recipes, the ability to make your own meal plans, and more.</span>
          </div>
          <SignUpPlan
            plan={monthly}
            title="Monthly"
            content={
              <div className="card-text">
                <p className="fs-5 mb-1">${monthly.price} per month</p>
                <p className="footer-text lh-sm">Renews monthly</p>
              </div>
            }
            selectedPlan={selectedPlan}
            selectPlan={setSelectedPlan}
          />
          <SignUpPlan
            plan={quarterly}
            title="Quarterly - most popular"
            content={
              <div className="card-text">
                <p className="fs-5 mb-1">${quarterly.price / 3} per month</p>
                <p className="footer-text lh-sm">${quarterly.price} charged every three months</p>
              </div>
            }
            selectedPlan={selectedPlan}
            selectPlan={setSelectedPlan}
          />
          <SignUpPlan
            plan={annual}
            title="Annual - best value"
            content={
              <div className="card-text">
                <p className="fs-5 mb-1">${annual.price / 12} per month</p>
                <p className="footer-text lh-sm">${annual.price} charged annually</p>
              </div>
            }
            selectedPlan={selectedPlan}
            selectPlan={setSelectedPlan}
          />
          <div className="mb-4">
            <div className="mt-4 mb-2">
              <span className="fs-4 h-alt fw-bold"><i class="fa-solid fa-fork-knife text-green"></i> Recipe Club</span>
              <br />
              <span>Choose this option if you want recipes only. Meal plans, grocery lists, printing, and downloads are not included.</span>
            </div>

            <SignUpPlan
              plan={rvMonthly}
              title="Monthly"
              content={
                <div className="card-text">
                  <p className="fs-5 mb-1">${rvMonthly.price} per month</p>
                  <p className="footer-text lh-sm">Renews monthly</p>
                </div>
              }
              selectedPlan={selectedPlan}
              selectPlan={setSelectedPlan}
            />
          </div>
          <div className="d-grid gap-2 col-12 col-sm-6 mx-auto">
            <button className="btn btn-primary btn-lg fw-bold" type="button" onClick={() => setScreen(screens.BILLING_INFORMATION)}>
              Next
            </button>
          </div>
          <p className="mt-2 footer-text text-center">Mouthgasms and aha moments await. You can easily cancel or change your plan.</p>
        </>
      );
    } else if (screen === screens.BILLING_INFORMATION && !props.memberStore.member.hasMP && !props.memberStore.member.hasRV) {
      content = (
        <>
          <div className="progress">
            <div className="progress-bar progress-bar-striped bg-primary" role="progressbar" style={{ width: "75%" }}></div>
          </div>
          <p className="mt-3 mb-1">
            <strong>
              {props.subStore.creditCards.length === 0 ? "Let's get your name and billing address." : "Add a new card or change your default card."}
            </strong>
          </p>
          <BillingInformation
            member={props.memberStore.member}
            subStore={props.subStore}
            dismiss={() => setScreen(screens.PURCHASE_CONFIRMATION)}
            signUpContext={true}
          />
          <p className="mt-2 footer-text text-center">You're investing in yourself. That's hot.</p>
          {props.subStore.changingDefaultCard ? (
            <button type="button" className="btn btn-link text-muted" disabled={true}>
              <span className="fas fa-chevron-left" /> Back
            </button>
          ) : (
            <button type="button" className="btn btn-link text-muted" onClick={() => setScreen(screens.PLAN_SELECTION)}>
              <span className="fas fa-chevron-left" /> Back
            </button>
          )}
        </>
      );
    } else if (screen === screens.PURCHASE_CONFIRMATION) {
      content = (
        <>
          <div className="progress">
            <div className="progress-bar progress-bar-striped bg-primary" role="progressbar" style={{ width: "90%" }}></div>
          </div>
          <p className="mt-3">
            <strong>Review your order</strong>
          </p>
          <PurchaseConfirmation
            member={props.memberStore.member}
            subStore={props.subStore}
            plan={purchaseConfirmationPlan}
            back={() => setScreen(screens.BILLING_INFORMATION)}
          />
          <div className="d-grid gap-2 col-12 col-sm-6 mx-auto mt-5 mb-3">
            <Testimonials />
          </div>
          <button type="button" className="btn btn-link text-muted" onClick={() => setScreen(screens.BILLING_INFORMATION)}>
            <span className="fas fa-chevron-left" /> Back
          </button>
        </>
      );
    } else if (props.memberStore.member.hasMP || props.memberStore.member.hasRV) {
      content = <Redirect to="/" />;
    } else {
      content = (
        <div className="text-center">
          <span className="spinner-border" />
        </div>
      );
    }

    return (
      <main id="sign-up" className="container-lg mt-0 my-sm-auto p-3 p-sm-4">
        {header}
        {content}
      </main>
    );
  }

  return main();
}

SignUp.propTypes = {
  authenticated: PropTypes.bool,
  message: PropTypes.string,
  memberStore: PropTypes.object,
  subStore: PropTypes.object,
  signUp: PropTypes.func,
  resetMessage: PropTypes.func,
  setUtmParams: PropTypes.func,
};
