import {useEffect, useState} from "react";
import axios from "axios";
import Helpers from "../Helpers";

export default function useVideo(token, id, active) {
  const {isNotEmpty} = Helpers();
  const [video, setVideo] = useState({
    id: null,
    title: "",
    text: "",
    vimeoId: "",
    thumbnail: "",
    files: [],
  });

  useEffect(() => {
    if (isNotEmpty(token) && isNotEmpty(id) && active) {
      axios({
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          "Authorization": `Bearer ${token}`
        },
        url: `${process.env.REACT_APP_API}/cms/videos/${id}`,
      }).then(res => {
        setVideo(res.data);
      }).catch(err => {
        console.log(err);
      });
    }
  }, [token, id, active]);


  return {video};
}