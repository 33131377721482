// ***** MagicMealPlannerStore.js *****
// This store interacts with api endpoints defined in MagicMealPlannerResource.java
// This store provides data and function to MagicMealPlan views: MagicMealPlans.js, MagicMealPlan.js

import {useEffect, useReducer} from "react";
import axios from "axios";
import Constants from "../Constants";
import Helpers from "../Helpers";
import { DateTime } from "luxon";

const actions = {
  CHANGE_COOKING_METHOD: "changeCookingMethod",
  LOADING: "loading",
  LOAD_ALL: "loadAll",
  LOAD_ONE: "loadOne",
  LOAD_PUBLIC: "loadPublic",
  SET_DAY_BOOKMARK: "setDayBookmark",
  SET_MEAL_SLOT: "setMealSlot",
  UPDATE_CHILD_RECIPE_ADDITIONAL_PORTIONS: "updateChildRecipeAdditionalPortions",
  UPDATE_CUSTOMIZATIONS: "updateCustomizations", // TODO: split
  UPDATE_PREPS: "updatePreps",
  UPDATE_INGREDIENTS: "updateIngredients",
  UPDATE_RECIPES_CHECKBOXES: "updateRecipesCheckboxes",
  UPDATE_DELETED_CHILD_RECIPES: "updateDeletedChildRecipes",
  RESET_PLAN: "resetPlan",
  UPDATING_CHECKBOXES: "updatingCheckboxes",
};

const initialPlan = {
  id: null,
  name: "",
  description: "",
  photo: "",
  publiclyAvailable: false,
  listView: false,
  createdAt: null,
  storageId: null,
  recipeData: {masters: [], recipes: [], views: []},
  childRecipeIds: [],
  ingredientData: {masters: [], ingredients: [], nutrients: [], views: {produce: [], bulk: [], spicesHerbs: [], packaged: [], itemsYouHave: []}},
  ingredientNutrients: [],
  sections: [],
  makeAheadRecipeIds: [],
  preps: [],
  mealSlot: null,
}

const initialCustomizations = {
  sections: [],
  ingredients: [],
  preps: [],
  makeAheadRecipes: [],
  recipeTools: [],
  recipesCheckboxes: [],
  deletedChildRecipes: [],
  childRecipeAdditionalPortions: [],
}

const initialState = {
  plans: [],
  plan: initialPlan,
  publicPlan: {
    id: null,
    name: "",
    description: "",
    photo: "",
    author: "",
    listView: false,
    sections: [],
  },
  customizations: initialCustomizations,
  showPublicPlan: false,
  availableImages: [],
  dayBookmark: {
    planId: null,
    day: null,
  },
  loading: true,
  updatingCheckboxes: false,
};

export default function useMagicMealPlan(cache, token, member, ss, getPersonalizedContent, refreshQuickLinks) {
  const {recipeComponentCategories, recipeTypes, recipeViewContexts} = Constants();
  const {handleError, isEmpty, isNotEmpty} = Helpers();
  function reducer(state, action) {
    function reset() {
      state.plan.recipeData = {
        masters: state.plan.recipeData.masters,
        recipes: JSON.parse(JSON.stringify(state.plan.recipeData.masters)),
        views: []
      };
      state.plan.ingredientData.ingredients = JSON.parse(JSON.stringify(state.plan.ingredientData.masters));
      state.plan.ingredientData.views =  {produce: [], bulk: [], spicesHerbs: [], packaged: [], itemsYouHave: []};
      state.plan.sections = [];
      state.plan.makeAheadRecipeIds = [];
    }
    function makeAheadRecipeIds(data) {
      // Toggles are generated in the order that recipes should be prepared.
      const allToggles = [];
      for (let section of data.customizations.sections) {
        for (let cm of section.meals) {
          // Augment meals with more data
          const recipe = data.plan.recipeData.recipes.find(r => r.id === cm.recipeId);
          if (recipe !== undefined) {
            const makeAheadToggles = generateToggles(data, recipe.id);
            for (let mat of makeAheadToggles) {
              const toggle = allToggles.find(t => t.recipeId === mat.recipeId);
              if (isEmpty(toggle)) {
                allToggles.push(mat);
              }
            }
            cm["recipeId"] = recipe.id;
            cm["name"] = recipe.name;
            cm["photo"] = recipe.photo;
            cm["makeAheadToggles"] = makeAheadToggles
          }
        }
        // Add section to plan
        data.plan.sections.push(section);
      }
      // Use toggles order to generate a batching list in the correct order
      const recipeIds = [];
      for (let mat of allToggles) {
        const mar = data.customizations.makeAheadRecipes.find(mar => mar.recipeId === mat.recipeId);
        if (isNotEmpty(mar)) {
          recipeIds.push(mar.recipeId);
        }
      }
      data.plan.makeAheadRecipeIds = recipeIds;
    }
    function customizeRecipes(data) {
      ss.customizeRecipesComponents(data.plan.recipeData, data.customizations.recipeTools);
      ss.deleteChildRecipes(data.plan.recipeData.recipes, data.customizations.deletedChildRecipes);
    }
    function scale(data) {
      const childRecipeIds = []; // This property has the correct order of preparation for recipes
      addAppearances(data.plan, childRecipeIds);
      data.plan.childRecipeIds = [...childRecipeIds].reverse();
      data.plan.recipeData.recipes = data.plan.recipeData.recipes.filter(r => r.hasOwnProperty("appearances") && r.appearances.length > 0);
      addTotalPortions(data.plan);
      addRatios(data.plan);
      ss.adjustMinimumYield(data.plan.recipeData.recipes, data.customizations.childRecipeAdditionalPortions);
      ss.scale(recipeViewContexts.MAGIC_MEAL_PLANNER, data.plan.recipeData.recipes, data.plan.ingredientData.ingredients);
      ss.addMissingProperties(data.plan.recipeData.recipes, data.plan.ingredientData.ingredients);
      data.plan.recipeData.views = data.plan.recipeData.recipes.map(r => ss.generateRecipeView(recipeViewContexts.MAGIC_MEAL_PLANNER, r, data.plan.recipeData.recipes, data.plan.ingredientData.ingredients, data.customizations, data.plan.id));
      data.plan.preps = ss.generatePreps(recipeViewContexts.MAGIC_MEAL_PLANNER, data.plan.recipeData.views.filter(r => data.plan.makeAheadRecipeIds.indexOf(r.id) !== -1), data.plan.ingredientData.ingredients, data.customizations);
      ss.setIngredientsAppearances(data.plan.ingredientData.ingredients, data.plan.recipeData.recipes);
      ss.ingredientsQuantities(data.plan.ingredientData.ingredients);
      ss.generateGroceryList(data.plan.ingredientData, data.plan.recipeData.recipes, data.customizations.ingredients);
    }

    function findChildRecipes(recipes, childRecipeId, output) {
      const recipe = recipes.find(r => r.id === childRecipeId);
      if (isNotEmpty(recipe)) {
        output.push(recipe);
        const components = recipe.stoveTopRecipeComponents.length > 0 ? recipe.stoveTopRecipeComponents : recipe.instantPotRecipeComponents;
        for (let component of components) {
          if (component.category === recipeComponentCategories.RECIPE) {
            findChildRecipes(recipes, component.childRecipeId, output);
          }
        }
      }
    }

    function generateToggles(data, recipeId) {
      const makeAheadToggles = [];
      const meal = data.plan.recipeData.recipes.find(r => r.id === recipeId);
      if (isNotEmpty(meal)) {
        const makeAheadRecipes = [];
        if (meal.type !== recipeTypes.ASSEMBLY) {
          makeAheadRecipes.push(meal);
        }
        const components = meal.stoveTopRecipeComponents.length > 0 ? meal.stoveTopRecipeComponents : meal.instantPotRecipeComponents;
        for (let component of components) {
          if (component.category === recipeComponentCategories.RECIPE) {
            findChildRecipes(data.plan.recipeData.recipes, component.childRecipeId, makeAheadRecipes);
          }
        }
        for (let makeAheadRecipe of makeAheadRecipes) {
          const toggle = data.customizations.makeAheadRecipes.find(t => t.recipeId === makeAheadRecipe.id);
          if (makeAheadToggles.find(mat => mat.recipeId === makeAheadRecipe.id) === undefined) {
            makeAheadToggles.push({
              recipeId: makeAheadRecipe.id,
              name: makeAheadRecipe.name,
              toggled: toggle !== undefined
            });
          }
        }
        return [...makeAheadToggles].reverse();
      }
    }

    if (action.type === actions.LOADING) {
      return { ...state, loading: action.data.loading};
    } else if (action.type === actions.LOAD_ALL) {
      return { ...state, plans: action.data.plans, availableImages: action.data.availableImages, loading: false};
    } else if (action.type === actions.LOAD_ONE) {
      const plan = {
        id: action.data.plan.id,
        name: action.data.plan.name,
        description: action.data.plan.description,
        photo: action.data.plan.photo,
        publiclyAvailable: action.data.plan.publiclyAvailable,
        listView: action.data.plan.listView,
        createdAt: action.data.plan.createdAt,
        storageId: action.data.plan.storageId,
        recipeData: {
          masters: action.data.plan.recipes,
          recipes: JSON.parse(JSON.stringify(action.data.plan.recipes)),
          views: []
        },
        ingredientData: {masters: action.data.plan.ingredients, ingredients: JSON.parse(JSON.stringify(action.data.plan.ingredients)), nutrients: action.data.plan.ingredientNutrients, views: {produce: [], bulk: [], spicesHerbs: [], packaged: [], itemsYouHave: []}},
        ingredientNutrients: action.data.plan.ingredientNutrients,
        sections: [],
        makeAheadRecipeIds: []
      };
      const data = {
        plan: plan,
        customizations: action.data.customizations,
      }
      customizeRecipes(data);
      makeAheadRecipeIds(data);
      scale(data);
      return { ...state, plan:  data.plan, customizations: data.customizations, showPublicPlan: false, loading: false};
    } else if (action.type === actions.LOAD_PUBLIC) {
      return { ...state, publicPlan: action.data.publicPlan, showPublicPlan: true, loading: false};
    } else if (action.type === actions.UPDATE_CUSTOMIZATIONS) {
      reset();
      const data = {
        plan: state.plan,
        customizations: action.data.customizations
      }
      customizeRecipes(data);
      makeAheadRecipeIds(data);
      scale(data);
      return { ...state, plan:  data.plan, customizations: data.customizations};
    } else if (action.type === actions.UPDATE_PREPS) {
      // No need to scale here?
      reset();
      const customizations = {...state.customizations, preps: action.data.preps};
      const data = {...state, customizations: customizations};
      customizeRecipes(data);
      makeAheadRecipeIds(data);
      scale(data);
      return data;
    } else if (action.type === actions.UPDATING_CHECKBOXES) {
      return { ...state, updatingCheckboxes: true };
    } else if (action.type === actions.UPDATE_RECIPES_CHECKBOXES) {
      const customizations = {...state.customizations, recipesCheckboxes: action.data.recipesCheckboxes};
      const data = {...state, updatingCheckboxes: false, customizations: customizations};
      const view = data.plan.recipeData.views.find(v => v.id === action.data.recipeId);
      if (view !== undefined) {
        const recipeCheckboxes = action.data.recipesCheckboxes.find(rc => rc.recipeId === action.data.recipeId);
        if (view.checkboxes !== recipeCheckboxes.checkboxes) {
          view.checkboxes = recipeCheckboxes.checkboxes; 
        }
      }
      return data;
    } else if (action.type === actions.CHANGE_COOKING_METHOD) {
      reset();
      const customizations = {...state.customizations, recipeTools: action.data.recipeTools};
      const data = {...state, customizations: customizations};
      customizeRecipes(data);
      makeAheadRecipeIds(data);
      scale(data);
      return data;
    } else if (action.type === actions.UPDATE_INGREDIENTS) {
      reset();
      const customizations = {...state.customizations, ingredients: action.data.ingredients};
      const data = {...state, customizations: customizations};
      customizeRecipes(data);
      makeAheadRecipeIds(data);
      scale(data);
      return data;
    } else if (action.type === actions.UPDATE_DELETED_CHILD_RECIPES) {
      reset();
      const customizations = {...state.customizations, deletedChildRecipes: action.data.deletedChildRecipes};
      const data = {...state, customizations: customizations};
      customizeRecipes(data);
      makeAheadRecipeIds(data);
      scale(data);
      return data;
    } else if (action.type === actions.UPDATE_CHILD_RECIPE_ADDITIONAL_PORTIONS) {
      reset();
      const customizations = {...state.customizations, childRecipeAdditionalPortions: action.data.childRecipeAdditionalPortions};
      const data = {...state, customizations: customizations};
      customizeRecipes(data);
      makeAheadRecipeIds(data);
      scale(data);
      return data;
    } else if (action.type === actions.SET_MEAL_SLOT) {
      const plan = {...state.plan, mealSlot: action.data.mealSlot};
      return { ...state, plan: plan};
    } else if (action.type === actions.SET_DAY_BOOKMARK) {
      return {...state, dayBookmark: action.data.dayBookmark};
    }
    return state;
  }
  const [state, dispatch] = useReducer(reducer, initialState);

  useEffect(() => {
    if (isNotEmpty(token) && member.hasMP) {
      getAll();
    }
  }, [token, member]);

  // Helpers

  function addAppearances(plan, childRecipeIds) {
    for (let section of plan.sections) {
      for (let meal of section.meals) {
        const recipe = plan.recipeData.recipes.find(r => r.id === meal.recipeId);
        if (!recipe.hasOwnProperty("appearances")) {
          recipe["appearances"] = [];
        }
        const ratio = meal.portions / recipe.portions;
        recipe.appearances.push({recipeId: recipe.id, ratio: ratio});
        addChildAppearances(plan.recipeData.recipes, recipe, ratio, childRecipeIds);
      }
    }
  }

  function addChildAppearances(recipes, recipe, ratio, childRecipeIds) {
    const components = recipe.stoveTopRecipeComponents.length > 0 ? recipe.stoveTopRecipeComponents : recipe.instantPotRecipeComponents;
    for (let component of components) {
      if (component.category === recipeComponentCategories.RECIPE) {
        addChildAppearance(recipes, component, ratio, childRecipeIds);
      }
    }
  }

  function addChildAppearance(recipes, component, ratio, childRecipeIds) {
    const recipe = recipes.find(r => r.id === component.childRecipeId);
    const childRecipeIdIndex = childRecipeIds.indexOf(recipe.id);
    if (childRecipeIdIndex !== -1) {
      // When the child recipe already exist, we remove it and add it back at the end of
      // the array. Since this array will be eventually reversed, we make sure that the 
      // deepest child recipes appear first when making a recipe
      childRecipeIds.splice(childRecipeIdIndex, 1);
    }
    childRecipeIds.push(recipe.id);
    if (!recipe.hasOwnProperty("appearances")) {
      recipe["appearances"] = [];
    }
    let appearanceRatio = 0;
    if (isNotEmpty(component.volume)) {
      appearanceRatio = component.volume / recipe.yieldVolume * ratio;
      recipe.appearances.push({recipeId: component.parentRecipeId, ratio: appearanceRatio});
      addChildAppearances(recipes, recipe, appearanceRatio, childRecipeIds);
    }
  }

  function addRatios(plan) {
    for (let recipe of plan.recipeData.recipes) {
      recipe["ratio"] = recipe.appearances.map(a => a.ratio).reduce((a, b) => a + b, 0);
      for (let section of plan.sections) {
        for (let meal of section.meals) {
          if (meal.recipeId === recipe.id) {
            recipe["customPortions"] = meal.totalPortions;
            break;
          }
        }
      }
    }
  }

  function addTotalPortions(plan) {
    for (let section of plan.sections) {
      for (let meal of section.meals) {
        const master = plan.recipeData.masters.find(r => r.id === meal.recipeId);
        const recipe = plan.recipeData.recipes.find(r => r.id === meal.recipeId);
        const totalRatio = recipe.appearances.map(a => a.ratio).reduce((a, b) => a + b, 0);
        const totalPortions = totalRatio * recipe.portions;
        meal["defaultPortions"] = master.portions;
        meal["totalPortions"] = Math.round(totalPortions);
      }
    }
  }

  // API calls


  function getAll() {
    const plansPromise = axios({
      method: "get",
      headers: {
        "Content-Type": "application/json",
        "Authorization": `Bearer ${token}`
      },
      url: `${process.env.REACT_APP_API}/meal-planner`,
    });
    const availableImagesPromise = axios({
      method: "get",
      headers: {
        "Content-Type": "application/json",
        "Authorization": `Bearer ${token}`
      },
      url: `${process.env.REACT_APP_API}/meal-planner/available-images`,
    });
    Promise.all([plansPromise, availableImagesPromise]).then(responses => {
        dispatch({
          type: actions.LOAD_ALL,
          data: { plans: responses[0].data, availableImages: responses[1].data }
        });
    }).catch(err => {
      handleError(err);
    });
  }

  function get(name) {
    dispatch({
      type: actions.LOADING,
      data: {loading: true}
    });
    const nameParts = name.split("-");
    if (nameParts.length > 1) {
      const id = nameParts[0];
      getAllData(id);
    }
  }

  function getAllData(id, cb) {
    axios({
      method: "get",
      headers: {
        "Content-Type": "application/json",
        "Authorization": `Bearer ${token}`
      },
      url: `${process.env.REACT_APP_API}/meal-planner/${id}`,
    }).then(wmpRes => {
      axios({
        method: "get",
        headers: {
          "Content-Type": "application/json",
          "Authorization": `Bearer ${token}`
        },
        url: `${process.env.REACT_APP_API}/meal-planner/${id}/customizations`,
      }).then(customizationRes => {
        dispatch({
          type: actions.LOAD_ONE,
          data: {plan: wmpRes.data, customizations: customizationRes.data}
        });
        if (cb) cb();
      }).catch(err => {
        console.log(err);
        if (cb) cb();
      });
    }).catch(err => {
      if (err.response.status === 401) {
        // Get public data for this weekly meal plan
        getPublicData(id, cb);
      } else {
        handleError(err);
      }
    });
  }

  function getPublicData(id, cb) {
    axios({
      method: "get",
      headers: {
        "Content-Type": "application/json",
        "Authorization": `Bearer ${token}`
      },
      url: `${process.env.REACT_APP_API}/meal-planner/${id}/public-data`,
    }).then(res => {
      dispatch({
        type: actions.LOAD_PUBLIC,
        data: {publicPlan: res.data},
      });
      if (cb) cb();
    }).catch(err => {
      handleError(err);
    });
  }

  function create(data, cb) {
    const listView = isNotEmpty(member.preferences.mealPlannerListView) ? member.preferences.mealPlannerListView : false;
    axios({
      method: "post",
      headers: {
        "Content-Type": "application/json",
        "Authorization": `Bearer ${token}`
      },
      url: `${process.env.REACT_APP_API}/meal-planner`,
      data: {name: data.name, listView: listView}
    }).then(res => {
      dispatch({
        type: actions.LOAD_ALL,
        data: { plans: res.data, availableImages: state.availableImages }
      });
      if (cb && res.data.length > 0) {
        cb(res.data[0].id);
      }
    }).catch(() => {
      // do nothing
    });
  }

  function update(data, cb) {
    const plan = state.plans.find(p => p.id === data.id);
    if (isNotEmpty(plan)) {
      plan.name = data.name;
      plan.description = data.description;
      plan.storageId = data.storageId;
      axios({
        method: "put",
        headers: {
          "Content-Type": "application/json",
          "Authorization": `Bearer ${token}`
        },
        url: `${process.env.REACT_APP_API}/meal-planner/${plan.id}`,
        data: plan
      }).then(res => {
        dispatch({
          type: actions.LOAD_ALL,
          data: { plans: res.data, availableImages: state.availableImages }
        });
        if (cb) cb();
      }).catch(() => {
        if (cb) cb();
      });
    }
  }

  function togglePublicAvailability() {
    if (state.plan.id !== null) {
      axios({
        method: "put",
        headers: {
          "Content-Type": "application/json",
          "Authorization": `Bearer ${token}`
        },
        url: `${process.env.REACT_APP_API}/meal-planner/${state.plan.id}`,
        data: {
          name: state.plan.name,
          description: state.plan.description,
          publiclyAvailable: !state.plan.publiclyAvailable,
          listView: state.plan.listView,
          storageId: state.plan.storageId
        }
      }).then(() => {
        getAllData(state.plan.id);
      }).catch(() => {
        // do nothing
      });
    }
  }

  function toggleListView() {
    if (state.plan.id !== null) {
      axios({
        method: "put",
        headers: {
          "Content-Type": "application/json",
          "Authorization": `Bearer ${token}`
        },
        url: `${process.env.REACT_APP_API}/meal-planner/${state.plan.id}`,
        data: {
          name: state.plan.name,
          description: state.plan.description,
          publiclyAvailable: state.plan.publiclyAvailable,
          listView: !state.plan.listView,
          storageId: state.plan.storageId
        }
      }).then(() => {
        getAllData(state.plan.id);
      }).catch(() => {
        // do nothing
      });
    }
  }

  function deletePlan(id, cb) {
    const plan = state.plans.find(p => p.id === id);
    if (isNotEmpty(plan)) {
      axios({
        method: "delete",
        headers: {
          "Content-Type": "application/json",
          "Authorization": `Bearer ${token}`
        },
        url: `${process.env.REACT_APP_API}/meal-planner/${id}`,
      }).then(() => {
          getAll();
          // When a plan is deleted, we need to refresh the quick links in case the plan was there before the deletion
          refreshQuickLinks();
          if (cb) cb();
      }).catch(() => {
        // do nothing
      });
    }
  }

  function deleteCustomizations(cb) {
    axios({
      method: "delete",
      headers: {
        "Content-Type": "application/json",
        "Authorization": `Bearer ${token}`
      },
      url: `${process.env.REACT_APP_API}/meal-planner/${state.plan.id}/customizations`,
    }).then(res => {
      dispatch({
        type: actions.UPDATE_CUSTOMIZATIONS,
        data: {customizations: res.data}
      });
      if (cb) cb();
    }).catch(err => {
      console.log(err);
      if (cb) cb();
    });
  }

  function setMealSlot(data) {
    dispatch({
      type: actions.SET_MEAL_SLOT,
      data: { mealSlot: data }
    });
  }

  function setDayBookmark(data) {
    dispatch({
      type: actions.SET_DAY_BOOKMARK,
      data: { dayBookmark: data }
    });
  }

  function createSection(planId, data, cb) {
    axios({
      method: "post",
      headers: {
        "Content-Type": "application/json",
        "Authorization": `Bearer ${token}`
      },
      url: `${process.env.REACT_APP_API}/meal-planner/${planId}/sections`,
      data: data
    }).then(res => {
      dispatch({
        type: actions.UPDATE_CUSTOMIZATIONS,
        data: { customizations: res.data }
      });
      if (cb) cb();
    }).catch(() => {
      // do nothing
      if (cb) cb();
    });
  }

  function updateSection(data) {
    axios({
      method: "put",
      headers: {
        "Content-Type": "application/json",
        "Authorization": `Bearer ${token}`
      },
      url: `${process.env.REACT_APP_API}/meal-planner/${state.plan.id}/sections/${data.id}`,
      data: data
    }).then(res => {
      dispatch({
        type: actions.UPDATE_CUSTOMIZATIONS,
        data: { customizations: res.data }
      });
    }).catch(() => {
      // do nothing
    });
  }

  function deleteSection(sectionId) {
    axios({
      method: "delete",
      headers: {
        "Content-Type": "application/json",
        "Authorization": `Bearer ${token}`
      },
      url: `${process.env.REACT_APP_API}/meal-planner/${state.plan.id}/sections/${sectionId}`
    }).then(res => {
      dispatch({
        type: actions.UPDATE_CUSTOMIZATIONS,
        data: { customizations: res.data }
      });
    }).catch(() => {
      // do nothing
    });
  }

  function copyDay(day, cb) {
    axios({
      method: "get",
      headers: {
        "Content-Type": "application/json",
        "Authorization": `Bearer ${token}`
      },
      url: `${process.env.REACT_APP_API}/meal-planner/${state.plan.id}/sections/${day}/copy`,
    }).then(() => {
      getAllData(state.plan.id, cb);
    });
  }

  function addMeal(data, cb) {
    axios({
      method: "post",
      headers: {
        "Content-Type": "application/json",
        "Authorization": `Bearer ${token}`
      },
      url: `${process.env.REACT_APP_API}/meal-planner/${state.plan.id}/meals`,
      data: data
    }).then(() => {
      getAllData(state.plan.id, cb);
    });
  }

  function updateMeal(meal) {
    axios({
      method: "put",
      headers: {
        "Content-Type": "application/json",
        "Authorization": `Bearer ${token}`
      },
      url: `${process.env.REACT_APP_API}/meal-planner/${state.plan.id}/meals/${meal.id}`,
      data: meal
    }).then(res => {
      if (res.status === 200) {
        // @ts-ignore
        dispatch({
          type: actions.UPDATE_CUSTOMIZATIONS,
          data: { customizations: res.data }
        });
      }
    }).catch(() => {
      // do nothing
    });
  }

  function toggleMakeAhead(recipeId) {
    const toggle = state.customizations.makeAheadRecipes.find(t => t.recipeId === recipeId);
    const method = toggle !== undefined ? "delete" : "put";
    axios({
      method: method,
      headers: {
        "Content-Type": "application/json",
        "Authorization": `Bearer ${token}`
      },
      url: `${process.env.REACT_APP_API}/meal-planner/${state.plan.id}/make-ahead-recipes/${recipeId}`,
    }).then(res => {
      if (res.status === 200) {
        // @ts-ignore
        dispatch({
          type: actions.UPDATE_CUSTOMIZATIONS,
          data: { customizations: res.data }
        });
      }
    }).catch(() => {
      // do nothing
    });
  }

  function updatePrep(data) {
    axios({
      method: "put",
      headers: {
        "Content-Type": "application/json",
        "Authorization": `Bearer ${token}`
      },
      url: `${process.env.REACT_APP_API}/meal-planner/${state.plan.id}/make-ahead-recipes/${data.recipeId}/ingredients/${data.ingredientId}`,
      data: data,
    }).then(res => {
      dispatch({
        type: actions.UPDATE_PREPS,
        data: { preps: res.data }
      });
    });
  }

  function changePortions(meal, portions) {
    const data = {id: meal.id, portions: portions, makeAhead: meal.makeAhead};
    updateMeal(data);
  }

  function changePortionsInRecipeVault(meal, cb) {
    axios({
      method: "put",
      headers: {
        "Content-Type": "application/json",
        "Authorization": `Bearer ${token}`
      },
      url: `${process.env.REACT_APP_API}/recipe-vault/${meal.recipeId}`,
      data: {recipeId: meal.recipeId, portions: meal.portions}
    }).then(() => {
      if (cb) cb();
    }).catch(() => {
      if (cb) cb();
    });
  }

  function replaceMeal(previousMeal, meal, cb) {
    axios({
      method: "delete",
      headers: {
        "Content-Type": "application/json",
        "Authorization": `Bearer ${token}`
      },
      url: `${process.env.REACT_APP_API}/meal-planner/${state.plan.id}/meals/${previousMeal.id}`
    }).then(() => {
      getPersonalizedContent();
      addMeal(meal, cb);
    });
  }

  function removeMeal(meal) {
    axios({
      method: "delete",
      headers: {
        "Content-Type": "application/json",
        "Authorization": `Bearer ${token}`
      },
      url: `${process.env.REACT_APP_API}/meal-planner/${state.plan.id}/meals/${meal.id}`
    }).then(() => {
      getPersonalizedContent();
      getAllData(state.plan.id);
    });
  }

  function changeCookingMethod(recipeId, toolName) {
    const toolId = cache.toolId(toolName);
    axios({
      method: "put",
      headers: {
        "Content-Type": "application/json",
        "Authorization": `Bearer ${token}`
      },
      url: `${process.env.REACT_APP_API}/meal-planner/${state.plan.id}/recipe-tools/${recipeId}/tools/${toolId}`,
      data: {recipeId: recipeId, toolId: toolId}
    }).then(res => {
      dispatch({
        type: actions.CHANGE_COOKING_METHOD,
        data: { recipeTools: res.data}
      });
    });
  }

  function updateCheckboxes(recipeId, checkboxes) {
    dispatch({type: actions.UPDATING_CHECKBOXES});
    let updatePersonalizedContent = false;
    const recipeMade = { 
      madeAt: DateTime.local().toString(), // get local datetime to record or remove recipe made at
      recipeId: recipeId,
      weeklyMealPlanId: state.plan.id,
    };
    if (checkboxes.filter(c => !c).length === 0) { // if all checkboxes are true, record a recipe made
      updatePersonalizedContent = true;
      const recipe = state.plan.recipeData.recipes.find(r => r.id === recipeId);
      let stoveTopOrInstantPot = null;
      if (recipe.hasInstantPotAndStoveTopVersions) {
        stoveTopOrInstantPot = recipe.stoveTopRecipeComponents.length > 0 ? "Stove top" : "Instant Pot";
      }
      recipeMade["portions"] = recipe.type === "Assembly" ? recipe.customPortions : null;
      recipeMade["yieldVolume"] = recipe.scaledYieldVolume;
      recipeMade["yieldUnitId"] = recipe.yieldUnitId;
      recipeMade["stoveTopOrInstantPot"] = stoveTopOrInstantPot;
    }
    axios({
      method: "put",
      headers: {
        "Content-Type": "application/json",
        "Authorization": `Bearer ${token}`
      },
      url: `${process.env.REACT_APP_API}/meal-planner/${state.plan.id}/recipe-checkboxes/${recipeId}`,
      data: {recipeCheckboxes: {recipeId: recipeId, checkboxes: checkboxes}, recipeMade: recipeMade}
    }).then(res => {
      if (updatePersonalizedContent) {
        getPersonalizedContent();
      }
      dispatch({
        type: actions.UPDATE_RECIPES_CHECKBOXES,
        data: { recipeId: recipeId, recipesCheckboxes: res.data}
      });
    });
  }

  function updateIngredient(ingredientId, checked) {
    axios({
      method: "put",
      headers: {
        "Content-Type": "application/json",
        "Authorization": `Bearer ${token}`
      },
      url: `${process.env.REACT_APP_API}/meal-planner/${state.plan.id}/ingredients/${ingredientId}`,
      data: {ingredientId: ingredientId, checked: checked}
    }).then(res => {
      dispatch({
        type: actions.UPDATE_INGREDIENTS,
        data: { ingredients: res.data }
      });
    });
  }

  function deleteIngredients() {
    axios({
      method: "delete",
      headers: {
        "Content-Type": "application/json",
        "Authorization": `Bearer ${token}`
      },
      url: `${process.env.REACT_APP_API}/meal-planner/${state.plan.id}/ingredients`,
    }).then(() => {
      dispatch({
        type: actions.UPDATE_INGREDIENTS,
        data: { ingredients: [] }
      });
    });
  }

  function deleteChildRecipe(parentRecipeId, childRecipeId) {
    const parentRecipe = state.plan.recipeData.recipes.find(r => r.id === parentRecipeId);
    if (isNotEmpty(parentRecipe)) {
      const components = parentRecipe.stoveTopRecipeComponents.length > 0 ? parentRecipe.stoveTopRecipeComponents : parentRecipe.instantPotRecipeComponents;
      const rc = components.find(rc => rc.childRecipeId === childRecipeId);
      if (isNotEmpty(rc)) {
        axios({
          method: "delete",
          headers: {
            "Content-Type": "application/json",
            "Authorization": `Bearer ${token}`
          },
          url: `${process.env.REACT_APP_API}/meal-planner/${state.plan.id}/recipes/${parentRecipeId}/child-recipes/${childRecipeId}`,
        }).then(res => {
          dispatch({
            type: actions.UPDATE_DELETED_CHILD_RECIPES,
            data: { deletedChildRecipes: res.data }
          });
        });
      }
    }
  }

  function restoreChildRecipes(parentRecipeId ) {
    axios({
      method: "get",
      headers: {
        "Content-Type": "application/json",
        "Authorization": `Bearer ${token}`
      },
      url: `${process.env.REACT_APP_API}/meal-planner/${state.plan.id}/recipes/${parentRecipeId}/restore-child-recipes`,
    }).then(res => {
      dispatch({
        type: actions.UPDATE_DELETED_CHILD_RECIPES,
        data: { deletedChildRecipes: res.data }
      });
    });
  }

  function updateChildRecipeAdditionalPortions(data) {
    axios({
      method: "put",
      headers: {
        "Content-Type": "application/json",
        "Authorization": `Bearer ${token}`
      },
      url: `${process.env.REACT_APP_API}/meal-planner/${state.plan.id}/child-recipes/${data.childRecipeId}/additional-portions`,
      data: data,
    }).then(res => {
      dispatch({
        type: actions.UPDATE_CHILD_RECIPE_ADDITIONAL_PORTIONS,
        data: { childRecipeAdditionalPortions: res.data }
      });
    });
  }

  function copyPublicPlan(cb) {
    if (isNotEmpty(state.publicPlan.id)) {
      axios({
        method: "get",
        headers: {
          "Content-Type": "application/json",
          "Authorization": `Bearer ${token}`
        },
        url: `${process.env.REACT_APP_API}/meal-planner/${state.publicPlan.id}/copy`,
      }).then(res => {
        getAll();
        if (cb) cb(res.data);
      }).catch(() => {
        if (cb) cb();
      });
    }
  }

  function prepareDataForNutritionFacts(recipeId) {
    return {
      recipeId: recipeId,
      recipeData: {masters: JSON.parse(JSON.stringify(state.plan.recipeData.masters)), recipes: JSON.parse(JSON.stringify(state.plan.recipeData.masters))},
      recipeTools: state.customizations.recipeTools,
      deletedChildRecipes: state.customizations.deletedChildRecipes,
      ingredients: state.plan.ingredientData.ingredients,
      ingredientNutrients: state.plan.ingredientNutrients,
    };
  }

  const mmpStore = {
    state: state,
    copyDay: copyDay,
    createSection: createSection,
    updateSection: updateSection,
    deleteSection: deleteSection,
    addMeal: addMeal,
    changePortions: changePortions,
    changePortionsInRecipeVault: changePortionsInRecipeVault,
    create: create,
    deleteCustomizations: deleteCustomizations,
    update: update,
    get: get,
    getAllData: getAllData,
    deletePlan: deletePlan,
    replaceMeal: replaceMeal,
    removeMeal: removeMeal,
    toggleMakeAhead: toggleMakeAhead,
    updatePrep: updatePrep,
    togglePublicAvailability: togglePublicAvailability,
    toggleListView: toggleListView,
    copyPublicPlan: copyPublicPlan,
    changeCookingMethod: changeCookingMethod,
    updateCheckboxes: updateCheckboxes,
    updateChildRecipeAdditionalPortions: updateChildRecipeAdditionalPortions,
    updateIngredient: updateIngredient,
    deleteIngredients: deleteIngredients,
    deleteChildRecipe: deleteChildRecipe,
    restoreChildRecipes: restoreChildRecipes,
    setMealSlot: setMealSlot,
    setDayBookmark: setDayBookmark,
    prepareDataForNutritionFacts: prepareDataForNutritionFacts,
    specialScalingIds: cache.specialScalingIds,
  }

  return {mmpStore};
}