import React, {useState} from "react";
import {Redirect, useLocation} from "react-router-dom";

export default function SignInButton() {
  let location = useLocation();
  const [redirect, setRedirect] = useState(false);

  function handleClick(e) {
    e.preventDefault();
    setRedirect(true);
  }
  return redirect ? (
    <Redirect
      to={{
        pathname: "/sign-in",
        state: {from: location},
      }}
    />
  ) : (
    <button type="button" className="btn btn-light" onClick={handleClick}>Sign in</button>
  );
}