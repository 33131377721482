import "./App.css";
import "react-image-lightbox/style.css";
import "./ril.css";
import React, { useEffect, useState } from "react";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import useRouting from "../services/stores/RoutingStore";
import useAuth from "../services/stores/AuthStore";
import useCache from "../services/stores/CacheStore";
import useMember from "../services/stores/MemberStore";
import useMenu from "../services/stores/MenuStore";
import RecipeHelper from "../services/RecipeHelper";
import SignIn from "./Auth/SignIn";
import ForgotPassword from "./Auth/ForgotPassword";
import TileCollection from "./cms/TileCollection/TileCollection";
import WizardPlan from "./WizardPlan/WizardPlan";
import Playlist from "./cms/Playlist/Playlist";
import Account from "../pages/Account/Account";
import SignUp from "./Auth/SignUp";
import Trial from "./Auth/Trial";
import MagicMealPlans from "./MagicMealPlan/MagicMealPlans";
import MagicMealPlan from "./MagicMealPlan/MagicMealPlan";
import Header from "./components/Header";
import useSubscription from "../services/stores/SubscriptionStore";
import useGroceries from "../services/stores/GroceriesStore";
import useMagicMealPlan from "../services/stores/MagicMealPlannerStore";
import useStash from "../services/stores/StashStore";
import ScalingService from "../services/ScalingService";
import useReview from "../services/stores/ReviewStore";
import useNote from "../services/stores/NoteStore";
import Constants from "../services/Constants";
import useRecipe from "../services/stores/RecipeStore";
import StandaloneRecipe from "./Recipe/StandaloneRecipe";
import ReviewsModal from "./components/ReviewsModal";
import { Modal } from "bootstrap";
import NutritionFactsModal from "./components/NutritionFactsModal";
import useFeatured from "../services/stores/FeaturedStore";
import Gift from "./Member/Gift";
import AddToMealPlanModal from "./AddModal/AddToMealPlanModal";
import useNutritionFacts from "../services/NutritionFactsHook";
import NotFound from "./RestrictedContent/NotFound";
import Featured from "./cms/Featured/Featured";
import ScrollToTop from "../services/ScrollToTop";
import Error from "./Error/Error";
import ResetPassword from "./Auth/ResetPassword";
import PortalRedirect from "./components/PortalRedirect";
import MembersOnly from "./RestrictedContent/MembersOnly";
import VersionToast from "./components/VersionToast";
import GoToPlanToast from "./components/GoToPlanToast";
import useAddToPlan from "../services/stores/AddToPlanStore";
import ThankYou from "./Subscription/ThankYou";
import TrialBanner from "./Subscription/TrialBanner";
import Collection from "./Collection/Collection";
import UniversalSearch from "./Search/UniversalSearch";
import usePersonalizedContent from "../services/stores/PersonalizedContentStore";
import CookingJournal from "./Member/CookingJournal";
import Homepage from "./cms/Homepage/Homepage";
import useHomepage from "../services/stores/HomepageStore";
import CreatePassword from "./Auth/CreatePassword";
import FullMembershipOnly from "./RestrictedContent/FullMembershipOnly";
import FullMembershipRequiredModal from "./components/FullMembershipRequiredModal";
import useNotifications from "../hooks/useNotifications";

export default function App() {
  const { documentTypes, routeTypes, searchOrigins } = Constants();
  const { cache } = useCache();
  const {
    token,
    authenticated,
    giftStore,
    signIn,
    signUp,
    startTrial,
    signOut,
    createPasswordReset,
    setAuthMessage,
    resetAuthMessage,
    resetPassword,
    authMessage,
    setUtmParams,
  } = useAuth();
  const { memberStore } = useMember(token, setAuthMessage, cache.ready, signOut);
  const routingStore = useRouting();
  const menuStore = useMenu(token, memberStore.member);
  const notificationStore = useNotifications(token);
  const homepageStore = useHomepage(token, memberStore.member, memberStore.waitingForMember);
  const { subStore } = useSubscription(token, memberStore.member, memberStore.getMember);
  const { reviewStore } = useReview(token, memberStore.member);
  const { noteStore } = useNote(token, memberStore.member);
  const { gStore } = useGroceries(token, memberStore.member);
  const { rh } = RecipeHelper(cache, memberStore.member);
  const { stashStore } = useStash(token, memberStore.member);
  const { ss } = ScalingService(cache, rh, memberStore.member);
  const personalizedContentStore = usePersonalizedContent(token);
  const { mmpStore } = useMagicMealPlan(cache, token, memberStore.member, ss, personalizedContentStore.get, homepageStore.refreshQuickLinks);
  const { recipeStore } = useRecipe(cache, rh, token, memberStore.member, ss, personalizedContentStore.get);
  const { featuredStore } = useFeatured(token, memberStore.member);
  const { nutritionFactsStore } = useNutritionFacts(cache, rh, ss, memberStore.member);
  const { addToPlanStore } = useAddToPlan(cache, token, memberStore.member, mmpStore);
  const [showTrialBanner, setShowTrialBanner] = useState(true);

  useEffect(() => {
    const addToMealPlanModal = document.getElementById("add-to-meal-plan-modal");
    addToMealPlanModal.addEventListener("hidden.bs.modal", () => {
      addToPlanStore.resetDocument();
    });
    const reviewsModal = document.getElementById("reviews-modal");
    reviewsModal.addEventListener("hidden.bs.modal", () => {
      reviewStore.resetDocumentData();
    });
    const nutritionFactsModal = document.getElementById("nutrition-facts-modal");
    nutritionFactsModal.addEventListener("hidden.bs.modal", () => {
      nutritionFactsStore.resetData();
    });
  }, []);

  const trialBanner = (
    <TrialBanner member={memberStore.member} subscription={subStore.subscription} show={showTrialBanner} hide={() => setShowTrialBanner(false)} />
  );

  function showAddToMealPlan(data) {
    addToPlanStore.setDocument(data);
    const element = document.getElementById("add-to-meal-plan-modal");
    const modal = Modal.getOrCreateInstance(element);
    modal.show();
  }

  function hideAddToMealPlan() {
    const element = document.getElementById("add-to-meal-plan-modal");
    const modal = Modal.getOrCreateInstance(element);
    modal.hide();
  }

  function showReviews(data) {
    reviewStore.setDocumentData(data);
    const element = document.getElementById("reviews-modal");
    const reviewsModal = Modal.getOrCreateInstance(element);
    reviewsModal.show();
  }

  function hideReviews() {
    const element = document.getElementById("reviews-modal");
    const modal = Modal.getOrCreateInstance(element);
    modal.hide();
  }

  function showNutritionFacts(data) {
    nutritionFactsStore.generateRecipeNutritionFacts(data);
    const element = document.getElementById("nutrition-facts-modal");
    const modal = Modal.getOrCreateInstance(element);
    modal.show();
  }

  function hideNutritionFacts() {
    const element = document.getElementById("nutrition-facts-modal");
    const modal = Modal.getOrCreateInstance(element);
    modal.hide();
  }

  function showFullMembershipRequiredModal() {
    const element = document.getElementById("full-membership-required-modal");
    const modal = Modal.getOrCreateInstance(element);
    modal.show();
  }

  function addMembersOnlyRoutes(result) {
    for (let [i, r] of routingStore.routes
      .filter((route) => route.slug !== "home" && [routeTypes.RECIPE_COLLECTION, routeTypes.WIZARD_PLAN_COLLECTION].indexOf(route.type) === -1)
      .entries()) {
      result.push(
        <Route exact path={`/${r.slug}`} key={i + 1}>
          <MembersOnly authenticated={authenticated} />
        </Route>
      );
    }
    result.push(
      <Route exact path="/meal-plans/featured-wizard-plans" key="featured-wizard-plans">
        <MembersOnly authenticated={authenticated} />
      </Route>
    );
    result.push(
      <Route exact path="/recipes/recipe-vault" key="recipes">
        <MembersOnly authenticated={authenticated} />
      </Route>
    );
    result.push(
      <Route exact path="/recipes/featured-recipes" key="featured-recipes">
        <MembersOnly authenticated={authenticated} />
      </Route>
    );
    result.push(
      <Route exact path="/recipes/new-and-noteworthy-recipes" key="new-and-noteworthy-recipes">
        <MembersOnly authenticated={authenticated} />
      </Route>
    );
    result.push(
      <Route exact path="/meal-plans/meal-planner" key="meal-planner">
        <MembersOnly authenticated={authenticated} />
      </Route>
    );
    result.push(
      <Route exact path="/meal-plans/meal-planner/:id" key="weekly-meal-plan">
        <MembersOnly authenticated={authenticated} />
      </Route>
    );
  }

  function addFullMembershipOnlyRoutes(result) {
    for (let r of routingStore.routes.filter(
      (route) => route.slug !== "home" && route.slug.indexOf("recipes") === -1 && route.slug.indexOf("watch-learn") === -1
    )) {
      result.push(
        <Route exact path={`/${r.slug}`} key={r.slug}>
          <FullMembershipOnly />
        </Route>
      );
    }
    result.push(
      <Route exact path="/meal-plans/wizard-plans/:hashtag" key="wizard-plan">
        <FullMembershipOnly />
      </Route>
    );
    result.push(
      <Route exact path="/meal-plans/featured-wizard-plans" key="featured-wizard-plans">
        <FullMembershipOnly />
      </Route>
    );
    result.push(
      <Route exact path="/meal-plans/meal-planner" key="meal-planner">
        <FullMembershipOnly />
      </Route>
    );
    result.push(
      <Route exact path="/meal-plans/meal-planner/:id" key="weekly-meal-plan">
        <FullMembershipOnly />
      </Route>
    );
  }

  function addMembershipRoutes(result) {
    result.push(
      <Route exact path="/recipes/recipe-vault/:id" key="recipe">
        <StandaloneRecipe
          token={token}
          authenticated={authenticated}
          cacheReady={cache.ready}
          memberStore={memberStore}
          recipeStore={recipeStore}
          stashStore={stashStore}
          reviewStore={reviewStore}
          noteStore={noteStore}
          mmpStore={mmpStore}
          modals={modals}
          banner={trialBanner}
        />
      </Route>
    );
    result.push(
      <Route exact path="/recipes/featured-recipes" key="featured-recipes">
        <Featured
          authenticated={authenticated}
          member={memberStore.member}
          stashStore={stashStore}
          items={featuredStore.items.featuredRecipes}
          modals={modals}
          title="Featured Recipes"
          banner={trialBanner}
        />
      </Route>
    );
    result.push(
      <Route exact path="/recipes/new-and-noteworthy-recipes" key="new-and-noteworthy-recipes">
        <Featured
          authenticated={authenticated}
          member={memberStore.member}
          stashStore={stashStore}
          items={featuredStore.items.noteworthyRecipes}
          modals={modals}
          title="New & Noteworthy Recipes"
          banner={trialBanner}
        />
      </Route>
    );
    result.push(
      <Route exact path="/cooking-journal" key="cooking-journal">
        <CookingJournal
          authenticated={authenticated}
          cookingJournal={personalizedContentStore.state.cookingJournal}
          deleteRecipeMade={personalizedContentStore.deleteRecipeMade}
          member={memberStore.member}
          title="Cooking Journal"
          banner={trialBanner}
        />
      </Route>
    );
    result.push(
      <Route exact path="/search" key="search">
        <UniversalSearch
          token={token}
          authenticated={authenticated}
          cache={cache}
          memberStore={memberStore}
          stashStore={stashStore}
          modals={modals}
          banner={trialBanner}
          config={{
            path: "/search",
            searchOrigin: searchOrigins.GLOBAL,
            recommendedOnly: false,
            tagIds: [],
            setRecommendedOnly: null,
            add: null,
          }}
        />
      </Route>
    );
    result.push(
      <Route exact path="/" key="home">
        <Homepage
          homepageStore={homepageStore}
          authenticated={authenticated}
          modals={modals}
          member={memberStore.member}
          stashStore={stashStore}
          banner={trialBanner}
        />
      </Route>
    );
  }

  const modals = {
    showAddToMealPlan: showAddToMealPlan,
    showReviews: showReviews,
    showNutritionFacts: showNutritionFacts,
    showFullMembershipRequiredModal: showFullMembershipRequiredModal,
  };

  function renderRoutes() {
    const redirects = [];
    for (let redirect of routingStore.redirects) {
      if (redirect.sourceUrl.indexOf("*") === -1) {
        redirects.push(
          <Route key={redirect.id} exact path={redirect.sourceUrl}>
            <PortalRedirect redirect={redirect} />
          </Route>
        );
      } else {
        redirects.push(
          <Route key={redirect.id} path={redirect.sourceUrl}>
            <PortalRedirect redirect={redirect} />
          </Route>
        );
      }
    }

    const accountRoute = (
      <Route exact path="/account" key="account">
        <Account
          authenticated={authenticated}
          message={authMessage}
          cache={cache}
          memberStore={memberStore}
          subStore={subStore}
          resetMessage={resetAuthMessage}
        />
      </Route>
    );
    const errorRoute = (
      <Route exact path="/error" key="error">
        <Error />
      </Route>
    );
    const forgotPasswordRoute = (
      <Route exact path="/forgot-password" key="forgot-password">
        <ForgotPassword authenticated={authenticated} createPasswordReset={createPasswordReset} />
      </Route>
    );
    const giftRoute = (
      <Route exact path="/gift" key="gift">
        <Gift memberStore={memberStore} message={authMessage} resetMessage={resetAuthMessage} signUp={signUp} signOut={signOut} giftStore={giftStore} />
      </Route>
    );
    const notFoundRoute = (
      <Route exact path="/404" key="not-found">
        <NotFound authenticated={authenticated} />
      </Route>
    );
    const resetPasswordRoute = (
      <Route exact path="/reset-password" key="reset-password">
        <ResetPassword authenticated={authenticated} resetPassword={resetPassword} />
      </Route>
    );
    const signInRoute = (
      <Route exact path="/sign-in" key="sign-in">
        <SignIn authenticated={authenticated} signIn={signIn} resetMessage={resetAuthMessage} message={authMessage} />
      </Route>
    );
    const signUpRoute = (
      <Route exact path="/sign-up" key="sign-up">
        <SignUp
          authenticated={authenticated}
          message={authMessage}
          memberStore={memberStore}
          subStore={subStore}
          signUp={signUp}
          resetMessage={resetAuthMessage}
          setUtmParams={setUtmParams}
        />
      </Route>
    );
    const trialRoute = (
      <Route exact path="/trial" key="trial">
        <Trial
          authenticated={authenticated}
          message={authMessage}
          memberStore={memberStore}
          startTrial={startTrial}
          resetMessage={resetAuthMessage}
          setUtmParams={setUtmParams}
        />
      </Route>
    );

    const thankYouRoute = (
      <Route exact path="/thank-you" key="thank-you">
        <ThankYou authenticated={authenticated} />
      </Route>
    );

    const createPasswordRoute = (
      <Route exact path="/create-password" key="create-password">
        <CreatePassword authenticated={authenticated} resetPassword={resetPassword} />
      </Route>
    );

    const result = redirects.concat([
      accountRoute,
      errorRoute,
      forgotPasswordRoute,
      giftRoute,
      notFoundRoute,
      resetPasswordRoute,
      signInRoute,
      signUpRoute,
      trialRoute,
      thankYouRoute,
      createPasswordRoute,
    ]);
    if (authenticated) {
      if (memberStore.waitingForMember) {
        // We do nothing
      } else if (memberStore.member.hasMP) {
        // Full access to all CMS routes
        for (let [i, r] of routingStore.routes.filter((route) => route.slug !== "home").entries()) {
          if (r.type === routeTypes.TILE_COLLECTION) {
            result.push(
              <Route exact path={`/${r.slug}`} key={i + 1}>
                <TileCollection token={token} id={r.id} banner={trialBanner} />
              </Route>
            );
          } else if (r.type === routeTypes.VIDEO_PLAYLIST) {
            result.push(
              <Route exact path={`/${r.slug}`} key={i + 1}>
                <Playlist token={token} id={r.id} banner={trialBanner} />
              </Route>
            );
          } else if (r.type === routeTypes.RECIPE_COLLECTION) {
            result.push(
              <Route exact path={`/${r.slug}`} key={i + 1}>
                <Collection
                  authenticated={authenticated}
                  token={token}
                  member={memberStore.member}
                  stashStore={stashStore}
                  modals={modals}
                  id={r.id}
                  documentType={documentTypes.RECIPE}
                  banner={trialBanner}
                />
              </Route>
            );
          } else if (r.type === routeTypes.WIZARD_PLAN_COLLECTION) {
            result.push(
              <Route exact path={`/${r.slug}`} key={i + 1}>
                <Collection
                  authenticated={authenticated}
                  token={token}
                  member={memberStore.member}
                  stashStore={stashStore}
                  modals={modals}
                  id={r.id}
                  documentType={documentTypes.WIZARD_PLAN}
                  banner={trialBanner}
                />
              </Route>
            );
          }
        }
        // Add routes shared by all types of memberships
        addMembershipRoutes(result);
        // Add routes specific to the full membership
        result.push(
          <Route exact path="/meal-plans/wizard-plans/:hashtag" key="wizard-plan">
            <WizardPlan
              authenticated={authenticated}
              cache={cache}
              token={token}
              memberStore={memberStore}
              stashStore={stashStore}
              reviewStore={reviewStore}
              noteStore={noteStore}
              gStore={gStore}
              ss={ss}
              modals={modals}
              getPersonalizedContent={personalizedContentStore.get}
            />
          </Route>
        );
        result.push(
          <Route exact path="/meal-plans/featured-wizard-plans" key="featured-wizard-plans">
            <Featured
              authenticated={authenticated}
              member={memberStore.member}
              stashStore={stashStore}
              items={featuredStore.items.featuredWizardPlans}
              modals={modals}
              title="Featured meal plans"
              banner={trialBanner}
            />
          </Route>
        );
        result.push(
          <Route exact path="/meal-plans/meal-planner" key="meal-planner">
            <MagicMealPlans authenticated={authenticated} memberStore={memberStore} mmpStore={mmpStore} banner={trialBanner} />
          </Route>
        );
        result.push(
          <Route exact path="/meal-plans/meal-planner/:id" key="weekly-meal-plan">
            <MagicMealPlan
              authenticated={authenticated}
              token={token}
              cache={cache}
              stashStore={stashStore}
              memberStore={memberStore}
              mmpStore={mmpStore}
              gStore={gStore}
              reviewStore={reviewStore}
              noteStore={noteStore}
              modals={modals}
            />
          </Route>
        );
        // Whatever doesn't match the routes above, show the not found page
        result.push(
          <Route key="catch-all-not-found">
            <NotFound authenticated={authenticated} />
          </Route>
        );
      } else if (memberStore.member.hasRV) {
        // Add restricted routes
        addFullMembershipOnlyRoutes(result);
        // Add partial access to CMS routes
        for (let r of routingStore.routes.filter(
          (route) => route.slug !== "home" && (route.slug.indexOf("recipes") !== -1 || route.slug.indexOf("watch-learn") !== -1)
        )) {
          if (r.type === routeTypes.TILE_COLLECTION) {
            result.push(
              <Route exact path={`/${r.slug}`} key={r.slug}>
                <TileCollection token={token} id={r.id} banner={trialBanner} />
              </Route>
            );
          } else if (r.type === routeTypes.VIDEO_PLAYLIST) {
            result.push(
              <Route exact path={`/${r.slug}`} key={r.slug}>
                <Playlist token={token} id={r.id} banner={trialBanner} />
              </Route>
            );
          } else if (r.type === routeTypes.RECIPE_COLLECTION) {
            result.push(
              <Route exact path={`/${r.slug}`} key={r.slug}>
                <Collection
                  authenticated={authenticated}
                  token={token}
                  member={memberStore.member}
                  stashStore={stashStore}
                  modals={modals}
                  id={r.id}
                  documentType={documentTypes.RECIPE}
                  banner={trialBanner}
                />
              </Route>
            );
          }
        }
        // Add routes shared by all types of memberships
        addMembershipRoutes(result);
        // Whatever doesn't match the routes above, show the not found page
        result.push(
          <Route key="catch-all-not-found">
            <NotFound authenticated={authenticated} />
          </Route>
        );
      } else {
        // Existing user without a valid subscription
        addMembersOnlyRoutes(result);
        result.push(
          <Route exact path="/meal-plans/wizard-plans/:hashtag" key="wizard-plan">
            <MembersOnly authenticated={authenticated} />
          </Route>
        );
        result.push(
          <Route exact path="/recipes/recipe-vault/:id" key="recipe">
            <MembersOnly authenticated={authenticated} />
          </Route>
        );
        result.push(
          <Route exact path="/" key="home-account">
            <Account
              authenticated={authenticated}
              message={authMessage}
              cache={cache}
              memberStore={memberStore}
              subStore={subStore}
              resetMessage={resetAuthMessage}
            />
          </Route>
        );
        // Whatever doesn't match the routes above, show the not found page
        result.push(
          <Route key="catch-all-not-found">
            <NotFound authenticated={authenticated} />
          </Route>
        );
      }
    } else {
      // Anonymous user
      addMembersOnlyRoutes(result);
      result.push(
        <Route exact path="/meal-plans/wizard-plans/:hashtag" key="wizard-plan">
          <WizardPlan
            authenticated={authenticated}
            cache={cache}
            token={token}
            memberStore={memberStore}
            stashStore={stashStore}
            reviewStore={reviewStore}
            noteStore={noteStore}
            gStore={gStore}
            ss={ss}
            modals={modals}
            getPersonalizedContent={personalizedContentStore.get}
          />
        </Route>
      );
      result.push(
        <Route exact path="/recipes/recipe-vault/:id" key="recipe">
          <StandaloneRecipe
            token={token}
            authenticated={authenticated}
            cacheReady={cache.ready}
            memberStore={memberStore}
            recipeStore={recipeStore}
            stashStore={stashStore}
            reviewStore={reviewStore}
            noteStore={noteStore}
            mmpStore={mmpStore}
            modals={modals}
            banner={trialBanner}
          />
        </Route>
      );
      for (let [i, r] of routingStore.routes
        .filter((route) => [routeTypes.RECIPE_COLLECTION, routeTypes.WIZARD_PLAN_COLLECTION].indexOf(route.type) !== -1)
        .entries()) {
        if (r.type === routeTypes.RECIPE_COLLECTION) {
          result.push(
            <Route exact path={`/${r.slug}`} key={i + 1}>
              <Collection
                authenticated={authenticated}
                token={token}
                member={memberStore.member}
                stashStore={stashStore}
                modals={modals}
                id={r.id}
                documentType={documentTypes.RECIPE}
                banner={trialBanner}
              />
            </Route>
          );
        } else if (r.type === routeTypes.WIZARD_PLAN_COLLECTION) {
          result.push(
            <Route exact path={`/${r.slug}`} key={i + 1}>
              <Collection
                authenticated={authenticated}
                token={token}
                member={memberStore.member}
                stashStore={stashStore}
                modals={modals}
                id={r.id}
                documentType={documentTypes.WIZARD_PLAN}
                banner={trialBanner}
              />
            </Route>
          );
        }
      }
      result.push(
        <Route exact path="/" key="home">
          <Homepage homepageStore={homepageStore} authenticated={authenticated} modals={modals} member={memberStore.member} stashStore={stashStore} />
        </Route>
      );
      // Whatever doesn't match the routes above, show the not found page
      result.push(
        <Route key="catch-all-not-found">
          <NotFound authenticated={authenticated} />
        </Route>
      );
    }
    return result;
  }

  return (
    <BrowserRouter>
      <ScrollToTop />
      <Header
        authenticated={authenticated}
        menuStore={menuStore}
        quickLinks={homepageStore.state.quickLinks.items}
        toggleQuickLink={homepageStore.toggleQuickLink}
        signOut={signOut}
        notificationStore={notificationStore}
      />
      <Switch>{renderRoutes()}</Switch>
      <AddToMealPlanModal addToPlanStore={addToPlanStore} mmpStore={mmpStore} hide={hideAddToMealPlan} />
      <ReviewsModal authenticated={authenticated} cache={cache} store={reviewStore} hide={hideReviews} />
      <NutritionFactsModal store={nutritionFactsStore} hide={hideNutritionFacts} />
      <FullMembershipRequiredModal />
      <VersionToast />
      <GoToPlanToast store={addToPlanStore} />
    </BrowserRouter>
  );
}
