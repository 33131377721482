function CFDGHelpListicle({ isMPClub = false }) {
  const helpItems = [
    {
      title: "Become a confident plant-based cook",
      description: "Cook surprisingly delicious whole-food plant-based meals you and your (omni and plant-based) family will enjoy.",
    },
    {
      title: "Create your own meal plans",
      description: "Find the meals and recipes you want to make and easily add them to our Magic Meal Planner.",
    },
    {
      title: "Find joy in eating plant-based",
      description: "Experience the joy that bubbles up when the food you love loves you back.",
    },
    {
      title: "Crave healthy plant-based meals",
      description: "We'll show you how to truly prefer healthy plant-based meals to anything else.",
    },
    {
      title: "Stop being stressed about what to make",
      description:
        "Over 4,900 of the most delicious plant-based recipes you won't find anywhere else. Say goodbye to pesky ads and Instagram fails. Say hello to your kitchen BFF.",
    },
  ];
  const mainTitle = `Clean Food Dirty Girl ${isMPClub ? "Meal Plan Club " : ""}can help you`;

  return (
    <div className="mt-4">
      <h2>{mainTitle}</h2>
      {helpItems.map((item, index) => (
        <section key={index}>
          <SubHeading>{item.title}</SubHeading>
          <p>{item.description}</p>
        </section>
      ))}
    </div>
  );
}

function SubHeading({ children }) {
  return <h3 className="h-handwriting">{children}</h3>;
}

export default CFDGHelpListicle;
