import { DateTime } from "luxon";
import Constants from "../../../services/Constants";
import Helpers from "../../../services/Helpers";

export default function SubscriptionMessage({ memberStore, subStore }) {
  const { subscriptionMessages } = Constants();
  const { isNotEmpty } = Helpers();

  switch (subStore.subscriptionMessage) {
    case subscriptionMessages.ERROR_CARD:
      return (
        <div className="alert alert-primary" role="alert">
          Oh no! Your card was declined. Please update your billing information (below) and try again.
        </div>
      );
    case subscriptionMessages.ERROR:
      return (
        <div className="alert alert-primary" role="alert">
          Something went wrong, email us at <a href="mailto:support@cleanfooddirtygirl.com">support@cleanfooddirtygirl.com</a> and we'll help you out.
        </div>
      );
    case subscriptionMessages.CHANGE_SUBSCRIPTION_SUCCESS:
      return (
        <div className="alert alert-success" role="alert">
          Success! You membership plan has been updated.
        </div>
      );
    case subscriptionMessages.ADD_SUBSCRIPTION_SUCCESS:
      //TODO a little ambiguous here - to be checked
      if (isNotEmpty(memberStore.member.since)) {
        const since = DateTime.fromSeconds(memberStore.member.since);
        if (since.startOf("day") < DateTime.now().startOf("day")) {
          // The member reactivated
          return (
            <div className="alert alert-success" role="alert">
              Welcome back! We're so happy to have you!
            </div>
          );
        } else {
          // This is a new member
          return (
            <div className="alert alert-success" role="alert">
              Congrats! We're so happy to have you as a member!
            </div>
          );
        }
      }
    default:
      return null;
  }
}
