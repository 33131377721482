import { useLocation } from "react-router-dom";

export default function QuickLinksButton({ quickLinks, toggleQuickLink }) {
  const location = useLocation();
  const isLinkQuick = !!quickLinks.find((ql) => ql.link === location.pathname + location.search);

  return (
    <button
      type="button"
      className={`btn btn-${isLinkQuick ? "primary" : "light"}`}
      title={`${isLinkQuick ? "Remove" : "Add"} this page to quick links`}
      onClick={() => toggleQuickLink(location.pathname, location.search)}
    >
      <i className="fa-solid fa-link" />
    </button>
  );
}
