import "../../WizardPlan/IngredientPreps.css";
import React from "react";
import PropTypes from "prop-types";
import Helpers from "../../../services/Helpers";
import Checkbox from "../../components/Checkbox";
import Popover from "../../components/Popover";


export default function Prep(props) {
  const {isNotEmpty} = Helpers();

  function updatePrep(recipeComponentId) {
    const instruction =  props.prep.instructions.find(i => i.recipeComponentId === recipeComponentId);
    if (isNotEmpty(instruction)) {
      props.updatePrep({
        recipeId: instruction.recipeId,
        ingredientId: instruction.ingredientId,
        checked: !instruction.checked
      });
    }
  }

  function render() {
    let result = [];
    for (const [index, instruction] of props.prep.instructions.entries()) {
      const firstCol = index === 0 ? (
        <td>
          {props.prep.ingredientName}<Popover title={props.prep.ingredientName} photo={props.prep.ingredientPhoto}/>
        </td>
      ) : <td/>;
      const background = (props.i % 2) === 1 ? null : "table-secondary";
      const video = isNotEmpty(instruction.ingredientPrepVideoTitle) ? (
        <button
          className="btn btn-link"
          onClick={() => {
            props.showVideo({
              ingredientPrepVideoTitle: instruction.ingredientPrepVideoTitle,
              ingredientPrepVimeoId: instruction.ingredientPrepVimeoId
            });
          }}
        >
          <span className="far fa-play-circle text-muted"/>
        </button>
      ) : null;
      result.push(
        <tr key={index} className={background}>
          {firstCol}
          <td className="td-checkbox">
            <Checkbox
              checked={instruction.checked}
              i={instruction.recipeComponentId}
              toggle={updatePrep}
            />&nbsp;
          </td>
          <td className="instructions-wrap"><span className="td-instructions">{instruction.text}</span>{video}</td>
          <td className="td-recipename text-muted">{instruction.recipeName}</td>
        </tr>
      );
    }
    return <>{result}</>;
  }

  return render();
}

Prep.propTypes = {
  prep: PropTypes.object,
  i: PropTypes.number,
  updatePrep: PropTypes.func,
  showVideo: PropTypes.func,
}