import React, {useEffect, useState} from "react";
import PropTypes from "prop-types";

export default function StashButton(props) {
  const [working, setWorking] = useState(false);

  useEffect(() => {
    setWorking(false);
  }, [props.active]);

  function toggle(event) {
    event.stopPropagation();
    setWorking(true);
    props.toggle({documentId: props.documentId, documentType: props.documentType});
  }

  const btnSize = props.smallButton ? " btn-sm" : "";

  const className = props.darkTheme ? `btn${btnSize} btn-white${props.margin}` : `btn${btnSize} btn-light${props.margin}`;
  const title = props.title;

  function render() {
    let result;
    if (working) {
      result = (
        <button className={className} type="button" disabled={true} title={title}>
          <span className={props.inactiveClassName}/>
        </button>
      );
    } else {
      result = props.active ? (
        <button className={className} type="button" onClick={toggle} title={title}>
          <span className={props.activeClassName}/>
        </button>
      ) : (
        <button className={className} type="button" onClick={toggle} title={title}>
          <span className={props.inactiveClassName}/>
        </button>
      );
    }
    return result;
  }

  return render();
}

StashButton.propTypes = {
  documentId: PropTypes.number,
  documentType: PropTypes.string,
  darkTheme: PropTypes.bool,
  smallButton: PropTypes.bool,
  margin: PropTypes.string,
  title: PropTypes.string,
  active: PropTypes.bool,
  activeClassName: PropTypes.string,
  inactiveClassName: PropTypes.string,
  toggle: PropTypes.func,
}