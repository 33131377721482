import "./MagicMealPlan.css";
import React, { useEffect, useState } from "react";
import { Redirect, useHistory, useLocation, useParams } from "react-router-dom";
import PropTypes from "prop-types";
import Helpers from "../../services/Helpers";
import Constants from "../../services/Constants";
import AccordionRecipe from "../Recipe/components/AccordionRecipe";
import CustomGroceryItems from "../components/CustomGroceryItems";
import Recipe from "../Recipe/components/Recipe";
import GroceryListCategory from "../WizardPlan/components/GroceryListCategory";
import CopyPublicPlan from "./components/CopyPublicPlan";
import Prep from "./components/Prep";
import { Modal, Toast } from "bootstrap";
import VimeoVideo from "../components/VimeoVideo";
import AccordionItem from "../components/AccordionItem";
import Footer from "../components/Footer";
import Popover from "../components/Popover";
import Checkbox from "../components/Checkbox";
import MagicMealPlanSections from "./components/MagicMealPlanSections";
import PublicMagicMealPlanSections from "./components/PublicMagicMealPlanSections";
import MemberOnlyFeatureModal from "../components/MemberOnlyFeatureModal";
import UniversalSearch from "../Search/UniversalSearch";

const views = {
  PLAN: "plan",
  GROCERY_LIST: "groceries",
  MEAL: "meal",
  MAKE_AHEAD: "batching",
  SEARCH: "search",
};
export default function MagicMealPlan(props) {
  let { id } = useParams();
  let location = useLocation();
  let history = useHistory();
  const { contexts, mealTypes, searchOrigins } = Constants();
  const { assetUrl, capitalizeFirstLetter, isNotEmpty, shortenedText } = Helpers();
  const [view, setView] = useState(views.PLAN);
  const [mealSlot, setMealSlot] = useState(null);
  const [meal, setMeal] = useState(null);
  const [recommendedOnly, setRecommendedOnly] = useState(true); // Persist the "Meal Planner friendly" facet for the current session
  const [prepVideo, setPrepVideo] = useState(null);
  const [copied, setCopied] = useState(false);
  const [expandAll, setExpandAll] = useState(false);
  const [collapseAll, setCollapseAll] = useState(false);
  const [showHeaderDetails, setShowHeaderDetails] = useState(false);

  useEffect(() => {
    const element = document.getElementById("go-to-plan-toast");
    const toast = Toast.getOrCreateInstance(element);
    toast.hide();
  }, []);

  // When the URL changes, set the proper view
  useEffect(() => {
    let searchParams = new URLSearchParams(location.search);
    const v = searchParams.get("v");
    if (isNotEmpty(v)) {
      for (const [key, value] of Object.entries(views)) {
        if (v === value) {
          if (v === views.MEAL) {
            let cannotShowMeal = true;
            const id = searchParams.get("id");
            if (isNotEmpty(id)) {
              const meals = props.mmpStore.state.plan.sections.reduce((acc, s) => [...acc, ...s.meals], []);
              const desiredMeal = meals.find((m) => m.id.toString() === id);
              if (isNotEmpty(desiredMeal)) {
                cannotShowMeal = false;
                setMeal(desiredMeal);
              }
            }
            if (cannotShowMeal) {
              history.replace(`${location.pathname}?v=${views.PLAN}`);
            }
          } else if (v === views.SEARCH) {
            // The meal slot must be set to use this view
            if (isNotEmpty(mealSlot)) {
              setMeal(null);
              setView(views[key]);
            } else {
              history.replace(`${location.pathname}?v=${views.PLAN}`);
            }
          } else {
            setMeal(null);
            setView(views[key]);
          }
          break;
        }
      }
    } else {
      history.replace(`${location.pathname}?v=${views.PLAN}`);
    }
  }, [location]);

  // When the id changes and the cache is ready, get the plan data
  useEffect(() => {
    if (isNotEmpty(id) && props.cache.ready) {
      props.mmpStore.get(id);
    }
  }, [id, props.cache.ready]);

  // Set the page title when the plan name changes
  useEffect(() => {
    if (isNotEmpty(props.mmpStore.state.plan.name)) {
      document.title = `${props.mmpStore.state.plan.name} - Clean Food Dirty Girl`;
    }
  }, [props.mmpStore.state.plan.name]);

  // When the meal slot is set, show the search page to fill that slot
  useEffect(() => {
    if (!props.mmpStore.state.loading && !props.mmpStore.state.showPublicPlan) {
      if (isNotEmpty(mealSlot)) {
        history.push(`${location.pathname}?v=${views.SEARCH}`);
        props.mmpStore.setMealSlot(mealSlot);
      }
    }
  }, [props.mmpStore.state.loading, props.mmpStore.state.showPublicPlan, mealSlot]);

  // When the meal is set, show the Recipe or the plan
  useEffect(() => {
    if (isNotEmpty(meal)) {
      setView(views.MEAL);
    }
  }, [meal]);

  // Clean video modal when closing to avoid seeing the wrong video thumbnail for a moment
  useEffect(() => {
    if (!props.mmpStore.state.loading) {
      const element = document.getElementById("video-modal");
      element.addEventListener("hidden.bs.modal", () => {
        setPrepVideo(null);
      });
    }
  }, [props.mmpStore.state.loading]);

  useEffect(() => {
    if (expandAll) {
      setCollapseAll(false);
    }
  }, [expandAll]);

  useEffect(() => {
    if (collapseAll) {
      setExpandAll(false);
    }
  }, [collapseAll]);

  function viewMeal(data) {
    if (isNotEmpty(data)) {
      history.push(`${location.pathname}?v=${views.MEAL}&id=${data.id}`);
    }
  }

  function confirmDeleteCustomizations() {
    props.mmpStore.deleteCustomizations(() => {
      const element = document.getElementById("reset-modal");
      const resetModal = Modal.getOrCreateInstance(element);
      resetModal.hide();
    });
  }

  function showResetPlanModal() {
    const element = document.getElementById("reset-modal");
    const resetModal = Modal.getOrCreateInstance(element);
    resetModal.show();
  }

  function showPrepVideo(prepVideo) {
    setPrepVideo(prepVideo);
    const element = document.getElementById("video-modal");
    const videoModal = Modal.getOrCreateInstance(element);
    videoModal.show();
  }

  function add(recipeId) {
    if (isNotEmpty(mealSlot)) {
      const meal = {
        recipeId: recipeId,
        day: mealSlot.day,
        portions: null,
        mealType: mealSlot.mealType,
      };
      if (mealSlot.hasOwnProperty("mealIdToReplace") && isNotEmpty(mealSlot.mealIdToReplace)) {
        props.mmpStore.replaceMeal({ id: mealSlot.mealIdToReplace }, meal, () => {
          setMealSlot(null);
          // Go back to the plan view after the meal has been replaced
          history.replace(`${location.pathname}?v=${views.PLAN}`);
        });
      } else {
        props.mmpStore.addMeal(meal, () => {
          setMealSlot(null);
          // Go back to the plan view after the meal has been added
          history.replace(`${location.pathname}?v=${views.PLAN}`);
        });
      }
    }
  }

  const preps = props.mmpStore.state.plan.preps.map((p, index) => <Prep key={index} i={index} prep={p} updatePrep={props.mmpStore.updatePrep} showVideo={showPrepVideo} />);

  function makeAheadRecipes() {
    let result = [];
    for (let i = 0; i < props.mmpStore.state.plan.makeAheadRecipeIds.length; i++) {
      const recipe = props.mmpStore.state.plan.recipeData.views.find((r) => r.id === props.mmpStore.state.plan.makeAheadRecipeIds[i]);
      if (isNotEmpty(recipe)) {
        result.push(
          <AccordionRecipe
            key={recipe.id}
            index={i}
            member={props.memberStore.member}
            stashStore={props.stashStore}
            noteStore={props.noteStore}
            context={contexts.MAGIC_MEAL_PLANNER}
            recipe={recipe}
            recipes={props.mmpStore.state.plan.recipeData.recipes}
            modals={props.modals}
            canShowActionButtons={true}
            batchingContext={true}
            canShowNutritionFacts={props.memberStore.member.preferences.showNutritionFacts}
            expandAll={expandAll}
            collapseAll={collapseAll}
            specialScalingIds={props.mmpStore.specialScalingIds}
            changeCookingMethod={props.mmpStore.changeCookingMethod}
            updateAdditionalPortions={props.mmpStore.updateChildRecipeAdditionalPortions}
            updateCheckboxes={props.mmpStore.updateCheckboxes}
            prepareDataForNutritionFacts={props.mmpStore.prepareDataForNutritionFacts}
            setExpandAll={setExpandAll}
            setCollapseAll={setCollapseAll}
          />
        );
      }
    }
    return result;
  }

  const planButtonTitle = (
    <>
      <i className="far fa-calendar-day" /> <span className="ms-1 d-none d-sm-inline">Plan</span>
    </>
  );

  const planButton =
    view === views.PLAN ? (
      <button type="button" className="nav-link active" disabled={true}>
        {planButtonTitle}
      </button>
    ) : (
      <button type="button" className="nav-link" onClick={() => history.push(`${location.pathname}?v=${views.PLAN}`)}>
        {planButtonTitle}
      </button>
    );

  function makeAheadButton() {
    let result;
    if (view === views.MAKE_AHEAD) {
      result = (
        <button type="button" className="nav-link active" disabled={true}>
          <i className="fas fa-bowl-spoon" /> <span className="ms-1 d-none d-sm-inline">{capitalizeFirstLetter(views.MAKE_AHEAD)}</span>
        </button>
      );
    } else {
      result = (
        <button type="button" className="nav-link" onClick={() => history.push(`${location.pathname}?v=${views.MAKE_AHEAD}`)}>
          <i className="fas fa-bowl-spoon" /> <span className="ms-1 d-none d-sm-inline">{capitalizeFirstLetter(views.MAKE_AHEAD)}</span>
        </button>
      );
    }
    return result;
  }

  const ingredientButton =
    view === views.GROCERY_LIST ? (
      <button type="button" className="nav-link active" disabled={true}>
        <i className="fas fa-shopping-basket" />
        <span className="ms-1 d-none d-sm-inline">{capitalizeFirstLetter(views.GROCERY_LIST)}</span>
      </button>
    ) : (
      <button type="button" className="nav-link" onClick={() => history.push(`${location.pathname}?v=${views.GROCERY_LIST}`)}>
        <i className="fas fa-shopping-basket" /> <span className="ms-1 d-none d-sm-inline">{capitalizeFirstLetter(views.GROCERY_LIST)}</span>
      </button>
    );

  function groceryList() {
    return (
      <>
        <GroceryListCategory
          icon={<i className="fa-solid fa-leafy-green me-1 margin-print" />}
          name="Produce"
          ingredients={props.mmpStore.state.plan.ingredientData.views.produce}
          updateIngredient={props.mmpStore.updateIngredient}
        />
        <GroceryListCategory
          icon={<i className="fa-solid fa-sack me-1 margin-print" />}
          name="Bulk"
          ingredients={props.mmpStore.state.plan.ingredientData.views.bulk}
          updateIngredient={props.mmpStore.updateIngredient}
        />
        <GroceryListCategory
          icon={<i className="fa-solid fa-mortar-pestle me-1 margin-print" />}
          name="Spices and Herbs"
          ingredients={props.mmpStore.state.plan.ingredientData.views.spicesHerbs}
          updateIngredient={props.mmpStore.updateIngredient}
        />
        <GroceryListCategory
          icon={<i className="fa-regular fa-can-food me-1 margin-print" />}
          name="Packaged"
          ingredients={props.mmpStore.state.plan.ingredientData.views.packaged}
          updateIngredient={props.mmpStore.updateIngredient}
        />
        <GroceryListCategory
          icon={<i className="fa-solid fa-cart-circle-check me-1 margin-print" />}
          name="Items You Have"
          ingredients={props.mmpStore.state.plan.ingredientData.views.itemsYouHave}
          updateIngredient={props.mmpStore.updateIngredient}
          uncheckAll={props.mmpStore.deleteIngredients}
        />
      </>
    );
  }

  function sectionName() {
    let result = "this section";
    if (props.mmpStore.state.plan.sections.length > mealSlot.day - 1) {
      result = props.mmpStore.state.plan.sections[mealSlot.day - 1].name;
    }
    return result;
  }

  function print() {
    if (props.memberStore.member.hasTrial) {
      const element = document.getElementById("trial-feature-modal");
      const trialFeatureModal = Modal.getOrCreateInstance(element);
      trialFeatureModal.show();
    } else {
      window.print();
    }
  }

  function tagIds() {
    let result = [];
    if (isNotEmpty(mealSlot) && mealSlot.mealType !== mealTypes.EXTRA) {
      result = props.cache.tagIdsFromNames([mealSlot.mealType]);
    }
    return result;
  }

  function magicMealPlan() {
    let result;
    if (view === views.MEAL && isNotEmpty(meal)) {
      const recipe = props.mmpStore.state.plan.recipeData.views.find((r) => r.id === meal.recipeId);
      const recipes = props.mmpStore.state.plan.recipeData.views.filter((r) => r.id === meal.recipeId || recipe.childRecipeIds.indexOf(r.id) !== -1);
      const rawRecipes = props.mmpStore.state.plan.recipeData.recipes.filter((r) => r.id === meal.recipeId || recipe.childRecipeIds.indexOf(r.id) !== -1);
      result = (
        <Recipe
          token={props.token}
          authenticated={props.authenticated}
          member={props.memberStore.member}
          stashStore={props.stashStore}
          context={contexts.MAGIC_MEAL_PLANNER}
          recipeId={meal.recipeId}
          recipes={recipes}
          rawRecipes={rawRecipes}
          childRecipeIds={props.mmpStore.state.plan.childRecipeIds}
          deletedChildRecipes={props.mmpStore.state.customizations.deletedChildRecipes}
          reviewStore={props.reviewStore}
          noteStore={props.noteStore}
          modals={props.modals}
          canShowNutritionFacts={props.memberStore.member.preferences.showNutritionFacts}
          showRelatedContent={true}
          specialScalingIds={props.mmpStore.specialScalingIds}
          updatingCheckboxes={props.mmpStore.updatingCheckboxes}
          changeCookingMethod={props.mmpStore.changeCookingMethod}
          updateCheckboxes={props.mmpStore.updateCheckboxes}
          deleteChildRecipe={props.mmpStore.deleteChildRecipe}
          restoreChildRecipes={props.mmpStore.restoreChildRecipes}
          updateChildRecipeAdditionalPortions={props.mmpStore.updateChildRecipeAdditionalPortions}
          prepareDataForNutritionFacts={props.mmpStore.prepareDataForNutritionFacts}
          recordDownload={props.memberStore.recordDownload}
        />
      );
    } else if (view === views.MAKE_AHEAD) {
      result =
        props.mmpStore.state.plan.makeAheadRecipeIds.length > 0 ? (
          <div className="p-0 p-sm-3">
            <div className="row justify-content-between align-items-center p-2 mb-2 my-auto">
              <div className="col-auto">
                <h2 className="h-alt mt-2 text-magic nav-heading">
                  <i className="fas fa-bowl-spoon" /> Batching
                </h2>
              </div>

              <div className="col text-end">
                <button className="btn btn-white text-muted me-1" type="button" title="Collapse all" onClick={() => setCollapseAll(true)}>
                  <i className="fa-solid fa-chevrons-up" />
                </button>
                <button className="btn btn-white text-muted" type="button" title="Expand all" onClick={() => setExpandAll(true)}>
                  <i className="fa-solid fa-chevrons-down" />
                </button>
              </div>
            </div>
            <div className="accordion mb-3 magic-accordion">
              <AccordionItem
                id="preps"
                title={<span>Mise en Place (Prep)</span>}
                item={
                  <table className="table table-borderless">
                    <tbody>{preps}</tbody>
                  </table>
                }
              />
              {makeAheadRecipes()}
            </div>
          </div>
        ) : (
          <div className="p-0 p-sm-3">
            <div className="row justify-content-between align-items-center p-2 mb-2 my-auto">
              <div className="col-12 col-lg-4">
                <h2 className="h-alt mt-2 text-magic nav-heading">
                  <i className="fas fa-bowl-spoon" /> Batching
                </h2>
              </div>
              <h3>Hey there beautiful!</h3>
              <p>You haven't selected anything to make ahead. Please go to your Plan section and check the boxes for items you'd like to make ahead, and a Batching list will magically appear.</p>
              <img className="d-none d-sm-block" src={`${process.env.PUBLIC_URL}/images/batching-setup-directions.png`} alt="Batching Setup Directions" />
              <img className="d-block d-sm-none" src={`${process.env.PUBLIC_URL}/images/mobile-batching-setup-directions.png`} alt="Batching Setup Directions" />
            </div>
          </div>
        );
    } else if (view === views.PLAN) {
      result = <MagicMealPlanSections stashStore={props.stashStore} mmpStore={props.mmpStore} setMealSlot={setMealSlot} viewMeal={viewMeal} member={props.memberStore.member} print={print} />;
    } else if (view === views.GROCERY_LIST) {
      result = (
        <div id="grocery-list" className="p-0 p-sm-3">
          <div className="d-flex justify-content-between align-items-center p-2">
            <div>
              <h2 className="h-alt mt-2 mb-3 mb-sm-4 text-magic nav-heading">
                <i className="fas fa-shopping-basket" /> Groceries
              </h2>
            </div>
            <div className="d-print-none">
              <button className="btn btn-white text-muted me-1" type="button" title="Print" onClick={print}>
                <i className="fa-solid fas fa-print" />
              </button>
            </div>
          </div>
          <div className="col">
            <CustomGroceryItems store={props.gStore} />
            {groceryList()}
          </div>
        </div>
      );
    } else if (view === views.SEARCH) {
      const title = isNotEmpty(mealSlot) ? (
        <h3 className="h-alt mt-2 mb-3 mb-sm-4 text-magic nav-heading">
          {mealSlot.mealType === mealTypes.EXTRA ? (
            <>Find a Recipe for {sectionName()}</>
          ) : (
            <>
              Find a <span className="caps">{mealSlot.mealType}</span> for {sectionName()}
            </>
          )}
        </h3>
      ) : null;
      result = (
        <div className="p-2 p-sm-3">
          {title}
          <UniversalSearch
            token={props.token}
            authenticated={props.authenticated}
            cache={props.cache}
            memberStore={props.memberStore}
            stashStore={props.stashStore}
            modals={props.modals}
            banner={null}
            config={{
              path: `/meal-plans/meal-planner/${id}`,
              searchOrigin: searchOrigins.PLANNER,
              recommendedOnly: recommendedOnly,
              tagIds: tagIds(),
              setRecommendedOnly: setRecommendedOnly,
              add: add,
            }}
          />
        </div>
      );
    }
    return result;
  }

  function main() {
    if (props.mmpStore.state.showPublicPlan) {
      return (
        <div>
          <PublicMagicMealPlanSections stashStore={props.stashStore} mmpStore={props.mmpStore} member={props.memberStore.member} />
        </div>
      );
    } else {
      return <div>{magicMealPlan()}</div>;
    }
  }

  function copyUrl() {
    let result = null;
    if (navigator.clipboard) {
      if (props.mmpStore.state.plan.publiclyAvailable) {
        result = copied ? (
          <button className="btn btn-light btn-sm small text-muted px-1 mt-1" type="button" disabled={true}>
            <i className="fas fa-copy" /> Copied
          </button>
        ) : (
          <button
            className="btn btn-light btn-sm small text-muted px-1 mt-1"
            type="button"
            onClick={() => {
              navigator.clipboard.writeText(window.location.href).then(() => {
                setCopied(true);
                setTimeout(() => {
                  setCopied(false);
                }, 5000);
              });
            }}
          >
            <i className="far fa-copy" /> Copy Link
          </button>
        );
      }
    }
    return result;
  }

  const shareOrCopy = props.mmpStore.state.showPublicPlan ? (
    <CopyPublicPlan mmpStore={props.mmpStore} member={props.memberStore.member} />
  ) : (
    <div className="d-print-none pb-2">
      <Checkbox checked={props.mmpStore.state.plan.publiclyAvailable} i={0} toggle={props.mmpStore.togglePublicAvailability} />
      <span className="ms-1 me-2" style={{ fontSize: "14px" }}>
        Share
        <Popover
          iconClassName="fas fa-info-circle text-muted cursor-pointer"
          content="Share your Magic Meal Plan with other Clean Food Dirty Girl members by checking Share. On mobile devices, copy the URL of your Magic Meal Plan from the address bar. On desktop or laptops, click Copy Link. Once the link is copied, you can share it with other members. Only logged-in members will be able to see your Magic Meal Plan."
        />
      </span>
      {copyUrl()}
    </div>
  );

  const photo = props.mmpStore.state.showPublicPlan ? props.mmpStore.state.publicPlan.photo : props.mmpStore.state.plan.photo;
  const name = props.mmpStore.state.showPublicPlan ? props.mmpStore.state.publicPlan.name : props.mmpStore.state.plan.name;
  const author = props.mmpStore.state.showPublicPlan ? <span className="text-muted">Plan by {props.mmpStore.state.publicPlan.author}</span> : null;
  const description = props.mmpStore.state.showPublicPlan ? props.mmpStore.state.publicPlan.description : props.mmpStore.state.plan.description;

  function render() {
    return props.authenticated ? (
      <div className="container-fluid ps-md-0 pt-md-0">
        <div id="content" className="row p-0">
          <div id="left-column" className="sidenav-mmp shadow-sm col-12 col-md-3 col-lg-2 p-0 d-flex">
            <div className="d-flex bg-white flex-md-column flex-row flex-grow-1 align-items-center align-items-md-start px-0 pt-0 fixed-sidebar">
              <div className="d-none d-md-block d-lg-none text-center pt-md-1 pb-md-1 p-lg-0 mx-auto strip-margin-print strip-padding-print">
                <img src={assetUrl(photo)} alt={`Plant-based meal plan ${name}`} className="rounded-circle mt-4 poster" style={{ width: "120px", height: "120px" }} />
              </div>
              <div className="d-none d-lg-block text-center">
                <img src={assetUrl(photo)} alt={`Plant-based meal plan ${name}`} className="w-100 mb-1 d-print-none w-100 poster" />
              </div>
              <div className="mt-2 mb-1 px-2 d-none d-md-block">
                <h4 className="d-none d-md-block">{shortenedText(name, 35)}</h4>
                {author}
                {shareOrCopy}
              </div>

              {props.mmpStore.state.showPublicPlan ? null : (
                <div className="nav-sidebar nav-magic mt-md-2 mt-md-0">
                  <ul className="nav nav-pills flex-md-column flex-row flex-nowrap flex-shrink-1 flex-md-grow-0 flex-grow-1 mb-md-auto mb-0 end-0 mt-md-1 justify-content-center" id="menu">
                    <li className="nav-item">{planButton}</li>
                    {/* if we are viewing a meal, add a navigation element for that meal */}
                    {view === views.MEAL ? (
                      <li className="nav-item text-white text-end">
                        <div className="nav-link active bg-white">
                          <i className="fas fa-fork-knife" /> <span className="ms-1 d-none d-sm-inline">Recipe</span>
                        </div>
                      </li>
                    ) : null}
                    <li className="nav-item">{makeAheadButton()}</li>
                    <li className="nav-item">{ingredientButton}</li>
                  </ul>
                </div>
              )}

              <div className="d-none d-lg-block small description mt-md-3 px-md-2">
                <p>{shortenedText(description, 100)}</p>
              </div>
              {props.mmpStore.state.showPublicPlan ? null : (
                <div className="d-none d-md-block px-md-2 py-2 d-print-none">
                  <button className="btn btn-light small text-muted" type="button" onClick={showResetPlanModal}>
                    <i className="fa-solid fa-arrows-rotate" /> Reset Plan
                  </button>
                </div>
              )}
            </div>
          </div>

          <div className="main-content-mmp col d-flex flex-column col-12 col-md-9 col-lg-10">
            <main className="row">
              <div className="col fixed-content">
                {/* Special phone only section to show review stars and timing */}
                <div className="mobile-header-mmp d-block d-md-none bg-white shadow-sm d-print-none mx-2 mb-2 px-2">
                  <div className="row">
                    <div className="col-auto ps-2 py-2 pe-0">
                      <img src={assetUrl(photo)} alt={`Plant-based meal plan ${name}`} className="rounded-circle" style={{ width: "75px", height: "75px" }} />
                    </div>
                    <div className="col">
                      <div className="row">
                        <div className="col py-2 ps-2 pe-0">
                          <h2 className="xs-intro m-0">{showHeaderDetails ? name : shortenedText(name, 13)}</h2>
                        </div>
                        <div className="col-auto py-2 px-0">
                          {/* TODO: use bootstrap animation to ease in header details display */}
                          <button
                            className={showHeaderDetails ? "accordion-button ps-0 pe-3 pt-1 pb-0" : "accordion-button collapsed ps-0 pe-3 pt-1 pb-0"}
                            type="button"
                            onClick={() => setShowHeaderDetails(!showHeaderDetails)}
                          ></button>
                        </div>
                      </div>
                      {showHeaderDetails ? (
                        <div className="row">
                          <p className="small py-0 my-0">{description}</p>
                          {props.mmpStore.state.showPublicPlan ? null : (
                            <div className="py-2 d-print-none">
                              <button className="btn btn-light btn-sm small text-muted px-1" type="button" onClick={showResetPlanModal}>
                                <i className="fa-solid fa-arrows-rotate" /> Reset Plan
                              </button>
                            </div>
                          )}
                        </div>
                      ) : null}
                      <div className="row">{shareOrCopy}</div>
                    </div>
                  </div>
                </div>

                {/* Print Only */}
                <div className="d-none d-print-block p-0 m-0">
                  <table>
                    <tbody>
                      <tr>
                        <td>
                          <img src={assetUrl(photo)} alt={`Plant-based meal plan ${name}`} className="rounded-circle" style={{ width: "110px", height: "110px" }} />
                        </td>
                        <td>
                          <h2 className="d-block d-sm-none xs-intro">{name}</h2>
                          <p> {description}</p>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                {main()}
              </div>
              <div id="video-modal" className="modal" tabIndex={-1}>
                <div className="modal-dialog modal-dialog-centered modal-lg">
                  <div className="modal-content">
                    <div className="modal-header">
                      {prepVideo === null ? null : <h1 className="modal-title">{prepVideo.ingredientPrepVideoTitle}</h1>}
                      <button type="button" className="btn-close btn-close-black" data-bs-dismiss="modal" aria-label="Close" />
                    </div>
                    <div className="modal-body">
                      {prepVideo === null ? null : (
                        <div className="ratio ratio-16x9">
                          <VimeoVideo vimeoId={prepVideo.ingredientPrepVimeoId} title={prepVideo.ingredientPrepVideoTitle} />
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
              <div id="reset-modal" className="modal" tabIndex={-1}>
                <div className="modal-dialog modal-dialog-centered modal-lg">
                  <div className="modal-content">
                    <div className="modal-header">
                      <h1 className="modal-title h-alt">Reset Plan</h1>
                      <button type="button" className="btn-close btn-close-black" data-bs-dismiss="modal" aria-label="Close" />
                    </div>
                    <div className="modal-body">
                      <p>Resetting this plan will remove all checkmarks and restore the plan to default.</p>
                      <p>Would you like to proceed?</p>
                    </div>
                    <div className="modal-footer">
                      <button type="button" className="btn btn-outline-primary" data-bs-dismiss="modal">
                        No, cancel
                      </button>
                      <button type="button" className="btn btn-secondary me-auto" onClick={confirmDeleteCustomizations}>
                        Yes, reset
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              <Footer />
            </main>
          </div>
        </div>
        <MemberOnlyFeatureModal member={props.memberStore.member} />
      </div>
    ) : (
      <Redirect to="/sign-in" />
    );
  }

  return props.mmpStore.state.loading ? (
    <main className="text-center">
      <span className="spinner-border" />
    </main>
  ) : (
    render()
  );
}

MagicMealPlan.propTypes = {
  authenticated: PropTypes.bool,
  token: PropTypes.string,
  cache: PropTypes.object,
  stashStore: PropTypes.object,
  memberStore: PropTypes.object,
  mmpStore: PropTypes.object,
  gStore: PropTypes.object,
  reviewStore: PropTypes.object,
  noteStore: PropTypes.object,
  modals: PropTypes.object,
};
