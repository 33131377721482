export default function AgreeToChange({ checked, onChange, changeOrUpgrade, isDateChanging }) {
  return (
    <div className="px-3 mb-3">
      <div className="form-check">
        <input className="form-check-input cursor-pointer" type="checkbox" id="agreed" checked={checked} onChange={onChange} />
        <label className="form-check-label cursor-pointer" htmlFor="agreed">
          I have read the above and understand that my billing{isDateChanging ? " date and " : " "}amount will change with this membership {changeOrUpgrade}.
        </label>
      </div>
    </div>
  );
}
