import React from "react";

export default function Footer() {
  return (
    <footer className="mt-auto d-print-none">
      <div className="container-xl">
        <div className="d-flex flex-column flex-md-row align-items-center px-1 px-md-2 mt-4 footer">
          <div className="mr-md-auto logo">
            <a href="https://cleanfooddirtygirl.com/">
              <img src={`${process.env.PUBLIC_URL}/images/clean-food-dirty-girl-logo-long.png`} alt="Clean Food Dirty Girl" width="300"/>
            </a>
            <div className="footer-fine-print">
              <p>
                &copy; {new Date().getFullYear()} &nbsp;
                <a
                href="https://cleanfooddirtygirl.com/privacy-policy-terms-use-disclaimer/" target="_blank"
                rel="noreferrer">Terms & Privacy Policy</a>
              </p>
            </div>
          </div>
          <nav className="ms-md-auto footer-icons pt-1 pt-md-0">
            <a className="p-2" href="https://www.facebook.com/cfdgirl" target="_blank" rel="noreferrer">
              <i className="fab fa-facebook"/>
            </a>
            <a className="p-2" href="https://www.instagram.com/cleanfooddirtygirl/" target="_blank" rel="noreferrer">
              <i className="fab fa-instagram"/>
            </a>
            <a className="p-2" href="https://open.spotify.com/show/5ViLN4goOjFHyVJs5nMDnG" target="_blank" rel="noreferrer">
              <i className="fab fa-spotify"/>
            </a>
            <a className="p-2 strip-right" href="http://cleanfooddirtygirl.com/contact" target="_blank"
               rel="noreferrer">
              <i className="fas fa-circle-envelope"/>
            </a>
          </nav>
        </div>
        <div className="d-flex px-1 px-md-2 pb-1 pt-2">
            <span className="footer-fine-print">
             All content found on this website (Clean Food Dirty Girl), including text, images, audio, or other formats were created for informational purposes only.
              Nothing contained in this website is medical advice or diagnosis nor should any information in this website be construed as such.
              You assume full responsibility for how you choose to use this information. We suggest you seek the advice of a physician before beginning
               any weight loss effort or program. Never disregard professional medical advice or delay in seeking it because of something you have read on this website.
            </span>
        </div>
      </div>
    </footer>
  );
}