import React, {useEffect, useState} from "react";
import PropTypes from "prop-types";
import Constants from "../../../services/Constants";
import PublicMealPreview from "./PublicMealPreview";
import {Collapse} from "bootstrap";
import Helpers from "../../../services/Helpers";

export default function PublicMagicMealPlanSection(props) {
  const {mealTypes} = Constants();
  const {isNotEmpty} = Helpers();
  const [showSection, setShowSection] = useState(true);
  const sectionId = `section-${props.section.day}`;

  useEffect(() => {
    if (showSection) {
      const element = document.getElementById(sectionId);
      if (isNotEmpty(element)) {
        const collapse = new Collapse(element, {toggle: false});
        collapse.show();
      }
    }
  }, [props.section]);

  useEffect(() => {
    const element = document.getElementById(sectionId);
    if (isNotEmpty(element)) {
      const collapse = new Collapse(element, {toggle: false});
      if (showSection) {
        collapse.show();
      } else {
        collapse.hide();
      }
    }
  }, [showSection]);

  useEffect(() => {
    if (props.mmpStore.state.publicPlan.listView && !showSection) {
      setShowSection(true);
    }
  }, [props.mmpStore.state.publicPlan.listView]);

  function meals() {
    let result;
    if (props.mmpStore.state.publicPlan.listView) {
      result = props.section.meals.map(m => (
        <PublicMealPreview
          key={m.id}
          stashStore={props.stashStore}
          listView={props.mmpStore.state.publicPlan.listView}
          id={m.id}
          meal={m}
          mealType={m.mealType}
        />
      ));
    } else {
      const orderedMeals = [];
      props.section.meals.filter(m => m.mealType === mealTypes.BREAKFAST).map(m => orderedMeals.push(m));
      props.section.meals.filter(m => m.mealType === mealTypes.LUNCH).map(m => orderedMeals.push(m));
      props.section.meals.filter(m => m.mealType === mealTypes.DINNER).map(m => orderedMeals.push(m));
      props.section.meals.filter(m => m.mealType === mealTypes.EXTRA).map(m => orderedMeals.push(m));
      result = orderedMeals.map(m => (
        <PublicMealPreview
          key={m.id}
          stashStore={props.stashStore}
          listView={props.mmpStore.state.publicPlan.listView}
          id={m.id}
          meal={m}
          mealType={m.mealType}
        />
      ));
    }
    return result;
  }

  const main = (
    <div>
      {props.mmpStore.state.publicPlan.listView ? (
        <div className="list-group pb-0">
          {meals()}
        </div>
      ) : (
        <div className="row row-cols-1 row-cols-lg-2 row-cols-xxl-3">
          {meals()}
        </div>
      )}
    </div>
  );

  return (
    <div className={"accordion-item mb-3"}>
      <h2 className="accordion-header">
        <div className="row">
          <div className="col">
            <button
              className={showSection ? "accordion-button" : "accordion-button collapsed"}
              type="button"
              onClick={() => setShowSection(!showSection)}
            >
              {props.section.name}
            </button>
          </div>
        </div>
      </h2>

      <div id={sectionId} className="accordion-collapse collapse">
        <div className="accordion-body accordion-recipe px-2 py-3 p-md-3">
          {main}
        </div>
      </div>
    </div>
  );
}

PublicMagicMealPlanSection.propTypes = {
  stashStore: PropTypes.object,
  mmpStore: PropTypes.object,
  section: PropTypes.object,
}