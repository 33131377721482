import "./CookingJournal.scss";
import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import Footer from "../components/Footer";
import { Link, useHistory, useLocation } from "react-router-dom";
import Helpers from "../../services/Helpers";
import { DateTime } from "luxon";
import { Modal } from "bootstrap";

export default function CookingJournal(props) {
  const location = useLocation();
  let history = useHistory();
  const { pageTitle, isNotEmpty, makeUrlLookGood, assetUrl, splitHashtag, profilePhoto } = Helpers();
  const [startOfWeek, setStartOfWeek] = useState(DateTime.now().startOf("week"));
  const [deleteMode, setDeleteMode] = useState(false);
  const [recipeMadeToDeleteId, setRecipeMadeToDeleteId] = useState(null);
  const featureLaunch = DateTime.fromISO("2022-12-29").startOf("week");

  useEffect(() => {
    // set page title
    document.title = pageTitle(location.pathname);
    // get date from search params
    let searchParams = new URLSearchParams(location.search);
    const d = searchParams.get("d");
    const fallbackUrl = `${location.pathname}?d=${startOfWeek.toISODate()}`;
    if (isNotEmpty(d)) {
      if (d === "now") {
        setStartOfWeek(DateTime.now().startOf("week"));
      } else if (DateTime.fromISO(d).isValid) {
        if (d !== startOfWeek.toISODate()) {
          // if d isn't a start of week, replace the url, otherwise, update start of week in state
          if (d !== DateTime.fromISO(d).startOf("week").toISODate()) {
            history.replace(`${location.pathname}?d=${DateTime.fromISO(d).startOf("week").toISODate()}`);
          } else {
            setStartOfWeek(DateTime.fromISO(d).startOf("week"));
          }
        }
      } else {
        // if d param value doesn't mean anything, set it to current start of week
        history.replace(fallbackUrl);
      }
    } else {
      // if no date in url, set it to current start of week
      history.replace(fallbackUrl);
    }

  }, [location]);

  useEffect(() => {
    if (isNotEmpty(recipeMadeToDeleteId)) {
      const element = document.getElementById("delete-modal");
      const resetModal = Modal.getOrCreateInstance(element);
      resetModal.show();
    }
  }, [recipeMadeToDeleteId]);

  function previousWeek() {
    history.push(`${location.pathname}?d=${startOfWeek.minus({week: 1}).toISODate()}`);
  }
  
  function nextWeek() {
    history.push(`${location.pathname}?d=${startOfWeek.plus({week: 1}).toISODate()}`);
  }

  function currentWeek() {
    history.push(`${location.pathname}?d=${DateTime.now().startOf('week').toISODate()}`);
  }

  function toggleDeleteMode() {
    setDeleteMode(!deleteMode);
  }

  function recipeLink(r) {
    let result = "";
    if (isNotEmpty(r.mealPlanId)) {
      result = `/meal-plans/wizard-plans/${r.contextName.toLowerCase()}`;
    } 
    else if (isNotEmpty(r.weeklyMealPlanId)) {
      result = `/meal-plans/meal-planner/${makeUrlLookGood(r.weeklyMealPlanId, r.contextName)}`;
    }
    else if (isNotEmpty(r.standaloneRecipeId)) {
      result = `/recipes/recipe-vault/${makeUrlLookGood(r.standaloneRecipeId, r.contextName)}`;
    }
    else if (r.contextUnreachable) {
      result = `/recipes/recipe-vault/${makeUrlLookGood(r.recipeId, r.name)}`;
    }
    return result;
  }

  function recipeContext(r) {
    let result;
    if (isNotEmpty(r.mealPlanId)) {
      result = ` made in meal plan ${splitHashtag(r.contextName)}`;
    }
    else if (isNotEmpty(r.weeklyMealPlanId)) {
      result = ` made in Magic Meal Plan ${r.contextName}`;
    }
    else if (isNotEmpty(r.standaloneRecipeId) && r.standaloneRecipeId !== r.recipeId) {
      result = ` made in recipe ${r.contextName}`;
    }
    else if (r.contextUnreachable) {
      result = " made in a Magic Meal Plan"
    }
    else {
      result = " made as a standalone recipe"
    }
    return result;
  }

  function hideDeleteModal(event) {
    event.preventDefault();
    setRecipeMadeToDeleteId(null);
    const element = document.getElementById("delete-modal");
    const resetModal = Modal.getOrCreateInstance(element);
    resetModal.hide();   
  }

  function deleteRecipeMade() {
    props.deleteRecipeMade(recipeMadeToDeleteId, () => {
      const element = document.getElementById("delete-modal");
      const resetModal = Modal.getOrCreateInstance(element);
      resetModal.hide();
    });
  }

  function getRecipesByDate(date) {
    let result = [];
    const recipes = props.cookingJournal[date];
    if (isNotEmpty(recipes)) {
      result = recipes.map(r => (
        <tr className="journal-entry bg-light" key={r.id}>
          <td className="lh-sm border-bottom border-end fs-5 journal-entry-time align-top ps-2 pt-2 pe-1">{DateTime.fromISO(r.time).toLocaleString(DateTime.TIME_SIMPLE)}</td>
          <td className="lh-sm border-bottom border-end fs-5 ps-1 pt-2 pb-1 pe-2">
            <div className="row">
              <div className="col"><Link to={recipeLink(r)} title={recipeContext(r)}>{r.name}</Link></div>
              {deleteMode ? <div className={`col-auto cursor-pointer delete-recipe x-${makeUrlLookGood(r.recipeId, r.name)}`} onClick={() => setRecipeMadeToDeleteId(r.id)}>x</div> : null}
            </div>
          </td>
        </tr>
      ))
    }
    if (result.length < 3) {
      const emptyRowsCount = 3 - result.length;
      for (let i = 0; i < emptyRowsCount; i++) {
        result.push(
          <tr className="journal-entry bg-light" key={`empty-${date}-${i}`}>
            <td className="lh-sm border-bottom border-end fs-5 journal-entry-time align-top ps-2 pt-2 pe-1" style={emptyRowsCount === 3 ? {width: "90px"} : {}}/>
            <td className="lh-sm border-bottom border-end fs-5 ps-1 pt-2 pb-1 pe-2">&nbsp;</td>
          </tr>
        );
      }
    }
    return result;
  }

  function renderRecipes() {
    return (
      <>
        <div className="row mb-2 align-items-center">
          <div className="col text-end">
            <span className="fs-1 journal-title cursor-pointer" onClick={previousWeek} title="View previous week">&lt;</span>
          </div>
          <div className="journal-title col-auto fs-1 text-center">
              <span>{startOfWeek.toFormat('LLLL yyyy')}</span>
          </div>
          <div className="col">
            <span className="fs-1 journal-title cursor-pointer" onClick={nextWeek} title="View next week">&gt;</span>
          </div>
        </div>
        <div className="row">
          <div className="col-12 col-md-6">
            <table className="w-100">
              <tbody>
                <tr>
                  <td colSpan={2}>
                    <div className="journal-section fs-2 mt-3 mb-2">{startOfWeek.toFormat('EEEE d')}</div>
                  </td>
                </tr>
                {getRecipesByDate(startOfWeek.toISODate())}
                <tr>
                  <td colSpan={2}>
                    <div className="journal-section fs-2 mt-3 mb-2">{startOfWeek.plus({ days: 1 }).toFormat('EEEE d')}</div>
                  </td>
                </tr>
                {getRecipesByDate(startOfWeek.plus({ days: 1 }).toISODate())}
                <tr>
                  <td colSpan={2}>
                    <div className="journal-section fs-2 mt-3 mb-2">{startOfWeek.plus({ days: 2 }).toFormat('EEEE d')}</div>
                  </td>
                </tr>
                {getRecipesByDate(startOfWeek.plus({ days: 2 }).toISODate())}
              </tbody>
            </table>
          </div>
          <div className="col-12 col-md-6">
            <table className="w-100">
              <tbody>
                <tr>
                  <td colSpan={2}>
                    <div className="journal-section fs-2 mt-3 mb-2">{startOfWeek.plus({ days: 3 }).toFormat('EEEE d')}</div>
                  </td>
                </tr>
                {getRecipesByDate(startOfWeek.plus({ days: 3 }).toISODate())}
                <tr>
                  <td colSpan={2}>
                    <div className="journal-section fs-2 mt-3 mb-2">{startOfWeek.plus({ days: 4 }).toFormat('EEEE d')}</div>
                  </td>
                </tr>
                {getRecipesByDate(startOfWeek.plus({ days: 4 }).toISODate())}
                <tr>
                  <td colSpan={2}>
                    <div className="journal-section fs-2 mt-3 mb-2">{startOfWeek.plus({ days: 5 }).toFormat('EEEE d')}</div>
                  </td>
                </tr>
                {getRecipesByDate(startOfWeek.plus({ days: 5 }).toISODate())}
                <tr>
                  <td colSpan={2}>
                    <div className="journal-section fs-2 mt-3 mb-2">{startOfWeek.plus({ days: 6 }).toFormat('EEEE d')}</div>
                  </td>
                </tr>
                {getRecipesByDate(startOfWeek.plus({ days: 6 }).toISODate())}
              </tbody>
            </table>
          </div>
        </div>
        <div className="row mt-2">
          <div className="col-auto ms-auto">
            {deleteMode
              ? <i className="fa-regular fa-trash-can-slash text-muted cursor-pointer" onClick={toggleDeleteMode} title="Exit delete mode"></i>
              : <i className="fa-regular fa-trash-can text-muted cursor-pointer" onClick={toggleDeleteMode} title="Enter delete mode"></i>
            }
          </div>
        </div>
      </>
    );
  }

  function renderCollage() {
    let result = [];
    for (let weekDay = 0; weekDay < 7; weekDay++) {
      const recipes = props.cookingJournal[startOfWeek.plus({ days: weekDay }).toISODate()];
      if (isNotEmpty(recipes)) {
        result.push(recipes.map((r, i) => 
          isNotEmpty(r.photo)
            ? (<li className={`pic-${i}`} key={`li-${weekDay}-${i}`}><Link to={recipeLink(r)} title={r.name + recipeContext(r)}><img src={assetUrl(r.photo)} alt={r.name} /></Link></li>)
            : null
        ))
      }
    }
    return result;
  }

  function renderCoverPage() {
    const displayName = isNotEmpty(props.member.displayName) ? props.member.displayName : props.member.firstname;
    return (
      <>
        <div className="text-center journal-title">
          <div className="fs-2 mt-3">This journal belongs to</div>
          <div className="fs-1">{displayName}</div>
          <div className="profile-photo mt-4 mb-5"><img src={profilePhoto(props.member)} alt={`Profile photo of ${displayName}`} /></div>
        </div>
      </>
    );
  }

  function renderBody() {
    let result = null;
    // set the start of journal date for the current user
    let startOfJournal = featureLaunch; // date feature launched
    if (isNotEmpty(props.member) && isNotEmpty(props.member.startedMembership) && startOfJournal < DateTime.fromSeconds(props.member.startedMembership)) {
      startOfJournal = DateTime.fromSeconds(props.member.startedMembership).startOf("week"); // first start of a membership
    }
    if (isNotEmpty(props.member) && isNotEmpty(props.member.startedPflTrial) && startOfJournal < DateTime.fromSeconds(props.member.startedPflTrial)) {
      startOfJournal = DateTime.fromSeconds(props.member.startedPflTrial).startOf("week"); // start of pfl trial
    }
    if (startOfWeek >= startOfJournal) {
      // show journal if selected date allows it
      result = (
        <>
          {renderRecipes()}
          <ul className="collage mt-3">
            {renderCollage()}
          </ul>
        </>
      );
    } else {
      // show cover page if selected date doesn't allow showing journal
      result = renderCoverPage();
    }
    return result;
  }

  function renderBookmark() {
    let result = null;
    if (!startOfWeek.equals(DateTime.now().startOf("week"))) {
        // TODO: replace with an image for a similar look but better result
      result = (
        <>
          <span id="current-week-bookmark-top" className="fa-solid fa-bookmark cursor-pointer text-secondary" onClick={currentWeek} title="Click to view current week"></span>
          <span id="current-week-bookmark-bottom" className="fa-solid fa-bookmark cursor-pointer text-secondary" onClick={currentWeek} title="Click to view current week"></span>
        </>
      );
    }
    return result;
  }

  return (
    <>
      <main id="cooking-journal" className="container-lg px-0 px-sm-2">
        {props.banner}
        <h1 className="text-center page-title mb-3 mx-2 mx-sm-0">{props.title}</h1>
        <div id="journal-body" className="bg-white p-3">
          {renderBookmark()}
          {renderBody()}
        </div>

        <div id="delete-modal" data-bs-backdrop="static" data-bs-keyboard="false" className="modal" tabIndex={-1}>
          <div className="modal-dialog modal-dialog-centered modal-lg">
            <div className="modal-content">
              <div className="modal-header">
                <h1 className="modal-title h-alt">Delete from Cooking Journal</h1>
                <button type="button" className="btn-close btn-close-black" aria-label="Close" onClick={hideDeleteModal} />
              </div>
              <div className="modal-body">
                <p>Deleting this recipe will remove it from your journal. It will be like it never happened.</p>
                <p>Would you like to proceed?</p>
              </div>
              <div className="modal-footer">
                <button type="button" className="btn btn-outline-primary" onClick={hideDeleteModal}>No, cancel</button>
                <button type="button" className="btn btn-secondary me-auto" onClick={deleteRecipeMade}>Yes, delete</button>
              </div>
            </div>
          </div>
        </div>

      </main>
      <Footer />
    </>
  );
}

CookingJournal.propTypes = {
  authenticated: PropTypes.bool,
  cookingJournal: PropTypes.object,
  deleteRecipeMade: PropTypes.func,
  member: PropTypes.object,
  title: PropTypes.string,
  banner: PropTypes.element,
}