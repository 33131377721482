import {useState} from "react";
import axios from "axios";
import Helpers from "../Helpers";
import Constants from "../Constants";

export default function useAddToPlan(cache, token, member, mmpStore) {
  const {documentTypes} = Constants();
  const {handleError, isNotEmpty} = Helpers();
  const [plan, setPlan] = useState(emptyPlan)
  const [document, setDocument] = useState(emptyDocument());
  const [toast, setToast] = useState(emptyToast());

  function emptyPlan() {
    return {
      id: null,
      name: "",
      listView: false,
      sections: [],
    }
  }

  function emptyDocument() {
    return {
      documentId: 0,
      documentType: "",
      name: "",
    };
  }

  function resetDocument() {
    setDocument(emptyDocument());
  }

  function emptyToast() {
    return {
      id: 0,
      name: "",
      show: false,
    }
  }

  function resetToast() {
    toast.show = false;
    setToast(toast);
  }

  function getPlan(id) {
    axios({
      method: "get",
      headers: {
        "Content-Type": "application/json",
        "Authorization": `Bearer ${token}`
      },
      url: `${process.env.REACT_APP_API}/meal-planner/modal-plan/${id}`,
    }).then(res => {
      setPlan(res.data);
    }).catch(err => {
      handleError(err);
    });
  }

  function addDocumentMeals() {
    axios({
      method: "get",
      headers: {
        "Content-Type": "application/json",
        "Authorization": `Bearer ${token}`
      },
      url: `${process.env.REACT_APP_API}/wizard-plans/${document.documentId}/meals`
    }).then(res => {
      const dd = document;
      dd["wizardPlanMeals"] = res.data;
      setDocument(dd);
    });
  }

  function createSection(planId, data, cb) {
    axios({
      method: "post",
      headers: {
        "Content-Type": "application/json",
        "Authorization": `Bearer ${token}`
      },
      url: `${process.env.REACT_APP_API}/meal-planner/${planId}/sections`,
      data: data
    }).then(() => {
      if (cb) cb();
    }).catch(() => {
      // do nothing
      if (cb) cb();
    });
  }

  function add(data, cb) {
    if (isNotEmpty(document.documentId)) {
      const plan = mmpStore.state.plans.find(p => p.id === +data.planId);
      if (document.documentType === documentTypes.WIZARD_PLAN) {
        const mealPlan = {id: document.documentId};
        axios({
          method: "post",
          headers: {
            "Content-Type": "application/json",
            "Authorization": `Bearer ${token}`
          },
          url: `${process.env.REACT_APP_API}/meal-planner/${data.planId}/wizard-plan-meals`,
          data: mealPlan
        }).then(() => {
          if (isNotEmpty(mmpStore.state.plan.id)) {
            mmpStore.getAllData(mmpStore.state.plan.id);
          }
          if (isNotEmpty(plan)) {
            setToast({id: plan.id, name: plan.name, documentType: document.documentType, show: true})
          }
          if (cb) cb();
        });
      } else {
        const meal = {
          recipeId: document.documentId,
          day: data.day,
          mealType: data.mealType,
          portions: null,
        }
        const noSpecialScaling = cache.specialScalingIds.indexOf(document.documentId) === -1;
        if (noSpecialScaling) {
          meal.portions = isNotEmpty(member.preferences.portions) ? member.preferences.portions : 4;
        }
        axios({
          method: "post",
          headers: {
            "Content-Type": "application/json",
            "Authorization": `Bearer ${token}`
          },
          url: `${process.env.REACT_APP_API}/meal-planner/${data.planId}/meals`,
          data: meal
        }).then(() => {
          if (isNotEmpty(mmpStore.state.plan.id)) {
            mmpStore.getAllData(mmpStore.state.plan.id);
          }
          if (isNotEmpty(plan)) {
            setToast({id: plan.id, name: plan.name, documentType: document.documentType, show: true})
          }
          if (cb) cb();
        });
      }
    }
  }

  const addToPlanStore = {
    document: document,
    plan: plan,
    toast: toast,
    add: add,
    addDocumentMeals: addDocumentMeals,
    createSection: createSection,
    getPlan: getPlan,
    resetDocument: resetDocument,
    resetToast: resetToast,
    setDocument: setDocument,
  }

  return {addToPlanStore};
}