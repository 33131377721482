import {useEffect, useState} from "react";
import axios from "axios";
import Helpers from "../Helpers";

export default function useNote(token, member) {
  const {isNotEmpty} = Helpers();
  const [memberNotes, setMemberNotes] = useState([]);

  useEffect(() => {
    if (isNotEmpty(token) && (member.hasMP || member.hasRV)) {
      get();
    }
  }, [token, member]);

  function get() {
    if (isNotEmpty(token)) {
      axios({
        method: "get",
        headers: {
          "Content-Type": "application/json",
          "Authorization": `Bearer ${token}`
        },
        url: `${process.env.REACT_APP_API}/notes`,
      }).then(res => {
        setMemberNotes(res.data)
      }).catch(() => {
        // we do nothing
      });
    }
  }

  function create(data, cb) {
    if (isNotEmpty(token)) {
      axios({
        method: "post",
        headers: {
          "Content-Type": "application/json",
          "Authorization": `Bearer ${token}`
        },
        url: `${process.env.REACT_APP_API}/notes`,
        data: data,
      }).then(res => {
        setMemberNotes(res.data)
        if (cb) cb();
      }).catch(() => {
        if (cb) cb();
      });
    } else {
      if (cb) cb();
    }
  }

  function update(data, cb) {
    if (isNotEmpty(token)) {
      axios({
        method: "put",
        headers: {
          "Content-Type": "application/json",
          "Authorization": `Bearer ${token}`
        },
        url: `${process.env.REACT_APP_API}/notes/${data.id}`,
        data: data,
      }).then(res => {
        setMemberNotes(res.data)
        if (cb) cb();
      }).catch(() => {
        if (cb) cb();
      });
    } else {
      if (cb) cb();
    }
  }

  function destroy(id) {
    if (isNotEmpty(token)) {
      axios({
        method: "delete",
        headers: {
          "Content-Type": "application/json",
          "Authorization": `Bearer ${token}`
        },
        url: `${process.env.REACT_APP_API}/notes/${id}`,
      }).then(res => {
        setMemberNotes(res.data)
      }).catch(() => {
        // we do nothing
      });
    }
  }

  const noteStore = {
    memberNotes: memberNotes,
    create: create,
    update: update,
    destroy: destroy,
  }

  return {noteStore};
}