import "./SignUp.css";
import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { Link, Redirect, useLocation } from "react-router-dom";
import Helpers from "../../services/Helpers";
import MemberForm from "../components/MemberForm";

export default function Trial(props) {
  const screens = {
    ACCOUNT_CREATION: "accountCreation",
    WORKING: "working",
    REDIRECT: "redirect",
  };
  const location = useLocation();
  const { pageTitle } = Helpers();
  const [screen, setScreen] = useState(screens.WORKING);
  const [redirectUrl, setRedirectUrl] = useState("/");

  useEffect(() => {
    document.title = pageTitle(location.pathname);
    let searchParams = new URLSearchParams(location.search);
    props.setUtmParams({
      origin: location.pathname,
      campaign: searchParams.get("utm_campaign"),
      source: searchParams.get("utm_source"),
      medium: searchParams.get("utm_medium"),
      content: searchParams.get("utm_content"),
      term: searchParams.get("utm_term"),
    });
  }, [location]);

  useEffect(() => {
    // When everything is ready, we can set the screen
    if (!props.memberStore.waitingForMember) {
      if (props.authenticated) {
        // Redirect this signed-in user
        setScreen(screens.REDIRECT);
      } else {
        setScreen(screens.ACCOUNT_CREATION);
        window.scrollTo(0, 0);
      }
    }
  }, [props.memberStore.waitingForMember, props.authenticated]);

  function setTrialThankYouAsNextPage() {
    setRedirectUrl("/thank-you?p=pfl_trial");
  }

  function main() {
    const header = (
      <div className="row mb-3 align-items-center">
        <div className="col text-center">
          <Link to="/">
          <img
            src={`${process.env.PUBLIC_URL}/images/CFDGMealPlanClub.png`}
            width="200" alt="Clean Food Dirty Girl Meal Plan Club"
          />
          </Link>
        </div>
      </div>
    );

    let content;

    if (props.memberStore.member.hasMP || screen === screens.REDIRECT) {
      content = <Redirect to={redirectUrl} />;
    } else if (screen === screens.ACCOUNT_CREATION) {
      content = (
        <>
          <h2 className="mb-4 text-center">Transform the way you cook plants in just 10 days</h2>
        
          <MemberForm
            startTrial={props.startTrial}
            resetMessage={props.resetMessage}
            message={props.message}
            setTrialThankYouAsNextPage={setTrialThankYouAsNextPage}
          />
            <p className="mb-3 mt-2 text-center">
            Or,{" "}
            <a className="black-link" href="https://members.cleanfooddirtygirl.com/sign-up?utm_campaign=join&utm_source=portal&utm_medium=link&utm_content=trial+page&utm_term=skip+the+trial">
              skip the trial and join now
            </a>
            .
          </p>

          <p className="mt-2 footer-text text-center">
            Already a member?{" "}
            <a className="black-link" href="https://members.cleanfooddirtygirl.com/sign-in/">
              Sign in here
            </a>
            . By creating an account, I agree to the{" "}
            <a id="terms-privacy-link" className="black-link" href="https://cleanfooddirtygirl.com/privacy-policy-terms-use-disclaimer/" target="_blank">
              Terms and Privacy Policy
            </a>
            .
            </p>
        </>
      );
    } else {
      content = (
        <div className="text-center">
          <span className="spinner-border" />
        </div>
      );
    }

    return (
      <main id="sign-up" className="container-lg mt-0 my-sm-auto p-3 p-sm-4">
        {header}
        {content}
      </main>
    );
  }

  return main();
}

Trial.propTypes = {
  authenticated: PropTypes.bool,
  message: PropTypes.string,
  memberStore: PropTypes.object,
  startTrial: PropTypes.func,
  resetMessage: PropTypes.func,
  setUtmParams: PropTypes.func,
};
