import React, {useEffect, useState} from "react";
import {useLocation, useHistory} from "react-router-dom";
import PropTypes from "prop-types";
import {Toast} from "bootstrap";
import Helpers from "../../services/Helpers";
import Constants from "../../services/Constants";

export default function GoToPlanToast(props) {
  let history = useHistory();
  const location = useLocation();
  const {documentTypes} = Constants();
  const {makeUrlLookGood} = Helpers();
  const [zIndex, setZIndex] = useState(0);

  useEffect(() => {
    const element = document.getElementById("go-to-plan-toast");
    element.addEventListener("hidden.bs.toast", () => {
      setZIndex(0);
      props.store.resetToast();
    });
  }, []);

  useEffect(() => {
    if (zIndex > 0) {
      const element = document.getElementById("go-to-plan-toast");
      const toast = Toast.getOrCreateInstance(element);
      toast.show();
    }
  }, [zIndex]);

  useEffect(() => {
    if (props.store.toast.show && location.pathname.indexOf("/meal-planner") === -1) {
      setZIndex(1100);
    } else if (!props.store.toast.show) {
        setZIndex(0);
    }
  }, [props.store.toast.show]);

  const planUrl = `/meal-plans/meal-planner/${makeUrlLookGood(props.store.toast.id, props.store.toast.name)}`;

  return (
    <div className="position-fixed top-0 start-50 translate-middle-x p-3" style={{ zIndex: zIndex }}> {/* zindex to make it appear above the Help ? button which is at 999 */}
      <div id="go-to-plan-toast" className="toast" role="alert" aria-live="assertive" aria-atomic="true">
        <div className="toast-header">
          <strong className="me-auto">{props.store.toast.documentType === documentTypes.WIZARD_PLAN ? "Meal plan recipes added!" : "Recipe  added!"}</strong>
          <button type="button" className="btn-close btn-close-black" data-bs-dismiss="toast" aria-label="Close"></button>
        </div>
        <div className="toast-body">
          <button className="btn btn-link text-white px-0 mx-0" type="button" onClick={() => {
            props.store.resetToast();
            history.push(planUrl);
          }}>
            <span className="d-none d-sm-inline">Click</span><span className="d-inline d-sm-none">Tap</span> here to go to <span className="fw-bold">{props.store.toast.name}</span>
          </button>
        </div>
      </div>
    </div>
  );
}

GoToPlanToast.propTypes = {
  store: PropTypes.object,
}