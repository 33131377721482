import React, { useState } from "react";
import { Link } from "react-router-dom";
import Helpers from "../../services/Helpers";
import FullScreenImage from "../Recipe/components/FullScreenImage";

export default function Review({ review, memberReview, noPhotoLayout, edit, destroy }) {
  const { assetUrl, isEmpty } = Helpers();
  const [lightBoxOpened, setLightBoxOpened] = useState(false);
  function stars() {
    const stars = [];
    for (let i = 1; i < 6; i++) {
      const className = review.stars >= i ? "fa fa-star" : "far fa-star";
      stars.push(<i key={i} className={className} />);
    }
    return stars;
  }

  function citeText() {
    let result;
    if (memberReview) {
      result = review.approved ? (
        "Your review"
      ) : (
        <>
          This review will be published pending approval. We approve all reviews (positive or negative) that comply with our{" "}
          <Link
            to={{ pathname: "https://help.cleanfooddirtygirl.com/article/121-plant-fueled-life-community-guidelines" }}
            target="_blank"
            style={{ color: "#6c757d", "text-decoration": "underline" }}
          >
            community guidelines
          </Link>
          .
        </>
      );
    } else {
      result = isEmpty(review.member) ? "Member" : review.member;
    }
    return result;
  }

  return (
    <figure className="mb-4">
      {isEmpty(review.photo) ? null : (
        <FullScreenImage opened={lightBoxOpened} image={review.photo} caption={""} onCloseRequest={() => setLightBoxOpened(false)} />
      )}
      <blockquote className="blockquote">
        <div className="row review-stars your-rating">
          {noPhotoLayout ? null : (
            <div className="col-3 col-lg-2 pe-0">
              {review.photo && (
                <img
                  src={assetUrl(review.photo)}
                  className="cursor-pointer"
                  style={{ objectFit: "cover", width: "100%", aspectRatio: "1/1" }}
                  alt="Review"
                  onClick={() => {
                    setLightBoxOpened(true);
                  }}
                />
              )}
            </div>
          )}
          <div className={noPhotoLayout ? "col-12" : "col-9 col-lg-10"}>
            {stars()}
            <p className="mb-1">{review.text}</p>
            <figcaption className={memberReview ? "blockquote-footer" : "blockquote-footer text-capitalize"}>
              <cite>{citeText()}</cite>
            </figcaption>
            {memberReview ? (
                <div>
                  <button className="btn btn-link text-secondary ps-0" type="button" onClick={edit}>
                    <i className="fas fa-edit" /> Edit
                  </button>
                  <button className="btn btn-link text-danger" type="button" onClick={() => destroy(review.id)}>
                    <i className="far fa-minus-circle" /> Delete
                  </button>
                </div>
            ) : null}
            {review.reply && (
              <div className="ms-4">
                <p className="mb-1">{review.reply}</p>
                <figcaption className="blockquote-footer text-capitalize">
                  <cite>team dirty</cite>
                </figcaption>
              </div>
            )}
          </div>
        </div>
      </blockquote>

    </figure>
  );
}
