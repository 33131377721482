import React from "react";
import HomepageVisibilityToggle from "./HomepageVisibilityToggle";

export default function HomepageBlank({ htmlContent, visible, editMode, toggleVisibility }) {
  return visible || editMode ? (
    <section>
      {editMode ? (
        <h5><HomepageVisibilityToggle visible={visible} toggle={toggleVisibility} /></h5>
      ) : null}
      <div dangerouslySetInnerHTML={{ __html: htmlContent }} style={{ opacity: editMode && !visible ? 0.25 : 1 }} />
    </section>
  ) : null;
}