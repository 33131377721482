import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import StandaloneCreditCardForm from "../Auth/components/StandaloneCreditCardForm";
import Helpers from "../../services/Helpers";
import AddCardButton from "./AddCardButton";
import ReviewOrderButton from "./ReviewOrderButton";
import CreditCards from "./CreditCards";

export default function BillingInformation(props) {
  const { isNotEmpty } = Helpers();
  const [showForm, setShowForm] = useState(false);

  useEffect(() => {
    if (!props.subStore.waitingForCreditCards) {
      if (props.subStore.creditCards.length === 0) {
        setShowForm(true);
      } else {
        setShowForm(false);
      }
    }
  }, []);

  useEffect(() => {
    if (!props.subStore.waitingForCreditCards) {
      if (props.subStore.creditCards.length === 0) {
        setShowForm(true);
      } else {
        setShowForm(false);
      }
    }
  }, [props.subStore.creditCards, props.subStore.waitingForCreditCards]);

  useEffect(() => {
    if (isNotEmpty(props.setCardReady)) {
      props.setCardReady(!showForm);
    }
  }, [showForm]);

  if (props.subStore.waitingForCreditCards) {
    return (
      <div className="text-center">
        <span className="spinner-border" />
      </div>
    );
  }

  if (showForm) {
    return (
      <StandaloneCreditCardForm
        member={props.member}
        totalCards={props.subStore.creditCards.length}
        addCreditCard={props.subStore.addCreditCard}
        cancel={() => setShowForm(false)}
        dismiss={props.signUpContext ? props.dismiss : () => setShowForm(false)}
        signUpContext={props.signUpContext}
        useLargeButtons={props.useLargeButtons}
      />
    );
  }

  return (
    <>
      <div className="container-fluid p-0">
        <div className="row">
          <div className="col-12 mb-3">
            <AddCardButton disabled={props.subStore.changingDefaultCard} onClick={() => setShowForm(true)} />
          </div>
          <CreditCards
            creditCards={props.subStore.creditCards}
            changingDefaultCard={props.subStore.changingDefaultCard}
            setDefaultCreditCard={props.subStore.setDefaultCreditCard}
          />
        </div>
      </div>
      {props.signUpContext && (
        <div className="d-grid gap-2 col-12 col-sm-6 mx-auto">
          <ReviewOrderButton disabled={props.subStore.changingDefaultCard} onClick={props.dismiss} />
        </div>
      )}
    </>
  );
}

BillingInformation.propTypes = {
  member: PropTypes.object,
  subStore: PropTypes.object,
  dismiss: PropTypes.func,
  signUpContext: PropTypes.bool,
  setCardReady: PropTypes.func,
  useLargeButtons: PropTypes.bool,
};
