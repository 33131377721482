export default function SubscriptionButtons({ memberStore, subStore, showModal, showUpdateButtons, setShowUpdateButtons }) {
  const {
    member: { hasMP, hasRV, hadMP, hasTrial, hadTrial },
  } = memberStore;
  const { subscription } = subStore;

  const isGiftee = subscription.at(-1)?.internalName === "MP_GIFT";
  
  if (hadMP || subscription?.at(-1)?.canceled) {
    //member with all subscriptions expired or, canceled and no future subscription
    return (
      <>
        <div className="col-12 mt-4 d-md-flex justify-content-md-center">
          {!hadTrial && (
            <div className="d-grid gap-2 col-12 col-sm-6 mx-auto">
              <button type="button" className="btn btn-secondary me-sm-2 mb-2" onClick={() => showModal("start-trial-modal")}>
                Start free trial
              </button>
            </div>
          )}
          <div className={`d-grid gap-2 col-12 ${!hadTrial ? "col-sm-6 " : ""}mx-auto`}>
            <button type="button" className="btn btn-primary mb-2" onClick={() => showModal("reactivate-subscription-modal")}>
              Reactivate membership
            </button>
          </div>
        </div>
        <div className="col-12 mt-1 d-md-flex" style={{ fontSize: ".85rem" }}>
          Click on “Reactivate membership” and a popup will open with membership options to choose from.
        </div>
      </>
    );
  }
  //anything below !hadMP and has not canceled <==> has something active, or is expired trial.

  if (hasRV || (hasMP && !hasTrial && !isGiftee)) {
    //this is an active paying member
    return (
      <div className="col-12 mt-4 d-md-flex justify-content-md-center">
        {showUpdateButtons ? (
          <>
            <div className="d-grid gap-2 col-12 col-sm-6 mx-auto">
              <button type="button" className="btn btn-primary me-md-2 mb-2" onClick={() => showModal("change-subscription-modal")}>
                Change plan
              </button>
            </div>
            <div className="d-grid gap-2 col-12 col-sm-6 mx-auto">
              <button type="button" className="btn btn-outline-primary mb-2" onClick={() => showModal("cancel-subscription-modal")}>
                Cancel membership
              </button>
            </div>
          </>
        ) : (
          <div className="d-grid gap-2 col-12 mx-auto">
            <button type="button" className="btn btn-white mb-2" onClick={() => setShowUpdateButtons(true)}>
              Manage membership
            </button>
          </div>
        )}
      </div>
    );
  }

  //if here, trial or gift is active or, trial has expired
  return (
    <>
      <div className="col-12 mt-4 d-md-flex justify-content-md-center">
        {!hasTrial && !hadTrial && (
          <div className="d-grid gap-2 col-12 col-sm-6 mx-auto">
            <button type="button" className="btn btn-secondary me-sm-2 mb-2" onClick={() => showModal("start-trial-modal")}>
              Start free trial
            </button>
          </div>
        )}
        <div className={`d-grid gap-2 col-12 ${!hasTrial && !hadTrial ? "col-sm-6 " : ""}mx-auto`}>
          <button type="button" className="btn btn-primary mb-2" onClick={() => showModal("add-subscription-modal")}>
            Become a member
          </button>
        </div>
      </div>
      <div className="text-center">
        <a className="text-secondary me-sm-2 mb-2" href="https://cleanfooddirtygirl.com/plant-fueled-life" target="_blank" rel="noreferrer">
          Learn more
        </a>
      </div>
    </>
  );
}
