import React from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";

export default function Note(props) {
  function citeText() {
    let result;
    if (props.memberNote) {
      if (props.note.publiclyAvailable) {
        result = props.note.approved ? (
          "Your public note"
        ) : (
          <>
            Your public note will be published pending approval. We approve all notes that comply with our{" "}
            <Link
              to={{ pathname: "https://help.cleanfooddirtygirl.com/article/121-plant-fueled-life-community-guidelines" }}
              target="_blank"
              style={{ color: "#6c757d", "text-decoration": "underline" }}
            >
              community guidelines
            </Link>
            .
          </>
        );
      } else {
        result = "Your private note";
      }
    } else {
      result = props.note.member;
    }
    return result;
  }

  return (
    <figure className="pt-3">
      <blockquote className="blockquote">
        <p style={!props.memberNote ? { fontSize: "14px" } : {}}>{props.note.text}</p>
      </blockquote>
      <figcaption className="blockquote-footer">
        <cite style={!props.memberNote ? { fontSize: "14px" } : {}}>{citeText()}</cite>
      </figcaption>
      {props.memberNote ? (
        <div>
          <button className="btn btn-link btn-sm text-info ps-0" type="button" onClick={props.edit}>
            <i className="fas fa-edit" /> Edit
          </button>
          <button className="btn btn-link btn-sm text-danger ps-0" type="button" onClick={() => props.destroy(props.note.id)}>
            <i className="fas fa-trash-alt" /> Delete
          </button>
        </div>
      ) : null}
    </figure>
  );
}

Note.propTypes = {
  note: PropTypes.object,
  memberNote: PropTypes.bool,
  edit: PropTypes.func,
  destroy: PropTypes.func,
};
