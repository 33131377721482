import React from "react";
import PropTypes from "prop-types";
import Constants from "../../services/Constants";

export default function PortionSelector(props) {
  const { MAX_SERVINGS: maxServings } = Constants();
  function options() {
    let result = [];
    if (props.specialScaling) {
      // half, standard, double
      result.push(
        <option disabled key="size" value={null}>
          Size
        </option>
      );
      if (props.allowZero) {
        result.push(
          <option key="zero" value={0}>
            None
          </option>
        );
      }
      result.push(
        <option key="half" value={props.defaultPortions / 2}>
          Half
        </option>
      );
      result.push(
        <option key="standard" value={props.defaultPortions}>
          Standard
        </option>
      );
      result.push(
        <option key="double" value={props.defaultPortions * 2}>
          Double
        </option>
      );
    } else {
      result.push(
        <option disabled key="servings" value={null}>
          Servings
        </option>
      );
      if (props.allowZero) {
        result.push(
          <option key="zero" value={0}>
            0
          </option>
        );
      }
      for (let i = 1; i <= maxServings; i++) {
        result.push(
          <option key={i} value={i}>
            {i}
          </option>
        );
      }
    }
    return result;
  }

  return (
    <select
      className="form-select text-muted w-auto ps-2 small"
      id="portions-selector"
      value={props.portions}
      onClick={(e) => e.stopPropagation()}
      onChange={(e) => props.change(Number(e.target.value))}
    >
      {options()}
    </select>
  );
}

PortionSelector.propTypes = {
  allowZero: PropTypes.bool,
  specialScaling: PropTypes.bool,
  defaultPortions: PropTypes.number,
  portions: PropTypes.number,
  change: PropTypes.func,
};
