import React, {useEffect, useState} from "react";
import PropTypes from "prop-types";
import {Collapse} from "bootstrap";

export default function AccordionItem(props) {
  const [show, setShow] = useState(false);

  useEffect(() => {
    const element = document.getElementById(props.id);
    const collapse = new Collapse(element, {toggle: false});
    show ? collapse.show() : collapse.hide();
  }, [show, props.id]);

  return (
    <div className="accordion-item mb-3">
      <h2 className="accordion-header">
        <button
          className={show ? "accordion-button" : "accordion-button collapsed"}
          type="button"
          onClick={() => setShow(!show)}
        >
          <h3 className="ms-2 mt-1">{props.title}</h3>
        </button>
      </h2>
      <div id={props.id} className="accordion-collapse collapse">
        <div className="accordion-body">
          {props.item}
        </div>
      </div>
    </div>
  )
}

AccordionItem.propTypes = {
  id: PropTypes.string,
  title: PropTypes.element,
  item: PropTypes.element,
}