import "../MealPreview.css";
import React from "react";
import PropTypes from "prop-types";
import Helpers from "../../../services/Helpers";
import MakeAheadToggle from "./MakeAheadToggle";
import {useHistory} from "react-router-dom";
import PortionSelector from "../../components/PortionSelector";
import Constants from "../../../services/Constants";
import StashButton from "../../Search/components/StashButton";

export default function MealPreview(props) {
  let history = useHistory();
  const {documentTypes, mealTypes} = Constants();
  const {assetUrl, capitalizeFirstLetter, isNotEmpty, makeUrlLookGood} = Helpers();

  function viewMealInRecipeVault() {
    props.changePortionsInRecipeVault(props.meal, () => {
      history.push(`/recipes/recipe-vault/${makeUrlLookGood(props.meal.recipeId, props.meal.name)}`);
    });
  }

  function changePortions(portions) {
    props.changePortions(props.meal, portions);
  }

  const makeAheadToggles = props.meal.makeAheadToggles.map(t => (
    <MakeAheadToggle
      key={`${props.id}-${t.recipeId}`}
      listView={props.listView}
      toggled={t.toggled}
      content={<small className="text-muted">{t.name}</small>}
      toggle={() => props.toggleMakeAhead(t.recipeId)}
    />
  ));

  const portionSelector = (
    <div className="d-print-none">
      <PortionSelector
        allowZero={false}
        specialScaling={props.specialScalingIds.indexOf(props.meal.recipeId) !== -1}
        defaultPortions={props.meal.defaultPortions}
        portions={props.meal.portions}
        change={changePortions}
      />
    </div>
  );

  const title = (
    <h2 className="h2-recipe meal-preview">
      {props.meal.name}
    </h2>
  );

  const imageStyle = {objectFit: "cover", width: "100%", maxHeight: "200px", minWidth: "220px", borderRadius: ".35rem"}

  const image = (
    <img
      src={isNotEmpty(props.meal.photo) ? assetUrl(props.meal.photo) : `${process.env.PUBLIC_URL}/images/recipe-placeholder-picture.jpg`}
      alt={props.meal.name}
      style={imageStyle}
    />
  );

  const stashItem = props.stashStore.items.filter(i => i.documentType === documentTypes.RECIPE).find(i => i.documentId === props.meal.recipeId);
  const servingsInPlanText = props.meal.totalPortions === 1 ? " serving in Plan" : " servings in Plan";
  const servingsInVaultText = props.meal.portions === 1 ? " serving in Recipe Vault" : " servings in Recipe Vault";

  const viewRecipeButton = props.meal.totalPortions > props.meal.portions ? (
    <div className="dropdown">
      <button
        id="view"
        className="btn btn-primary"
        type="button"
        title="View recipe"
        data-bs-toggle="dropdown"
      >
        <span className="d-block d-sm-none small text-nowrap px-0"><i className="fa-solid fa-chevron-circle-down me-1" />View</span>
        <span className="d-none d-sm-block small text-nowrap px-0"><i className="fa-solid fa-chevron-circle-down me-1" />View</span>
      </button>
      <ul className="dropdown-menu">
        <li><button type="button" className="btn btn-link dropdown-item" onClick={() => props.viewMeal(props.meal)}>{props.meal.totalPortions}{servingsInPlanText}</button></li>
        <li><button type="button" className="btn btn-link dropdown-item" onClick={viewMealInRecipeVault}>{props.meal.portions}{servingsInVaultText}</button></li>
      </ul>
    </div>
  ) : (
    <button
      className="btn btn-primary"
      type="button"
      title="View recipe"
      onClick={() => props.viewMeal(props.meal)}
    >
        <span className="d-block d-sm-none small text-nowrap px-0"><i className="fa-solid fa-chevron-circle-right me-1" />View</span>
        <span className="d-none d-sm-block small text-nowrap px-0"><i className="fa-solid fa-chevron-circle-right me-1" />View</span>
    </button>
  );

  const removeButton = (
    <div className={props.listView ? "btn-group" : "dropdown px-0 text-center"}>
      <button
        className="btn btn-sm btn-light"
        type="button"
        title="Remove"
        data-bs-toggle="dropdown"
      >
        <span className="fa-solid fa-circle-minus"/>
      </button>
      <ul className="dropdown-menu">
        <li><button type="button" className="btn btn-link dropdown-item" onClick={() => props.removeMeal(props.meal)}>Remove recipe</button></li>
      </ul>
    </div>
  );

  const replaceButton = (
    <button className="btn btn-sm btn-light me-1" type="button" onClick={() => props.selectMeal({ day: props.meal.day, mealType: props.meal.mealType, mealIdToReplace: props.meal.id })} title="Replace">
      <span className="fa-solid fa-retweet"/>
    </button>
  );

  const saveButton = (
    <StashButton
      documentId={props.meal.recipeId}
      documentType={documentTypes.RECIPE}
      smallButton={true}
      margin=" me-1"
      title="Save"
      active={stashItem === undefined ? false : stashItem.saved}
      activeClassName="fa fa-folder-bookmark"
      inactiveClassName="far fa-folder-bookmark"
      toggle={props.stashStore.toggleSaved}
    />
  );

  const loveButton = (
    <StashButton
      documentId={props.meal.recipeId}
      documentType={documentTypes.RECIPE}
      smallButton={true}
      margin=" me-1"
      title="Love"
      active={stashItem === undefined ? false : stashItem.loved}
      activeClassName="fa fa-heart"
      inactiveClassName="far fa-heart"
      toggle={props.stashStore.toggleLove}
    />
  );

  const singleMakeAhead = props.meal.makeAheadToggles.length > 0 ? (
    <div className="pt-sm-0">
      <MakeAheadToggle
        listView={props.listView}
        toggled={props.meal.makeAheadToggles[0].toggled}
        content={<span className="text-muted pb-1 text-nowrap"><i className="fas fa-bowl-spoon" /> Make Ahead</span>}
        toggle={() => props.toggleMakeAhead(props.meal.makeAheadToggles[0].recipeId)}
      />
    </div>
  ) : null;

  return props.listView ? (
    <div className="list-group-item pt-0 pb-1 mb-2">
      <div className="row bg-light meal-list-actions">
        <div className="col-auto list-actions bg-light">
          {removeButton}{replaceButton}{saveButton}{loveButton}
        </div>
      </div>
      <div className="row">
        <div className="col pt-1">{title}</div>
      </div>
      <div className="row">
        {props.meal.makeAheadToggles.length > 0 ? (
          <div className="col p-0 mb-1 mb-md-0 pt-1">
            {props.meal.makeAheadToggles.length === 1 && props.meal.makeAheadToggles[0].recipeId === props.meal.recipeId ? singleMakeAhead : (
              <div>
                <button
                  className="btn text-muted text-nowrap pt-0"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target={`#make-ahead-${props.meal.id}`}
                  aria-expanded="false"
                  aria-controls="make-ahead"
                >
                  <i className="fas fa-bowl-spoon" /> Make Ahead <i className="fa-solid fa-chevron-down ms-1" />
                </button>
                <div className="collapse" id={`make-ahead-${props.meal.id}`}>
                  {makeAheadToggles}
                </div>
              </div>
            )}
          </div>
        ) : (
          /* We keep an empty col here to preserve right alignment of the next col */
          <div className="col"/>
        )}
        <div className="col-auto pb-2">
          <div className="row">
            <div className="col pe-0">{portionSelector}</div>
            <div className="col">{viewRecipeButton}</div>
          </div>
        </div>
      </div>
    </div>
  ) : (
    <div>
      {/* Print only */}
      <div className="d-none d-print-block mb-2 row">
        {image}
        {portionSelector}
      </div>
      <div className="col mb-4">
        <div className="card meal-preview shadow-sm border magic-meal-plan-card">
          <div className="card-body p-0">
            <div className="gx-0 px-2 rounded-top">
              <div className="row">
                {/* left signet */}
                <div className="col-auto">
                  <div className="row bg-light meal-card-actions">
                    {/* remove button */}
                    {removeButton}
                  </div>
                  <div className="row bg-light meal-card-actions">
                    {/* replace button */}
                    {replaceButton}
                  </div>
                  <div className="row bg-light meal-card-actions">
                    {/* save button */}
                    {saveButton}
                  </div>
                  <div className="row bg-light meal-card-actions bottom-rounded">
                    {/* love button */}
                    {loveButton}
                  </div>
                </div>
                {/* right content */}
                <div className="col py-2 pe-2 pe-sm-3">
                  <div className="row">
                    <div className="col">
                      {/* meal type */}
                      <span className="text-muted">
                        <small>{props.mealType === mealTypes.EXTRA ? null : capitalizeFirstLetter(props.mealType)}</small>
                      </span>
                      {/* recipe name */}
                      {title}
                    </div>
                  </div>
                  <div className="row">
                    <div className="col">
                      {/* photo */}
                      {image}
                    </div>
                  </div>
                  {props.meal.makeAheadToggles.length > 0 ? (
                    <div className="row mt-2">
                      <div className="col make-ahead">
                        {/* make ahead list */}
                        {props.meal.makeAheadToggles.length === 1 && props.meal.makeAheadToggles[0].recipeId === props.meal.recipeId ? (
                          <MakeAheadToggle
                            listView={props.listView}
                            toggled={props.meal.makeAheadToggles[0].toggled}
                            content={<span className="text-muted pb-1"><i
                              className="fas fa-bowl-spoon"/> Make Ahead</span>}
                            toggle={() => props.toggleMakeAhead(props.meal.makeAheadToggles[0].recipeId)}
                          />
                        ) : (
                          <>
                            <span className="text-muted pb-1"><i className="fas fa-bowl-spoon"/> Make Ahead</span>
                            {makeAheadToggles}
                          </>
                        )}
                      </div>
                    </div>
                  ) : null}
                  <div className="row mb-2">
                    <div className="col-auto pe-0 mt-2">
                      {/* servings selector */}
                      {portionSelector}
                    </div>                    
                    <div className="col mt-2">
                      {/* view recipe button */}
                      {viewRecipeButton}
                    </div>
                  </div>
  
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

MealPreview.propTypes = {
  stashStore: PropTypes.object,
  listView: PropTypes.bool,
  id: PropTypes.number,
  meal: PropTypes.object,
  mealType: PropTypes.string,
  specialScalingIds: PropTypes.array,
  changePortions: PropTypes.func,
  toggleMakeAhead: PropTypes.func,
  removeMeal: PropTypes.func,
  selectMeal: PropTypes.func,
  viewMeal: PropTypes.func,
  changePortionsInRecipeVault: PropTypes.func,
}