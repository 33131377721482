import React, {useEffect, useState} from "react";
import PropTypes from "prop-types";
import MagicMealPlanPreview from "./components/MagicMealPlanPreview";
import MagicMealPlanForm from "./components/MagicMealPlanForm";
import {Modal} from "bootstrap";
import {Redirect, useLocation} from "react-router-dom";
import Helpers from "../../services/Helpers";

export default function MagicMealPlans(props) {
  const location = useLocation();
  const {isNotEmpty, pageTitle} = Helpers();
  const [idToDelete, setIdToDelete] = useState(null);
  const [deleting, setDeleting] = useState(false);

  useEffect(() => {
    const element = document.getElementById("add-to-meal-plan-modal");
    if (isNotEmpty(element)) {
      const modal = Modal.getOrCreateInstance(element);
      modal.hide();
    }
  }, []);

  useEffect(() => {
    document.title = pageTitle(location.pathname);
  }, [location]);

  useEffect(() => {
    if (idToDelete !== null) {
      const element = document.getElementById("delete-modal");
      const resetModal = Modal.getOrCreateInstance(element);
      resetModal.show();
    }
  }, [idToDelete]);

  function confirmDelete() {
    if (idToDelete !== null) {
      setDeleting(true);
      props.mmpStore.deletePlan(idToDelete, () => {
        const element = document.getElementById("delete-modal");
        const resetModal = Modal.getOrCreateInstance(element);
        resetModal.hide();
        setDeleting(false);
        setIdToDelete(null);
      });
    }
  }

  function hideDeleteModal(event) {
    event.preventDefault();
    setIdToDelete(null);
    const element = document.getElementById("delete-modal");
    const resetModal = Modal.getOrCreateInstance(element);
    resetModal.hide();   
  }

  const newPlan = props.mmpStore.state.plans.length < 20 || props.memberStore.member.admin ? (
    <div className="row mp-new">
      <MagicMealPlanForm key="new" create={props.mmpStore.create}/>
    </div>
  ) : (
    <div className="row mp-new">
      <p>Well, aren't you a plant-based boss! You've created the maximum amount of 20 Magic Meal Plans. Please delete an existing plan to create a new one.</p>
    </div>
  );

  const plans = props.mmpStore.state.plans.map(p => (
    <MagicMealPlanPreview key={p.id} mp={p} mmpStore={props.mmpStore} showDeleteModal={() => setIdToDelete(p.id)} />
  ));
  
  function mmpToDeleteName() {
    let result = null;
    const mmpToDelete = props.mmpStore.state.plans.find(p => p.id === idToDelete);
    if (isNotEmpty(mmpToDelete)) {
      result = mmpToDelete.name;
    }
    return result;
  }

  return props.authenticated ? (
    <div className="tile-collection main">
      {props.banner}
      <h1 className="text-center page-title mb-4 px-1">Magic Meal Planner</h1>
      <div className="container-lg">
        <div className="row h-100">
          <div className="col text">
            <div className="align-items-center text-center w-100 p-2">
              {newPlan}
            </div>
          </div>
          {props.mmpStore.state.plans.length > 0 ? (
            <div className="mt-3">
              <h3 className="h-alt mt-2 text-center">Your Custom Meal Plans</h3>
              <div className="row row-cols-1 row-cols-md-2 row-cols-lg-3 mp-saved">{plans}</div>
            </div>
          ) : null}
        </div>
        <div id="delete-modal" data-bs-backdrop="static" data-bs-keyboard="false" className="modal" tabIndex={-1}>
          <div className="modal-dialog modal-dialog-centered modal-lg">
            <div className="modal-content">
              <div className="modal-header">
                <h1 className="modal-title h-alt">Delete Magic Meal Plan</h1>
                <button type="button" className="btn-close btn-close-black" onClick={hideDeleteModal} aria-label="Close"/>
              </div>
              <div className="modal-body">
                <p>Are you sure you want to delete <strong>{mmpToDeleteName()}</strong>?</p>
              </div>
              <div className="modal-footer">
                <button type="button" className="btn btn-outline-primary" onClick={hideDeleteModal}>No, cancel</button>
                {deleting ? (
                  <button type="button" className="btn btn-secondary me-auto" disabled={true}>Deleting...</button>
                ) : (
                  <button type="button" className="btn btn-secondary me-auto" onClick={confirmDelete}>Yes, delete</button>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  ) : <Redirect to="/sign-in"/>;
}

MagicMealPlans.propTypes = {
  memberStore: PropTypes.object,
  authenticated: PropTypes.bool,
  mmpStore: PropTypes.object,
  banner: PropTypes.element,
}