import {useEffect, useRef, useState} from "react";
import axios from "axios";
import Helpers from "../services/Helpers";

export default function useNotifications(token) {
  const { isNotEmpty } = Helpers();

  const [data, setData] = useState([]);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);
  const intervalRef = useRef(null);

  useEffect(() => {
    getNotifications();

    function getNotifications() {
      try {
        setLoading(true);
        if (isNotEmpty(token)) {
          axios({
            method: "get",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`,
            },
            url: `${process.env.REACT_APP_API}/notifications`,
          }).then((res) => {
            setData(res.data);
          });
        }
      } catch (error) {
        setError(error);
      } finally {
        setLoading(false);
      }
    }
    intervalRef.current = setInterval(getNotifications,30000);
    return () => clearInterval(intervalRef.current);
  }, [token]);

  function dismissAll() {
    if (isNotEmpty(token)) {
      axios({
        method: "put",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        url: `${process.env.REACT_APP_API}/notifications/dismiss/all`,
      })
        .then((res) => {
          setData(res.data);
        })
        .catch(() => {
          //nothing yet
        });
    }
  }

  function dismissNotification(id) {
    if (isNotEmpty(token)) {
      axios({
        method: "put",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        url: `${process.env.REACT_APP_API}/notifications/dismiss/${id}`,
      })
        .then((res) => {
          setData(res.data);
        })
        .catch(() => {
          //nothing yet
        });
    }
  }

  return { notifications: data, error, loading, dismissAll, dismissNotification };
}
