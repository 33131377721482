import "./Checkbox.scss";
import React, {useState, useEffect} from "react";
import PropTypes from "prop-types";

export default function Checkbox(props) {
  const [saving, setSaving] = useState(false);

  useEffect(() => {
    setSaving(false);
  }, [props.checked]);

  function toggle() {
    setSaving(true);
    props.toggle(props.i);
  }

  let result;

  if (saving) {
    result = <i className="far fa-check fa-fade text-white checkbox"/>
  } else {
    if (props.checked) {
      result = props.locked !== undefined && props.locked ? <i className="fa-solid fa-check text-white checkbox checkbox-filled" /> : <i className="cursor-pointer fa-solid fa-check text-white checkbox checkbox-filled" onClick={toggle} />;
    } else {
      result = props.locked !== undefined && props.locked ? <i className="far fa-check text-white checkbox" /> : <i className="cursor-pointer far fa-check text-white checkbox" onClick={toggle} />
    }
  }

  return result;
}

Checkbox.propTypes = {
  i: PropTypes.number,
  checked: PropTypes.bool,
  locked: PropTypes.bool,
  toggle: PropTypes.func,
}
