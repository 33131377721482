import "./CreditCard.css";
import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import Helpers from "../../services/Helpers";
import CreditCardLogo from "./CreditCardLogo";
import SnailButton from "../components/SnailButton";

export default function CreditCard(props) {
  const [working, setWorking] = useState(false);
  const { paddedLast4 } = Helpers();

  useEffect(() => {
    setWorking(false);
  }, [props.card]);

  function makeDefault() {
    setWorking(true);
    props.makeDefault(props.card.id);
  }

  return (
    <div className="col-auto mb-2">
      <div
        className="p-2 m-0 mb-2 bg-white"
        style={{
          borderStyle: "solid",
          borderColor: props.card.status === 3 ? "#f47621" : "darkgray",
          borderWidth: props.card.status === 3 ? "5px" : "1px",
          borderRadius: 5,
          float: "left",
        }}
      >
        <div className="row">
          <div className="col">
            <CreditCardLogo type={props.card.type} />
          </div>
          <div className="col text-end">
            <span className="fw-bold text-nowrap small">
              {props.card.status === 3 ? (
                "Default"
              ) : (
                <SnailButton
                  className="btn m-0 small btn-secondary p-1 text-white"
                  text="Make default"
                  type="button"
                  onClick={makeDefault}
                  disabled={working || props.changingDefaultCard}
                  working={working}
                  workingTexts={["Updating..."]}
                />
              )}
            </span>
          </div>
        </div>

        <p style={{ fontFamily: "Poppins" }} className="p-0 m-0">
          <br />
          <span className="text-nowrap me-4">
            <span style={{ fontFamily: "monospace" }}>
              **** **** **** <span className="fw-bold">{paddedLast4(props.card.last4)}</span>
            </span>
          </span>
          <br />
          <span style={{ fontFamily: "monospace" }}>
            Expires {props.card.exp_month}/{props.card.exp_year % 100}
          </span>
          <br />
          {props.card.firstname} {props.card.lastname}
        </p>
      </div>
    </div>
  );
}

CreditCard.propTypes = {
  card: PropTypes.object,
  changingDefaultCard: PropTypes.bool,
  makeDefault: PropTypes.func,
};
