// ***** WizardPlanStore.js *****
// This store interacts with api endpoints defined in WizardPlansResource.java
// This store provides data and function to WizardPlan views: WizardPlan.js

import {useEffect, useReducer} from "react";
import axios from "axios";
import Helpers from "../Helpers";
import Constants from "../Constants";
import { DateTime } from "luxon";

const actions = {
  CHANGE_COOKING_METHOD: "changeCookingMethod",
  CHANGE_MEAL_PORTIONS: "changeMealPortions",
  LOAD: "load",
  LOAD_PUBLIC_DATA: "loadPublicData",
  UPDATE_CUSTOMIZATIONS: "updateCustomizations",
  UPDATE_CHILD_RECIPE_ADDITIONAL_PORTIONS: "updateChildRecipeAdditionalPortions",
  UPDATE_DELETED_CHILD_RECIPES: "updateDeletedChildRecipes",
  UPDATE_INGREDIENTS: "updateIngredients",
  UPDATE_PREPS: "updatePreps",
  UPDATE_RECIPES_CHECKBOXES: "updateRecipesCheckboxes",
  UPDATING_CHECKBOXES: "updatingCheckboxes",
};

const initialState = {
  mealPlan: {id: null, hashtag: "", description: ""},
  meals: {masters: [], recipes: [], viewRecipes: []},
  batchings: {masters: [], recipes: [], viewRecipes: []},
  nightOfMeals: {masters: [], recipes: [], viewRecipes: []},
  childRecipeIds: [],
  ingredientData: {masters: [], ingredients: [], nutrients: [], views: {produce: [], bulk: [], spicesHerbs: [], packaged: [], itemsYouHave: []}},
  ingredientNutrients: [],
  prepInstructions: {masters: [], preps: []},
  customizations: {childRecipeAdditionalPortions: [], recipeTools: [], contactPreps: [], contactIngredients: [], deletedChildRecipes: [], recipePortions: {}, recipesCheckboxes: []},
  reviews: [],
  stars: 0,
  timing: 0,
  notes: [],
  loading: true,
  publicData: {
    id: null,
    hashtag: "",
    description: "",
    photo: "",
    reviews: [],
    stars: 0
  },
  updatingCheckboxes: false,
};

export default function useWizardPlan(cache, token, member, ss, getPersonalizedContent, hashtag) {
  const {recipeTypes, recipeViewContexts} = Constants();
  const {handleError, isNotEmpty} = Helpers();
  function reducer(state, action) {
    function reset() {
      state.meals.recipes = JSON.parse(JSON.stringify(state.meals.masters));
      state.batchings.recipes = JSON.parse(JSON.stringify(state.batchings.masters));
      state.nightOfMeals.recipes = JSON.parse(JSON.stringify(state.nightOfMeals.masters));
      state.ingredientData.ingredients = JSON.parse(JSON.stringify(state.ingredientData.masters));
      state.ingredientData.views =  {produce: [], bulk: [], spicesHerbs: [], packaged: [], itemsYouHave: []};
    }
    function scale(data) {
      ss.setMealsRatio(data.meals.recipes, data.customizations);
      ss.customizeRecipesComponents(data.batchings, data.customizations.recipeTools);
      ss.customizeRecipesComponents(data.nightOfMeals, data.customizations.recipeTools);
      ss.deleteChildRecipes(data.meals.recipes.concat(data.batchings.recipes.concat(data.nightOfMeals.recipes)), data.customizations.deletedChildRecipes);
      const childRecipeIds = []; // This property has the correct order of preparation for recipes
      ss.addChildRecipeAppearances({meals: data.meals.recipes, batchings: data.batchings.recipes, nightOfMeals: data.nightOfMeals.recipes}, childRecipeIds);
      data.childRecipeIds = [...childRecipeIds].reverse();
      data.batchings.recipes = data.batchings.recipes.filter(r => r.hasOwnProperty("appearances") && r.appearances.length > 0);
      data.nightOfMeals.recipes = data.nightOfMeals.recipes.filter(r => r.hasOwnProperty("appearances") && r.appearances.length > 0);
      const childRecipes = data.batchings.recipes.concat(data.nightOfMeals.recipes);
      addChildRecipeRatios(childRecipes);
      ss.adjustMinimumYield(childRecipes, data.customizations.childRecipeAdditionalPortions);
      const recipes = data.meals.recipes.concat(data.batchings.recipes.concat(data.nightOfMeals.recipes));

      ss.scaleMeals(data.meals.recipes, data.ingredientData.ingredients);
      ss.scaleChildRecipes(data.batchings.recipes, data.ingredientData.ingredients);
      ss.scaleChildRecipes(data.nightOfMeals.recipes, data.ingredientData.ingredients);
      ss.setIngredientsAppearances(data.ingredientData.ingredients, recipes);
      ss.addAllChildRecipeNames({meals: data.meals.recipes, batchings: data.batchings.recipes, nightOfMeals: data.nightOfMeals.recipes}, data.ingredientData.ingredients);
      ss.ingredientsQuantities(data.ingredientData.ingredients);
      data.batchings.viewRecipes = data.batchings.recipes.map(b => ss.generateRecipeView(recipeViewContexts.WIZARD_PLAN, b, recipes, data.ingredientData.ingredients,data.customizations, data.mealPlan.id));
      data.prepInstructions.preps = ss.generatePreps(recipeViewContexts.WIZARD_PLAN, data.batchings.viewRecipes, data.ingredientData.ingredients, data.customizations);
      data.nightOfMeals.viewRecipes = data.nightOfMeals.recipes.map(nom => ss.generateRecipeView(recipeViewContexts.WIZARD_PLAN, nom, recipes, data.ingredientData.ingredients, data.customizations, data.mealPlan.id));
      // TODO: generate view using generateRecipeView
      data.meals.viewRecipes = data.meals.recipes.map(m => ss.generateMeal({batchings: data.batchings, nightOfMeals: data.nightOfMeals}, m, data.ingredientData.ingredients, data.mealPlan.id, data.customizations));
      ss.generateGroceryList(data.ingredientData, recipes, data.customizations.contactIngredients);
    }
    if (action.type === actions.LOAD) {
      const data = {
        ...state,
        mealPlan: action.data.mealPlan,
        meals: {masters: action.data.meals, recipes: JSON.parse(JSON.stringify(action.data.meals)), viewRecipes: []},
        batchings: {masters: action.data.batchings, recipes: JSON.parse(JSON.stringify(action.data.batchings)), viewRecipes: []},
        nightOfMeals: {masters: action.data.nightOfMeals, recipes: JSON.parse(JSON.stringify(action.data.nightOfMeals)), viewRecipes: []},
        childRecipeIds: [],
        ingredientData: {masters: action.data.ingredients, ingredients: JSON.parse(JSON.stringify(action.data.ingredients)), nutrients: action.data.ingredientNutrients, views: {produce: [], bulk: [], spicesHerbs: [], packaged: [], itemsYouHave: []}},
        ingredientNutrients: action.data.ingredientNutrients,
        prepInstructions: {masters: action.data.prepInstructions, preps: []},
        customizations: action.data.customizations,
        reviews: action.data.reviews,
        stars: action.data.stars,
        timing: action.data.timing,
        notes: action.data.notes,
        loading: false,
      }
      scale(data);
      return data;
    } else if (action.type === actions.LOAD_PUBLIC_DATA) {
      const publicData = {
        id: action.data.publicData.id,
        hashtag: action.data.publicData.hashtag,
        description: action.data.publicData.description,
        photo: action.data.publicData.photo,
        reviews: action.data.publicData.reviews,
        stars: action.data.publicData.stars
      };
      return { ...state, publicData:  publicData, loading: false};
    } else if (action.type === actions.UPDATE_CUSTOMIZATIONS) {
      reset();
      const data = {...state, customizations: action.data.customizations};
      scale(data);
      return data;
    } else if (action.type === actions.CHANGE_COOKING_METHOD) {
      reset();
      const customizations = {...state.customizations, recipeTools: action.data.recipeTools};
      const data = {...state, customizations: customizations};
      scale(data);
      return data;
    } else if (action.type === actions.CHANGE_MEAL_PORTIONS) {
      reset();
      const customizations = {...state.customizations, recipePortions: action.data.recipePortions};
      const data = {...state, customizations: customizations};
      scale(data);
      return data;
    } else if (action.type === actions.UPDATE_DELETED_CHILD_RECIPES) {
      reset();
      const customizations = {...state.customizations, deletedChildRecipes: action.data.deletedChildRecipes};
      const data = {...state, customizations: customizations};
      scale(data);
      return data;
    } else if (action.type === actions.UPDATE_PREPS) {
      reset();
      const customizations = {...state.customizations, contactPreps: action.data.contactPreps};
      const data = {...state, customizations: customizations};
      scale(data);
      return data;
    } else if (action.type === actions.UPDATING_CHECKBOXES) {
      return { ...state, updatingCheckboxes: true };
    } else if (action.type === actions.UPDATE_RECIPES_CHECKBOXES) {
      const customizations = {...state.customizations, recipesCheckboxes: action.data.recipesCheckboxes};
      const data = {...state, updatingCheckboxes: false, customizations: customizations};
      const views = data.meals.viewRecipes.concat(data.batchings.viewRecipes).concat(data.nightOfMeals.viewRecipes);
      const view = views.find(v => v.id === action.data.recipeId);
      if (view !== undefined) {
        const recipeCheckboxes = action.data.recipesCheckboxes.find(rc => rc.recipeId === action.data.recipeId);
        if (view.checkboxes !== recipeCheckboxes.checkboxes) {
          view.checkboxes = recipeCheckboxes.checkboxes;
          if (/*view.type === recipeTypes.ASSEMBLY &&*/ view.hasOwnProperty("recipes")) {
            // Meals have a different structure to support the meal card, we need to modify the checkboxes inside the assembly recipe of the meal recipes array
            const assembly = view.recipes.find(r => r.id === action.data.recipeId);
            if (assembly !== undefined) {
              assembly.checkboxes = recipeCheckboxes.checkboxes;
            }
          }
        }
      }
      return data;
    } else if (action.type === actions.UPDATE_INGREDIENTS) {
      reset();
      const customizations = {...state.customizations, contactIngredients: action.data.contactIngredients};
      const data = {...state, customizations: customizations};
      scale(data);
      return data;
    } else if (action.type === actions.UPDATE_CHILD_RECIPE_ADDITIONAL_PORTIONS) {
      reset();
      const customizations = {...state.customizations, childRecipeAdditionalPortions: action.data.childRecipeAdditionalPortions};
      const data = {...state, customizations: customizations};
      scale(data);
      return data;
    }
    return state;
  }
  const [state, dispatch] = useReducer(reducer, initialState);

  useEffect(() => {
    if (isNotEmpty(token)) {
      if (isNotEmpty(member.id)) {
        axios({
          method: "get",
          headers: {
            "Content-Type": "application/json",
            "Authorization": `Bearer ${token}`
          },
          url: `${process.env.REACT_APP_API}/wizard-plans/${hashtag}`,
        }).then(mealPlanRes => {
          axios({
            method: "get",
            headers: {
              "Content-Type": "application/json",
              "Authorization": `Bearer ${token}`
            },
            url: `${process.env.REACT_APP_API}/wizard-plans/${mealPlanRes.data.mealPlan.id}/customizations`,
          }).then(memberRes => {
            dispatch({
              type: actions.LOAD,
              data: {
                mealPlan: mealPlanRes.data.mealPlan,
                meals: mealPlanRes.data.meals,
                batchings: mealPlanRes.data.makeAheadRecipes,
                nightOfMeals: mealPlanRes.data.justInTimeRecipes,
                ingredients: mealPlanRes.data.ingredients,
                ingredientNutrients: mealPlanRes.data.ingredientNutrients,
                prepInstructions: mealPlanRes.data.prepInstructions,
                customizations: memberRes.data,
                reviews: mealPlanRes.data.reviews,
                stars: mealPlanRes.data.stars,
                timing: mealPlanRes.data.timing,
                notes: mealPlanRes.data.notes,
              }
            });
          });
        }).catch(err => {
          handleError(err);
        });
      }
    } else {
      axios({
        method: "get",
        headers: {
          "Content-Type": "application/json",
        },
        url: `${process.env.REACT_APP_API}/wizard-plans/${hashtag}/public-data`,
      }).then(res => {
        dispatch({
          type: actions.LOAD_PUBLIC_DATA,
          data: { publicData: res.data}
        });
      });
    }
  }, [token, member, hashtag]);

  function addChildRecipeRatios(recipes) {
    for (let recipe of recipes) {
      if (recipe.hasOwnProperty("appearances") && recipe.appearances.length > 0) {
        recipe["ratio"] = recipe.appearances.map(a => a.ratio).reduce((a,b) => a+b);
      }
    }
  }

  function deleteCustomizations() {
    axios({
      method: "delete",
      headers: {
        "Content-Type": "application/json",
        "Authorization": `Bearer ${token}`
      },
      url: `${process.env.REACT_APP_API}/wizard-plans/${state.mealPlan.id}/customizations`,
    }).then(res => {
      dispatch({
        type: actions.UPDATE_CUSTOMIZATIONS,
        data: { customizations: res.data}
      });
    });
  }

  function changeCookingMethod(recipeId, toolName) {
    const toolId = cache.toolId(toolName);
    axios({
      method: "put",
      headers: {
        "Content-Type": "application/json",
        "Authorization": `Bearer ${token}`
      },
      url: `${process.env.REACT_APP_API}/wizard-plans/${state.mealPlan.id}/recipe-tools/${recipeId}/tools/${toolId}`,
      data: {recipeId: recipeId, toolId: toolId}
    }).then(res => {
      dispatch({
        type: actions.CHANGE_COOKING_METHOD,
        data: { recipeTools: res.data}
      });
    });
  }

  function changePortions(recipeId, portions) {
    axios({
      method: "put",
      headers: {
        "Content-Type": "application/json",
        "Authorization": `Bearer ${token}`
      },
      url: `${process.env.REACT_APP_API}/wizard-plans/${state.mealPlan.id}/meals/${recipeId}`,
      data: {recipeId: recipeId, portions: portions}
    }).then(res => {
      dispatch({
        type: actions.CHANGE_MEAL_PORTIONS,
        data: { recipePortions: res.data}
      });
    });
  }

  function updatePrep(recipeComponentId, checked) {
    axios({
      method: "put",
      headers: {
        "Content-Type": "application/json",
        "Authorization": `Bearer ${token}`
      },
      url: `${process.env.REACT_APP_API}/wizard-plans/${state.mealPlan.id}/recipe-components/${recipeComponentId}`,
      data: {recipeComponentId: recipeComponentId, checked: checked}
    }).then(res => {
      dispatch({
        type: actions.UPDATE_PREPS,
        data: { contactPreps: res.data}
      });
    });
  }

  function updateIngredient(ingredientId, checked) {
    axios({
      method: "put",
      headers: {
        "Content-Type": "application/json",
        "Authorization": `Bearer ${token}`
      },
      url: `${process.env.REACT_APP_API}/wizard-plans/${state.mealPlan.id}/ingredients/${ingredientId}`,
      data: {ingredientId: ingredientId, checked: checked}
    }).then(res => {
      dispatch({
        type: actions.UPDATE_INGREDIENTS,
        data: { contactIngredients: res.data }
      });
    });
  }

  function deleteIngredients() {
    axios({
      method: "delete",
      headers: {
        "Content-Type": "application/json",
        "Authorization": `Bearer ${token}`
      },
      url: `${process.env.REACT_APP_API}/wizard-plans/${state.mealPlan.id}/ingredients`,
    }).then(() => {
      dispatch({
        type: actions.UPDATE_INGREDIENTS,
        data: { contactIngredients: [] }
      });
    });
  }

  function deleteChildRecipe(parentRecipeId, childRecipeId) {
    const parentRecipe = state.meals.recipes.find(r => r.id === parentRecipeId);
    if (isNotEmpty(parentRecipe)) {
      const components = parentRecipe.stoveTopRecipeComponents.length > 0 ? parentRecipe.stoveTopRecipeComponents : parentRecipe.instantPotRecipeComponents;
      const rc = components.find(rc => rc.childRecipeId === childRecipeId);
      if (isNotEmpty(rc)) {
        axios({
          method: "delete",
          headers: {
            "Content-Type": "application/json",
            "Authorization": `Bearer ${token}`
          },
          url: `${process.env.REACT_APP_API}/wizard-plans/${state.mealPlan.id}/recipes/${parentRecipeId}/child-recipes/${childRecipeId}`,
        }).then(res => {
          dispatch({
            type: actions.UPDATE_DELETED_CHILD_RECIPES,
            data: { deletedChildRecipes: res.data }
          });
        });
      }
    }
  }

  function restoreChildRecipes(parentRecipeId ) {
    axios({
      method: "get",
      headers: {
        "Content-Type": "application/json",
        "Authorization": `Bearer ${token}`
      },
      url: `${process.env.REACT_APP_API}/wizard-plans/${state.mealPlan.id}/recipes/${parentRecipeId}/restore-child-recipes`,
    }).then(res => {
      dispatch({
        type: actions.UPDATE_DELETED_CHILD_RECIPES,
        data: { deletedChildRecipes: res.data }
      });
    });
  }

  function updateChildRecipeAdditionalPortions(data) {
    axios({
      method: "put",
      headers: {
        "Content-Type": "application/json",
        "Authorization": `Bearer ${token}`
      },
      url: `${process.env.REACT_APP_API}/wizard-plans/${state.mealPlan.id}/child-recipes/${data.childRecipeId}/additional-portions`,
      data: data,
    }).then(res => {
      dispatch({
        type: actions.UPDATE_CHILD_RECIPE_ADDITIONAL_PORTIONS,
        data: { childRecipeAdditionalPortions: res.data }
      });
    });
  }

  /**
   * Update checkboxes of a recipe
   *
   * Called after a checkbox (recipe or recipe step) is checked/unchecked. The new data is persisted server side and
   * the new recipe checkbox list is then saved in local state.
   *
   * @param {number} recipeId - id of the recipe
   * @param {boolean[]} checkboxes - array of boolean representing checkboxes state
   */
  function updateCheckboxes(recipeId, checkboxes) {
    dispatch({type: actions.UPDATING_CHECKBOXES});
    let updatePersonalizedContent = false;
    const recipeMade = { 
      madeAt: DateTime.local().toString(), // get local datetime to record or remove recipe made at
      recipeId: recipeId,
      mealPlanId: state.mealPlan.id,
    };
    if (checkboxes.filter(c => !c).length === 0) { // if all checkboxes are true, record a recipe made
      updatePersonalizedContent = true;
      const recipe = state.meals.recipes.concat(state.batchings.recipes.concat(state.nightOfMeals.recipes)).find(r => r.id === recipeId);
      let stoveTopOrInstantPot = null;
      if (recipe.hasInstantPotAndStoveTopVersions) {
        stoveTopOrInstantPot = recipe.stoveTopRecipeComponents.length > 0 ? "Stove top" : "Instant Pot";
      }
      recipeMade["portions"] = recipe.type === "Assembly" && recipe.displayOrder < 6 ? recipe.customPortions : null;
      recipeMade["yieldVolume"] = recipe.scaledYieldVolume;
      recipeMade["yieldUnitId"] = recipe.yieldUnitId;
      recipeMade["stoveTopOrInstantPot"] = stoveTopOrInstantPot;
    }
    axios({
      method: "put",
      headers: {
        "Content-Type": "application/json",
        "Authorization": `Bearer ${token}`
      },
      url: `${process.env.REACT_APP_API}/wizard-plans/${state.mealPlan.id}/recipe-progress/${recipeId}`,
      data: {recipeCheckboxes: {recipeId: recipeId, checkboxes: checkboxes}, recipeMade: recipeMade}
    }).then(res => {
      if (updatePersonalizedContent) {
        getPersonalizedContent();
      }
      dispatch({
        type: actions.UPDATE_RECIPES_CHECKBOXES,
        data: { recipeId: recipeId, recipesCheckboxes: res.data}
      });
    });
  }

  function prepareDataForNutritionFacts(recipeId) {
    let masters = JSON.parse(JSON.stringify(state.meals.masters)).concat(JSON.parse(JSON.stringify(state.batchings.masters)).concat(JSON.parse(JSON.stringify(state.nightOfMeals.masters))));
    // If there are bonus recipes, we must remove duplicates
    masters = masters.filter((elem, index, self) => self.findIndex((t) => {return (t.id === elem.id)}) === index);
    let recipes = JSON.parse(JSON.stringify(state.meals.masters)).concat(JSON.parse(JSON.stringify(state.batchings.masters)).concat(JSON.parse(JSON.stringify(state.nightOfMeals.masters))));
    // If there are bonus recipes, we must remove duplicates
    recipes = recipes.filter((elem, index, self) => self.findIndex((t) => {return (t.id === elem.id)}) === index);
    return {
      recipeId: recipeId,
      recipeData: {masters: masters, recipes: recipes},
      recipeTools: state.customizations.recipeTools,
      deletedChildRecipes: state.customizations.deletedChildRecipes,
      ingredients: state.ingredientData.ingredients,
      ingredientNutrients: state.ingredientNutrients,
    };
  }

  const specialScalingIds = cache.specialScalingIds;

  return {state, specialScalingIds, changeCookingMethod, changePortions, deleteChildRecipe, deleteCustomizations, deleteIngredients, prepareDataForNutritionFacts, restoreChildRecipes, updateCheckboxes, updateChildRecipeAdditionalPortions, updateIngredient, updatePrep};
}