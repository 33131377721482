import React from "react";
import PropTypes from "prop-types";

export default function Allergens(props) {
  let result = null;
  if (props.allergens.length > 0) {
    result = <span className="allergens text-muted d-print-none small">Contains: {props.allergens.join(", ")}</span>;
  }

  return result;
}

Allergens.propTypes = {
  allergens: PropTypes.array,
}