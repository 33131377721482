import React, {useEffect, useState} from "react";
import PropTypes from "prop-types";
import {Dropdown} from "bootstrap";
import Helpers from "../../services/Helpers";

export default function EditNoteForm(props) {
  const {isNotEmpty} = Helpers();
  const [text, setText] = useState("");
  const [working, setWorking] = useState(false);

  useEffect(() => {
    setText(props.note.text);
  }, [props.note.text]);

  function save(publiclyAvailable) {
    setWorking(true);
    const element = document.getElementById("save");
    const dropdownButton = Dropdown.getOrCreateInstance(element);
    dropdownButton.toggle();
    const data = {
      id: props.note.id,
      text: text,
      publiclyAvailable: publiclyAvailable,
      documentId: props.note.documentId,
      documentType: props.note.documentType,
    }
    props.update(data, () => {
      setText("");
      setWorking(false);
      props.hide();
    })
  }

  function button() {
    let result;
    if (working) {
      result = (
        <button
          id="save"
          className="btn btn-primary dropdown-toggle"
          type="button"
          disabled={true}
        >
          <span className="spinner-border spinner-border-sm"/>
          <span className="ms-1">Saving...</span>
        </button>
      );
    } else if (isNotEmpty(text)) {
      result = (
        <button
          id="save"
          className="btn btn-primary dropdown-toggle"
          type="button"
          data-bs-toggle="dropdown"
        >
          Save note
        </button>
      );
    } else {
      result = (
      <button
        id="save"
        className="btn btn-primary dropdown-toggle"
        type="button"
        disabled={true}
      >
        Save note
      </button>
      );
    }
    return result;
  }

  return (
    <form>
      <textarea
        className="form-control mb-3"
        placeholder="Leave a note here"
        rows={3}
        value={text}
        onChange={e => setText(e.target.value)}
      />
      <div className="dropdown">
        {button()}
        <ul className="dropdown-menu">
          <li><button type="button" className="btn btn-link dropdown-item" onClick={() => save(true)}>Make Public</button></li>
          <li><button type="button" className="btn btn-link dropdown-item" onClick={() => save(false)}>Make Private</button></li>
        </ul>
      </div>
    </form>
  );
}

EditNoteForm.propTypes = {
  note: PropTypes.object,
  update: PropTypes.func,
  hide: PropTypes.func,
}