import React, {useEffect, useState} from "react";
import PropTypes from "prop-types";
import {Link, Redirect, useLocation} from "react-router-dom";
import Footer from "../components/Footer";
import Helpers from "../../services/Helpers";

export default function ResetPassword(props) {
  let location = useLocation();
  const {isEmpty} = Helpers();
  const [email, setEmail] = useState("");
  const [resetDigest, setResetDigest] = useState("");
  const [password, setPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [working, setWorking] = useState(false);

  useEffect(() => {
    let searchParams = new URLSearchParams(location.search);
    const emailParam = searchParams.get("email");
    const digestParam = searchParams.get("reset-digest");
    if (isEmpty(emailParam) || isEmpty(digestParam)) {
      window.location.href = "/sign-in";
    } else {
      setEmail(emailParam);
      setResetDigest(digestParam);
    }
  }, [location]);

  function handleSubmit(event) {
    event.preventDefault();
    setWorking(true);
    props.resetPassword({
      email: email,
      digest: resetDigest,
      password: password,
    }, () => {
      setWorking(false);
    });
  }

  const main = (
    <form onSubmit={handleSubmit}>
      <div className="mb-1">
        <label htmlFor="password" className="form-label">
          Password
        </label>
        <input
          type={showPassword ? "text" : "password"}
          autoComplete="off"
          className="form-control"
          id="password"
          placeholder="Type your password here..."
          value={password}
          pattern=".{8,}"
          title="Your password must be at least 8 characters long. You can do it!"
          required={true}
          onChange={e => setPassword(e.target.value)}
        />
      </div>
      <div className="mb-3">
        <label className="form-check-label cursor-pointer">
          <input
            className="form-check-input cursor-pointer"
            type="checkbox"
            checked={showPassword}
            onChange={e => setShowPassword(e.target.checked)}
          /> Show password
        </label>
      </div>
      {working ? (
        <button type="button" className="btn btn-primary" disabled={true}>
          <span className="spinner-border spinner-border-sm"/>
          <span className="ms-1">Updating...</span>
        </button>
        ) : <button type="submit" className="btn btn-primary">Update My Password</button>}
    </form>
  );

  return props.authenticated ? (
    <Redirect to={{pathname: "/"}}/>
  ) : (
    <>
      <main id="forgot-password" className="container-lg">
        <div className="align-items-center">
          <div className="forgot-password">
            <h1>Reset your password</h1>
            {main}
            <p className="mt-3">
              Not what you were looking for? <Link to="/sign-in" className="text-muted">Go back to sign in</Link>.
            </p>
          </div>
        </div>
      </main>
      <Footer/>
    </>
  );
}

ResetPassword.propTypes = {
  authenticated: PropTypes.bool,
  resetPassword: PropTypes.func,
}