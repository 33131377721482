import "./ThankYou.css";
import React from "react";
import PropTypes from "prop-types";
import { Link, Redirect, useLocation } from "react-router-dom";

export default function ThankYou(props) {
  let location = useLocation();
  const params = new URLSearchParams(location.search);
  const plan = params.get("p");

  const header = (
    <div className="row mb-3 align-items-center">
      <div className="col text-center">
        <Link to="/">
          <img src={`${process.env.PUBLIC_URL}/images/Clean-Food-Dirty-Girl-Main-Logo.png`} className="header-logo" alt="Clean Food Dirty Girl" />
        </Link>
      </div>
    </div>
  );

  const fullAccessContent = (
    <>
      <div className="text-center mt-3">
      <img id="thank-you-animation" src="https://assets.cleanfooddirtygirl.com/static/welcome-plant-party.gif" className="rounded" />
      </div>
      <p className="fs-4 mt-3 fw-bold">There's no better way to start than to dive in and cook a recipe that will impress the socks right off your feet!</p>
      <p className="lh-lg">
        Love Mac and Cheese? We have a version that will delight and inspire you. Is Creamy Tomato Soup your go-to comfort food? You MUST try ours, it's out of this world! Have you always wanted to
        try baked oatmeal? We have a recipe that will blow your mind in the best possible way.
      </p>
      <p className="lh-lg">
        All the recipes in the <Link to="/recipes/recipe-collections/must-make-recipes-for-beginners">Must Make Recipes for Beginners</Link> collection are gold. Jumpstart your membership by picking
        one recipe from this collection and follow along.
      </p>
      <p className="lh-lg">
        <Link to="/recipes/recipe-collections/must-make-recipes-for-beginners">
          <img id="thank-you-recipe-collection" src="https://assets.cleanfooddirtygirl.com/static/thank-you-view-beginners-collection.jpg" className="rounded" />
        </Link>
      </p>
      <p className="lh-lg">Okay, you awesome human, go forth and cook some delicious plants, we are so excited for you!</p>
      <p className="lh-lg">Molly + team</p>
      <div className="mt-4">
        <Link to="/">Go to homepage</Link>
      </div>
    </>
  );

  // For Recipe Club members
  const recipeVaultContent = (
    <>
      <div className="text-center mt-3">
        <img id="thank-you-animation" src="https://assets.cleanfooddirtygirl.com/static/welcome-plant-party.gif" className="rounded" />
      </div>
      <p className="fs-4 mt-3 fw-bold">There's no better way to start than to dive in and cook a recipe that will impress the socks right off your feet!</p>
      <p className="lh-lg">
        Love Mac and Cheese? We have a version that will delight and inspire you. Is Creamy Tomato Soup your go-to comfort food? You MUST try ours, it's out of this world! Have you always wanted to
        try baked oatmeal? We have a recipe that will blow your mind in the best possible way.
      </p>
      <p className="lh-lg">
        All the recipes in the <Link to="/recipes/recipe-collections/must-make-recipes-for-beginners">Must Make Recipes for Beginners</Link> collection are gold.  by picking
        one recipe from this collection and follow along.
      </p>
      <p className="lh-lg">
        <Link to="/recipes/recipe-collections/must-make-recipes-for-beginners">
          <img id="thank-you-recipe-collection" src="https://assets.cleanfooddirtygirl.com/static/thank-you-view-beginners-collection.jpg" className="rounded" />
        </Link>
      </p>
      <p className="lh-lg">Okay, you awesome human, go forth and cook some delicious plants, we are so excited for you!</p>
      <p className="lh-lg">Molly + team</p>
      <div className="mt-4">
        <Link to="/">Go to homepage</Link>
      </div>
    </>
  );

  return props.authenticated ? (
    <main id="thank-you" className="container-lg mt-0 my-sm-auto p-3 p-sm-4">
      {header}
      {plan === "rv_monthly" ? recipeVaultContent : fullAccessContent}
    </main>
  ) : (
    <Redirect to="/sign-in" />
  );
}

ThankYou.propTypes = {
  authenticated: PropTypes.bool,
};
