import React from "react";
import PropTypes from "prop-types";
import Constants from "../../../services/Constants";

export default function RecipeDetails(props) {
  const {recipeTypes} = Constants();

  return (
    <div>
      {props.components.length > 0 ? (
        <div className="recipe-ingredients-directions">
          {props.recipeType === recipeTypes.ASSEMBLY ? (
            <span className="markdown-notes">
              <p className="text-muted"><em>These measurements are guidelines. Adjust to your liking.</em></p>
            </span> 
          ) : null}
          <h5 className="mb-3 print-padding">Ingredients</h5>
          <div className="ms-2 mb-2">
            {props.components}
          </div>
        </div>
      ) : null}

      <div>
        {props.components.length > 0 ? <h5 className="mt-4 mb-3 print-padding-directions">Directions</h5> : null}
        <div className="ms-2 mb-2">
          {props.instructions}
        </div>
      </div>
    </div>
  )
}

RecipeDetails.propTypes = {
  recipeType: PropTypes.string,
  components: PropTypes.array,
  instructions: PropTypes.array,
}