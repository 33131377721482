import React from "react";
import PropTypes from "prop-types";

export default function MicroNutrient(props) {
  const config = props.config.find(fn => fn.name === props.nutrient.name);

  return (
    <tr>
      <td><strong>{config.friendlyName}</strong></td><td className="nutrient-fact">&nbsp;{Math.round(props.nutrient.amount)}{props.nutrient.unit}</td>
    </tr>
  );
}

MicroNutrient.propTypes = {
  nutrient: PropTypes.object,
  config: PropTypes.array,
}