import React, {useEffect, useState} from "react";
import PropTypes from "prop-types";
import Footer from "../components/Footer";
import {Link} from "react-router-dom";

export default function NotFound(props) {
  const [waiting, setWaiting] = useState(true);

  useEffect(() => {
    setTimeout(() => {
      setWaiting(false);
    }, 2000);
  }, []);

  const main = props.authenticated ? (
    <>
      <main id="not-found" className="container-lg">
        <h1 className="text-center page-title mb-3">Page Not Found</h1>
        <div className="text-center align-items-center d-sm-none d-md-block">Let's get you somewhere awesome.</div>


        <div className="row px-2 mt-2 py-md-2 px-md-2 anon-footer justify-content-center">

          {/* <div className="tile tile-magic col-12 col-md-4 mb-4">
            <Link to="/meal-plans/meal-planner">
              <div className="card h-100 text-center brighten border-0 rounded shadow-sm">
                <img
                  src={`${process.env.PUBLIC_URL}/images/magic-whole-food-plant-based-meal-planner-breakfast-lunch-dinner.jpeg`}
                  alt="vegan plant based meal planner"
                  className="img-fluid rounded-top card-img-top"/>
                <div className="card-body p-3">
                  <h3 className="card-title">Magic Meal Planner</h3>
                  <p className="card-text text-muted text-start pt-1">Create your own meal plans with our recipes. Save
                    money and time every week.</p>
                </div>
              </div>
            </Link>
          </div> */}


          <div className="tile tile-orange col-12 col-md-4 mb-4">
            <Link to="/meal-plans/wizard-plans">
              <div className="card h-100 text-center brighten border-0 rounded shadow-sm">
                <img src={`${process.env.PUBLIC_URL}/images/whole-food-plant-based-meal-plans.jpeg`}
                     alt="plant-based plant fueled life coaching with molly patrick clean food dirty girl"
                     className="img-fluid rounded-top card-img-top"/>
                <div className="card-body p-3">
                  <h3 className="card-title">Meal Plan Club</h3>
                  <p className="card-text text-muted text-start pt-1">No more planning and wondering what's for dinner with the help of our hand-crafted whole-food plant-based meal plans.</p>
                </div>
              </div>
            </Link>
          </div>

          <div className="tile tile-green col-12 col-md-4 mb-4">
            <Link to="/recipes/recipe-vault">
              <div className="card h-100 text-center brighten border-0 rounded shadow-sm">
                <img src={`${process.env.PUBLIC_URL}/images/whole-food-plant-based-recipes.jpeg`}
                     alt="oil free dairy free plant-based recipes clean food dirty girl"
                     className="img-fluid rounded-top card-img-top"/>
                <div className="card-body p-3">
                  <h3 className="card-title">Recipe Club</h3>
                  <p className="card-text text-muted text-start pt-1">No more disappointment from random plant-based recipes on the interwebz. Get ready for a damn delicious ad-free experience.</p>
                </div>
              </div>
            </Link>
          </div>
          

        </div>


      </main>
      <Footer/>
    </>
  ) : (
    // Britt: here starts the anonymous page
    <>
      <main id="not-found" className="container-lg">
        <h1 className="text-center page-title mb-3">Page Not Found</h1>
        <div className="text-center align-items-center d-sm-none d-md-block">Let's get you somewhere awesome.</div>


        <div className="row px-2 mt-2 py-md-2 px-md-2 anon-footer justify-content-center">

          {/* <div className="tile col-12 col-md-4 mb-4">
            <Link to="/meal-plans/meal-planner">
              <div className="card h-100 text-center brighten border-0 rounded shadow-sm">
                <img
                  src={`${process.env.PUBLIC_URL}/images/magic-whole-food-plant-based-meal-planner-breakfast-lunch-dinner.jpeg`}
                  alt="vegan plant based plant meal planner"
                  className="img-fluid rounded-top card-img-top"/>
                <div className="card-body p-3">
                  <h3 className="card-title">Magic Meal Planner</h3>
                  <p className="card-text text-muted text-start pt-1">Create your own meal plans with our recipes. Save
                    money and time every week.</p>
                </div>
              </div>
            </Link>
          </div> */}


          <div className="tile col-12 col-md-4 mb-4">
            <Link to="/meal-plans/wizard-plans">
              <div className="card h-100 text-center brighten border-0 rounded shadow-sm">
                <img src={`${process.env.PUBLIC_URL}/images/whole-food-plant-based-meal-plans.jpeg`}
                     alt="plant-based meal plans with batch cooking meal prep plant fueled life"
                     className="img-fluid rounded-top card-img-top"/>
                <div className="card-body p-3">
                  <h3 className="card-title">Meal Plan Club</h3>
                  <p className="card-text text-muted text-start pt-1">No more planning and wondering what's for dinner with the help of our hand-crafted whole-food plant-based meal plans.</p>
                </div>
              </div>
            </Link>
          </div>

          <div className="tile col-12 col-md-4 mb-4">
            <Link to="/recipes/recipe-vault">
              <div className="card h-100 text-center brighten border-0 rounded shadow-sm">
                <img src={`${process.env.PUBLIC_URL}/images/whole-food-plant-based-recipes.jpeg`}
                     alt="best whole-food plant-based recipes"
                     className="img-fluid rounded-top card-img-top"/>
                <div className="card-body p-3">
                  <h3 className="card-title">Recipe Club</h3>
                  <p className="card-text text-muted text-start pt-1">No more disappointment from random plant-based recipes on the interwebz. Get ready for a damn delicious ad-free experience.</p>
                </div>
              </div>
            </Link>
          </div>
       

        </div>


      </main>
      <Footer/>
    </>
  );

  return waiting ? (
    <main className="text-center">
      <span className="spinner-border"/>
    </main>
  ) : main;
}

NotFound.propTypes = {
  authenticated: PropTypes.bool,
}