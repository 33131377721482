export default function Socials() {
  return (
    <nav className="menu-icons mt-3">
      <a className="p-2" href="https://www.facebook.com/cfdgirl" target="_blank" rel="noreferrer">
        <i className="fab fa-facebook" />
      </a>
      <a className="p-2" href="https://www.instagram.com/cleanfooddirtygirl/" target="_blank" rel="noreferrer">
        <i className="fab fa-instagram" />
      </a>
      <a className="p-2" href="https://www.pinterest.com/clnfooddrtygrl/" target="_blank" rel="noreferrer">
        <i className="fab fa-pinterest"></i>
      </a>
      <a className="p-2" href="https://open.spotify.com/show/5ViLN4goOjFHyVJs5nMDnG" target="_blank" rel="noreferrer">
        <i className="fab fa-spotify" />
      </a>
      <a className="p-2 strip-right" href="https://cleanfooddirtygirl.threadless.com/" target="_blank" rel="noreferrer">
        <i className="fa-regular fa-bag-shopping"></i>
      </a>
    </nav>
  );
}
