// ***** CollectionStore.js *****
// This store interacts with api endpoints defined in CmsResource.java
// This store provides data and function to RecipeCollection and WizardPlanCollection views: RecipeCollection.js, WizardPlanCollection.js

import {useEffect, useState} from "react";
import axios from "axios";
import Helpers from "../Helpers";
import Constants from "../Constants";

export default function useCollection(token, collectionType, id) {
  const {collectionTypes} = Constants();
  const {isNotEmpty} = Helpers();
  const [collection, setCollection] = useState({
    id: null,
    cssClass: "",
    title: "",
    text: "",
    items: []
  });

  useEffect(() => {
    if (isNotEmpty(id)) {
      let url = "";
      if (collectionType === collectionTypes.RECIPE) {
        url = `${process.env.REACT_APP_API}/cms/recipe-collections/${id}`;
      } else if (collectionType === collectionTypes.WIZARD_PLAN) {
        url = `${process.env.REACT_APP_API}/cms/wizard-plan-collections/${id}`;
      }
      axios({
        method: "GET",
        headers: {"Content-Type": "application/json"},
        url: url,
      }).then(res => {
        setCollection(res.data);
      }).catch(err => {
        console.log(err);
      });
    }
  }, [id]);


  return {collection};
}