import React from "react";
import PropTypes from "prop-types";
import Helpers from "../../services/Helpers";

export default function ReviewTiming(props) {
  const {isEmpty} = Helpers();

  function render() {
    const muted = props.muted ? "text-muted " : "";
    const timing = [];
    const timingCount = props.timing ? props.timing : 0;
    for (let i = 1; i < 4; i++) {
      const className = timingCount >= i ? `${muted}fas fa-clock` : `${muted}far fa-clock`;
      timing.push(<span key={i}><i className={className} /> </span>);
    }
    return isEmpty(props.cta) ?
      <div className={`${muted}timing`} style={props.style}>{props.prefix}{timing}</div> :
      <div className={`${muted}timing cursor-pointer`} style={props.style} onClick={props.cta}>{props.prefix}{timing}</div>;
  }

  return render();
}

ReviewTiming.propTypes = {
  prefix: PropTypes.string,
  style: PropTypes.object,
  muted: PropTypes.bool,
  timing: PropTypes.number,
  cta: PropTypes.func,
}