import { useEffect, useReducer } from "react";
import axios from "axios";
import Helpers from "../Helpers";
import Constants from "../Constants";

const actions = {
  SUBSCRIPTION: "subscription",
  SUBSCRIPTION_MESSAGE: "subscriptionMessage",
  WAITING_FOR_SUBSCRIPTION: "waitingForSubscription",
  CREDIT_CARDS: "creditCards",
  CHANGING_DEFAULT_CARD: "changingDefaultCard",
  WAITING_FOR_CREDIT_CARDS: "waitingForCreditCards",
  PLANS: "plans",
  GET_PRORATION: "getProration",
};
const initialState = {
  // subscription: { id: null, plan: null, internalName: null, nextBillingDate: null, nextPaymentAmount: null, canceled: null, plans: [] },
  subscription: [],
  subscriptionMessage: "",
  waitingForSubscription: true,
  creditCards: [],
  changingDefaultCard: false,
  waitingForCreditCards: false,
  plans: [],
  proration: { amount: 0, date: new Date().getTime() / 1000 },
};
export default function useSubscription(token, member, getMember) {
  const { subscriptionMessages } = Constants();
  const { isNotEmpty } = Helpers();
  function reducer(state, action) {
    if (action.type === actions.SUBSCRIPTION) {
      return { ...state, subscription: action.data.subscription, waitingForSubscription: false };
    } else if (action.type === actions.SUBSCRIPTION_MESSAGE) {
      return { ...state, subscriptionMessage: action.data.message };
    } else if (action.type === actions.WAITING_FOR_SUBSCRIPTION) {
      return { ...state, waitingForSubscription: action.data.waiting };
    } else if (action.type === actions.CREDIT_CARDS) {
      return { ...state, creditCards: action.data.creditCards, waitingForCreditCards: false };
    } else if (action.type === actions.CHANGING_DEFAULT_CARD) {
      return { ...state, creditCards: action.data.creditCards, changingDefaultCard: action.data.changing, subscriptionMessage: "" };
    } else if (action.type === actions.WAITING_FOR_CREDIT_CARDS) {
      return { ...state, waitingForCreditCards: action.data.waiting };
    } else if (action.type === actions.PLANS) {
      return { ...state, plans: action.data.plans };
    } else if (action.type === actions.GET_PRORATION) {
      return { ...state, proration: action.data.proration };
    }

    return state;
  }
  const [state, dispatch] = useReducer(reducer, initialState);

  useEffect(() => {
    if (isNotEmpty(member.id)) {
      getCreditCards();
      getSubscription();
      getPlans();
    }
  }, [member]);

  function resetSubscriptionMessage() {
    dispatch({
      type: actions.SUBSCRIPTION_MESSAGE,
      data: { message: "" },
    });
  }

  function getSubscription() {
    axios({
      method: "get",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      url: `${process.env.REACT_APP_API}/subscription`,
    })
      .then((res) => {
        dispatch({
          type: actions.SUBSCRIPTION,
          data: { subscription: res.data },
        });
      })
      .catch(() => {
        dispatch({
          type: actions.WAITING_FOR_SUBSCRIPTION,
          data: { waiting: false },
        });
      });
  }

  function addSubscription(data, cb) {
    axios({
      method: "post",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      url: `${process.env.REACT_APP_API}/subscription`,
      data: data,
    })
      .then((res) => {
        if (res.data === subscriptionMessages.SUCCESS) {
          getSubscription();
          dispatch({
            type: actions.SUBSCRIPTION_MESSAGE,
            data: { message: subscriptionMessages.ADD_SUBSCRIPTION_SUCCESS },
          });
        } else if (res.data === subscriptionMessages.ERROR_CARD) {
          dispatch({
            type: actions.SUBSCRIPTION_MESSAGE,
            data: { message: subscriptionMessages.ERROR_CARD },
          });
        } else {
          dispatch({
            type: actions.SUBSCRIPTION_MESSAGE,
            data: { message: subscriptionMessages.ERROR },
          });
        }
        getMember();
        if (cb) cb();
      })
      .catch(() => {
        if (cb) cb();
      });
  }

  function changeSubscription(data, cb) {
    axios({
      method: "put",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      url: `${process.env.REACT_APP_API}/subscription`,
      data: data,
    })
      .then((res) => {
        if (res.data === subscriptionMessages.SUCCESS) {
          getSubscription();
          getMember();
          dispatch({
            type: actions.SUBSCRIPTION_MESSAGE,
            data: { message: subscriptionMessages.CHANGE_SUBSCRIPTION_SUCCESS },
          });
        } else if (res.data === subscriptionMessages.ERROR_CARD) {
          dispatch({
            type: actions.SUBSCRIPTION_MESSAGE,
            data: { message: subscriptionMessages.ERROR_CARD },
          });
        } else {
          dispatch({
            type: actions.SUBSCRIPTION_MESSAGE,
            data: { message: subscriptionMessages.ERROR },
          });
        }
        if (cb) cb();
      })
      .catch(() => {
        if (cb) cb();
      });
  }

  function cancelSubscription(data, cb) {
    axios({
      method: "delete",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      url: `${process.env.REACT_APP_API}/subscription`,
      data: data,
    })
      .then((res) => {
        if (res.data === subscriptionMessages.SUCCESS) {
          getSubscription();
        } else {
          dispatch({
            type: actions.SUBSCRIPTION_MESSAGE,
            data: { message: subscriptionMessages.ERROR },
          });
        }
        if (cb) cb();
      })
      .catch(() => {
        if (cb) cb();
      });
  }

  /**
   * Fetch from the ontraport_products db table (name, internal_name, price)
   * selects only 'MP_MONTHLY', 'MP_QUARTERLY', 'MP_ANNUAL', 'RV_MONTHLY'
   * SubscriptionResource.getPlans()-> ClassroomDAO.findAvailablePlans()
   * After fetching aplies discounts if any
   */
  function getPlans() {
    axios({
      method: "get",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      url: `${process.env.REACT_APP_API}/subscription/plans`,
    })
      .then((res) => {
        dispatch({
          type: actions.PLANS,
          data: { plans: res.data },
        });
      })
      .catch(() => {
        // do nothing
      });
  }

  function getCreditCards() {
    dispatch({
      type: actions.WAITING_FOR_CREDIT_CARDS,
      data: { waiting: true },
    });
    axios({
      method: "get",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      url: `${process.env.REACT_APP_API}/subscription/credit-cards`,
    })
      .then((res) => {
        dispatch({
          type: actions.CREDIT_CARDS,
          data: { creditCards: res.data },
        });
      })
      .catch(() => {
        dispatch({
          type: actions.WAITING_FOR_CREDIT_CARDS,
          data: { waiting: false },
        });
      });
  }

  function addCreditCard(data, cb) {
    axios({
      method: "post",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      url: `${process.env.REACT_APP_API}/subscription/credit-cards`,
      data: data,
    })
      .then((res) => {
        dispatch({
          type: actions.CREDIT_CARDS,
          data: { creditCards: res.data },
        });
        if (cb) cb();
      })
      .catch((err) => {
        // TODO: handle errors
        if (cb) cb();
      });
  }

  function setDefaultCreditCard(id) {
    dispatch({
      type: actions.CHANGING_DEFAULT_CARD,
      data: { creditCards: state.creditCards, changing: true },
    });
    axios({
      method: "get",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      url: `${process.env.REACT_APP_API}/subscription/credit-cards/${id}/default`,
    })
      .then((res) => {
        dispatch({
          type: actions.CHANGING_DEFAULT_CARD,
          data: { creditCards: res.data, changing: false },
        });
      })
      .catch(() => {
        dispatch({
          type: actions.CHANGING_DEFAULT_CARD,
          data: { creditCards: state.creditCards, changing: false },
        });
      });
  }

  function getProration(selectedPlan) {
    axios({
      method: "get",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      url: `${process.env.REACT_APP_API}/subscription/proration?selected-plan=${selectedPlan}`,
    })
      .then((res) => {
        dispatch({
          type: actions.GET_PRORATION,
          data: { proration: res.data },
        });
      })
      .catch(() => {
        // do nothing
      });
  }

  const subStore = {
    subscription: state.subscription,
    subscriptionMessage: state.subscriptionMessage,
    waitingForSubscription: state.waitingForSubscription,
    plans: state.plans,
    creditCards: state.creditCards,
    waitingForCreditCards: state.waitingForCreditCards,
    changingDefaultCard: state.changingDefaultCard,
    proration: state.proration,
    addSubscription: addSubscription,
    changeSubscription: changeSubscription,
    cancelSubscription: cancelSubscription,
    resetSubscriptionMessage: resetSubscriptionMessage,
    addCreditCard: addCreditCard,
    setDefaultCreditCard: setDefaultCreditCard,
    getProration: getProration,
  };

  return { subStore };
}
