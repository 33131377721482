import React from "react";
import PropTypes from "prop-types";
import useRelatedContent from "../../../services/stores/RelatedContentStore";
import Document from "../../Search/components/Document";

export default function FeaturedIn(props) {
  const { loading, content } = useRelatedContent(props.token, props.recipeId);

  const plansTitle = content.plans.length === 1 ? "This recipe was featured in this meal plan:" : "This recipe was featured in these meal plans:";
  const plans = content.plans.length > 0 ? (
    <div className="row mt-5 mb-2 p-2 p-md-3 py-md-2 px-md-5 gx-0 d-print-none">
      <h5 className="h-alt text-orange">{plansTitle}</h5>
      <div className="p-2">
        <div className="row">
          {content.plans.map(p => (
            <Document
              key={p.documentId}
              authenticated={props.authenticated}
              member={props.member}
              stashStore={props.stashStore}
              modals={props.modals}
              document={p}
            />
          ))}
        </div>
      </div>
    </div>
  ) : null;

  const recipesTitle = content.recipes.length === 1 ? "This recipe was featured in this recipe:" : "This recipe was featured in these recipes:";
  const recipes = content.recipes.length > 0 ? (
    <div className="row mt-2 mb-2 p-2 p-md-3 py-md-2 px-md-5 gx-0 d-print-none">
      <h5 className="h-alt text-green">{recipesTitle}</h5>
      <div className="p-2 p-md-2">
        <div className="row">
          {content.recipes.map(r => (
            <Document
              key={r.documentId}
              authenticated={props.authenticated}
              member={props.member}
              stashStore={props.stashStore}
              modals={props.modals}
              document={r}
            />
          ))}
        </div>
      </div>
    </div>
  ) : null;

  return loading ? (
    <span className="spinner-border" />
  ) : (
    <div>
      {plans}
      {recipes}
    </div>
  );
}

FeaturedIn.propTypes = {
  authenticated: PropTypes.bool,
  member: PropTypes.object,
  stashStore: PropTypes.object,
  modals: PropTypes.object,
  token: PropTypes.string,
  recipeId: PropTypes.number,
}