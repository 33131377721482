import React from "react";
import {Link} from "react-router-dom";
import PropTypes from "prop-types";

export default function AnonymousCallToActions(props) {
  return (
    <div>{/*               Devlabs: we may not use this anymore, but let's wait to remove until my changes are done.   */}</div>
  );
}

AnonymousCallToActions.propTypes = {
  tellMeMoreLink: PropTypes.string,
  joinLink: PropTypes.string,
}