import React from "react";
import PropTypes from "prop-types";
import Helpers from "../../../services/Helpers";

export default function AllergenCheckbox(props) {
  const {isNotEmpty, capitalizeFirstLetter} = Helpers();

  const allergen = props.allergens.find(a => a.id === props.allergen.id);

  return (
    <div className="form-check">
      <label className="form-check-label">
        <input
          className="form-check-input cursor-pointer"
          type="checkbox"
          checked={isNotEmpty(allergen)}
          onChange={() => props.toggle(props.allergen.id)}
        /> {capitalizeFirstLetter(props.allergen.allergen)}
      </label>
    </div>
  )
}

AllergenCheckbox.propTypes = {
  allergens: PropTypes.array,
  allergen: PropTypes.object,
  toggle: PropTypes.func,
}