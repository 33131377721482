import React from 'react';
import PropTypes from 'prop-types';
import GroceryItem from './GroceryItem';
import GroceryItemForm from './GroceryItemForm';

export default function CustomGroceryItems(props) {
  const customItems = props.store.customItems.map((ci) => (
    <GroceryItem key={ci.id} item={ci} checked={ci.checked} element={<span>{ci.text}</span>} custom={true} toggle={props.store.updateCustomItem} delete={props.store.deleteCustomItem} />
  ));
  const customItemsTable =
    props.store.customItems.length === 0 ? null : (
      <div className="row p-2 pb-0">
        <div className="col-12">
          <div className="bg-white d-inline-block mt-0 p-2 pb-1 ms-md-3 text-md-center rounded-top strip-margin-print">
            <h5 className="h-alt margin-print">
              <i className="fa-solid fa-list-check me-1" />
              Custom Items
            </h5>
          </div>
        </div>
        <div className="table-responsive col-12 bg-white px-3 px-md-4 py-2 strip-margin-print">
          <table className="w-100">
            <tbody>{customItems}</tbody>
          </table>
        </div>
      </div>
    );

  return (
    <div>
      <GroceryItemForm createItem={props.store.createCustomItem} />
      {customItemsTable}
    </div>
  );
}

CustomGroceryItems.propTypes = {
  store: PropTypes.object,
};
