import React from "react";
import PropTypes from "prop-types";
import Helpers from "../../../services/Helpers";

export default function YieldAndLeftovers(props) {
  const {isNotEmpty} = Helpers();

  let result = null;

  if (isNotEmpty(props.yield)) {
    if (isNotEmpty(props.leftovers)) {
      result = <span className="text-muted print-yield">Yield: {props.yield}, Leftovers: {props.leftovers}</span>;
    } else {
      result = <span className="text-muted print-yield">Yield: {props.yield}</span>;
    }
  }

  return result;
}

YieldAndLeftovers.propTypes = {
  yield: PropTypes.string,
  leftovers: PropTypes.string,
}
