import "./GroceryItem.scss";
import React, {useState} from "react";
import PropTypes from "prop-types";
import Checkbox from "./Checkbox";

export default function GroceryItem(props) {
  const [working, setWorking] = useState(false);

  function lastColumn() {
    let result = null;
    if (props.custom) {
      result = working ? (
        <td className="text-end">
          <button className="btn btn-sm text-danger" type="button" disabled={true}><i className="far fa-minus-circle"/></button>
        </td>
      ) : (
        <td className="text-end">
          <button
            className="btn btn-sm"
            type="button"
            onClick={() => {
              setWorking(true);
              props.delete(props.item.id);
            }}
          >
            <i className="fas fa-minus-circle"/>
          </button>
        </td>
      );
    }
    return result;
  }

  return (
    <tr className="grocery-item">
      <td className="grocery-item-check align-top" style={{width: "25px"}}><Checkbox i={props.item.id} checked={props.checked} toggle={props.toggle} /></td>
      <td className="grocery-item-name align-top ps-2" colSpan={props.custom ? 1 : 2}>{props.element}</td>
      {lastColumn()}
    </tr>
  );
}

GroceryItem.propTypes = {
  item: PropTypes.object,
  checked: PropTypes.bool,
  element: PropTypes.element,
  custom: PropTypes.bool,
  toggle: PropTypes.func,
  delete: PropTypes.func,
}