import "./Home.scss";
import React, { useEffect, useState } from "react";
import Constants from "../../../services/Constants";
import HomepageFeaturedWizardPlans from "./components/HomepageFeaturedWizardPlans";
import HomepageComponent from "./components/HomepageComponent";
import HomepageBlank from "./components/HomepageBlank";
import Helpers from "../../../services/Helpers";
import { useLocation } from "react-router-dom";
import Footer from "../../components/Footer";

export default function Homepage({ homepageStore, authenticated, modals, member, stashStore, banner }) {
  let location = useLocation();
  const { homepageComponentTypes } = Constants();
  const { pageTitle } = Helpers();
  const [editMode, setEditMode] = useState(false);

  useEffect(() => {
    document.title = pageTitle(location.pathname);
  }, [location]);

  function renderRows() {
    const rows = [];
    const components = authenticated ? homepageStore.state.components.filter(c => c.showToMembers) : homepageStore.state.components.filter(c => c.showToAnonymousVisitors);
    for (let component of components) {
      if (component.componentType === homepageComponentTypes.BLANK) {
        const blank = homepageStore.state.blanks.components.find((b) => b.id === component.id);
        if (blank !== undefined) {
          rows.push(
            <HomepageBlank
              key={component.id}
              htmlContent={blank.htmlContent}
              visible={homepageStore.state.hiddenComponents.ids.indexOf(component.id) === -1}
              editMode={editMode}
              toggleVisibility={() => homepageStore.toggleComponentVisibility(component.id)}
            />
          );
        }
      } else if (component.componentType === homepageComponentTypes.COLLECTION) {
        const items = homepageStore.state.collections.items.filter((i) => i.cmsComponentId === component.cmsComponentId);
        if (items.length > 0) {
          rows.push(
            <HomepageComponent
              key={component.id}
              loading={homepageStore.state.collections.loading}
              component={component}
              items={items}
              visible={homepageStore.state.hiddenComponents.ids.indexOf(component.id) === -1}
              editMode={editMode}
              toggleVisibility={() => homepageStore.toggleComponentVisibility(component.id)}
              modals={modals}
              member={member}
            />
          );
        }
      } else if (component.componentType === homepageComponentTypes.COOKING_JOURNAL) {
        if (homepageStore.state.cookingJournal.items.length > 0) {
          rows.push(
            <HomepageComponent
              key={component.id}
              loading={homepageStore.state.cookingJournal.loading}
              component={component}
              items={homepageStore.state.cookingJournal.items}
              visible={homepageStore.state.hiddenComponents.ids.indexOf(component.id) === -1}
              editMode={editMode}
              toggleVisibility={() => homepageStore.toggleComponentVisibility(component.id)}
              modals={modals}
              member={member}
            />
          );
        }
      } else if (component.componentType === homepageComponentTypes.EXTERNAL_LINKS) {
        const items = homepageStore.state.externalLinks.items.filter((i) => i.cmsHomepageComponentId === component.id);
        if (items.length > 0) {
          rows.push(
            <HomepageComponent
              key={component.id}
              loading={homepageStore.state.externalLinks.loading}
              component={component}
              items={items}
              visible={homepageStore.state.hiddenComponents.ids.indexOf(component.id) === -1}
              editMode={editMode}
              toggleVisibility={() => homepageStore.toggleComponentVisibility(component.id)}
              member={member}
            />
          );
        }
      } else if (component.componentType === homepageComponentTypes.FEATURED_WIZARD_PLANS) {
        if (homepageStore.state.featuredWizardPlans.items.length > 0) {
          rows.push(
            <HomepageFeaturedWizardPlans
              key={component.id}
              loading={homepageStore.state.featuredWizardPlans.loading}
              component={component}
              items={homepageStore.state.featuredWizardPlans.items}
              visible={homepageStore.state.hiddenComponents.ids.indexOf(component.id) === -1}
              editMode={editMode}
              authenticated={authenticated}
              modals={modals}
              member={member}
              stashStore={stashStore}
              toggleVisibility={() => homepageStore.toggleComponentVisibility(component.id)}
            />
          );
        }
      } else if (component.componentType === homepageComponentTypes.INTERNAL_LINKS) {
        const items = homepageStore.state.internalLinks.items.filter((i) => i.cmsHomepageComponentId === component.id);
        if (items.length > 0) {
          rows.push(
            <HomepageComponent
              key={component.id}
              loading={homepageStore.state.internalLinks.loading}
              component={component}
              items={items}
              visible={homepageStore.state.hiddenComponents.ids.indexOf(component.id) === -1}
              editMode={editMode}
              toggleVisibility={() => homepageStore.toggleComponentVisibility(component.id)}
              member={member}
            />
          );
        }
      } else if (component.componentType === homepageComponentTypes.MEMBERS_RECIPE_PHOTOS) {
        if (homepageStore.state.membersRecipePhotos.items.length > 0) {
          rows.push(
            <HomepageComponent
              key={component.id}
              loading={homepageStore.state.membersRecipePhotos.loading}
              component={component}
              items={homepageStore.state.membersRecipePhotos.items}
              visible={homepageStore.state.hiddenComponents.ids.indexOf(component.id) === -1}
              editMode={editMode}
              toggleVisibility={() => homepageStore.toggleComponentVisibility(component.id)}
              modals={modals}
              member={member}
            />
          );
        }
      } else if (component.componentType === homepageComponentTypes.MOST_LOVED_RECIPES_THIS_WEEK) {
        if (homepageStore.state.mostLovedRecipesThisWeek.items.length > 0) {
          rows.push(
            <HomepageComponent
              key={component.id}
              loading={homepageStore.state.mostLovedRecipesThisWeek.loading}
              component={component}
              items={homepageStore.state.mostLovedRecipesThisWeek.items}
              visible={homepageStore.state.hiddenComponents.ids.indexOf(component.id) === -1}
              editMode={editMode}
              toggleVisibility={() => homepageStore.toggleComponentVisibility(component.id)}
              modals={modals}
              member={member}
            />
          );
        }
      } else if (component.componentType === homepageComponentTypes.NEW_AND_NOTEWORTHY_RECIPES) {
        if (homepageStore.state.newAndNoteworthyRecipes.items.length > 0) {
          rows.push(
            <HomepageComponent
              key={component.id}
              loading={homepageStore.state.newAndNoteworthyRecipes.loading}
              component={component}
              items={homepageStore.state.newAndNoteworthyRecipes.items}
              visible={homepageStore.state.hiddenComponents.ids.indexOf(component.id) === -1}
              editMode={editMode}
              toggleVisibility={() => homepageStore.toggleComponentVisibility(component.id)}
              modals={modals}
              member={member}
            />
          );
        }
      } else if (component.componentType === homepageComponentTypes.QUICK_LINKS) {
        rows.push(
          <HomepageComponent
            key={component.id}
            loading={homepageStore.state.quickLinks.loading}
            component={component}
            items={homepageStore.state.quickLinks.items}
            visible={true}
            editMode={false}
            member={member}
          />
        );
      } else if (component.componentType === homepageComponentTypes.RANDOM_MEMBERS_LOVED_ITEMS) {
        if (homepageStore.state.randomMembersLovedItems.items.length > 0) {
          rows.push(
            <HomepageComponent
              key={component.id}
              loading={homepageStore.state.randomMembersLovedItems.loading}
              component={component}
              items={homepageStore.state.randomMembersLovedItems.items}
              visible={homepageStore.state.hiddenComponents.ids.indexOf(component.id) === -1}
              editMode={editMode}
              toggleVisibility={() => homepageStore.toggleComponentVisibility(component.id)}
              modals={modals}
              member={member}
            />
          );
        }
      } else if (component.componentType === homepageComponentTypes.RANDOM_MEMBERS_SAVED_ITEMS) {
        if (homepageStore.state.randomMembersSavedItems.items.length > 0) {
          rows.push(
            <HomepageComponent
              key={component.id}
              loading={homepageStore.state.randomMembersSavedItems.loading}
              component={component}
              items={homepageStore.state.randomMembersSavedItems.items}
              visible={homepageStore.state.hiddenComponents.ids.indexOf(component.id) === -1}
              editMode={editMode}
              toggleVisibility={() => homepageStore.toggleComponentVisibility(component.id)}
              modals={modals}
              member={member}
            />
          );
        }
      } else if (component.componentType === homepageComponentTypes.TRENDING_THIS_WEEK) {
        if (homepageStore.state.trendingThisWeek.items.length > 0) {
          rows.push(
            <HomepageComponent
              key={component.id}
              loading={homepageStore.state.trendingThisWeek.loading}
              component={component}
              items={homepageStore.state.trendingThisWeek.items}
              visible={homepageStore.state.hiddenComponents.ids.indexOf(component.id) === -1}
              editMode={editMode}
              toggleVisibility={() => homepageStore.toggleComponentVisibility(component.id)}
              modals={modals}
              member={member}
            />
          );
        }
      }
    }
    return rows;
  }

  const content = homepageStore.state.components.length === 0 ? (
    <div className="text-center">
      <span className="spinner-border m-4" />
    </div>
  ) : (
    <>
      {renderRows()}
      {authenticated ? (
        <div className="row my-3">
          <div className="col-12 text-center">
            <button className="btn btn-white" type="button" onClick={() => setEditMode(!editMode)}>
              {editMode ? "Done customizing" : "Customize"}
            </button>
          </div>
        </div>
      ) : null}
    </>
  );

  return (
    <>
      <main className="container-lg homepage-container bg-white">
        {banner}
        {content}
      </main>
      <Footer />
    </>
  );
}
