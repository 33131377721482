import "./Tile.css";
import React from "react";
import PropTypes from "prop-types";
import {Link} from "react-router-dom";
import Helpers from "../../../services/Helpers";

export default function Tile(props) {
  const {assetUrl, isNotEmpty} = Helpers()

  const img = props.t.imageKey ?
    <img
      src={props.t.imageKey ? assetUrl(props.t.imageKey) : ""}
      className="card-img-top rounded-top scaled-tile-image"
      alt={props.t.title}
    /> 
    : null;
  const text = props.t.text ? <p className="card-text text-muted">{props.t.text}</p> : null;
  function content() {
    let result;
    if (img) {
      result = (
        <>
          {img}
          <div className="card-body p-2">
            <h3 className={`card-title${text ? " " : " mb-0"}`}>{props.t.title}</h3>
            {text}
  </div>
        </>
      );
    } else if (text) {
      result = (
        <div className="card-body row align-items-end">
          <div className="col-12">
            <h3 className="card-title">{props.t.title}</h3>
          </div>
          <div className="col-12">
            {text}
          </div>
        </div>
      );
    } else {
      result = (
        <div className="card-body row align-items-center">
          <div className="col-12">
            <h3 className="card-title mb-0">{props.t.title}</h3>
          </div>
        </div>
      );
    }
    return result;
  }

  function className() {
    let result = "tile col mb-4";
    if (props.t.cssClass) {
      result = `tile ${props.t.cssClass} col mb-4`;
    }
    return result;
  }

  function link() {
    let result = "#";
    if (isNotEmpty(props.t.link)) {
      result = props.t.link.indexOf("http") !== -1 ? props.t.link : `/${props.t.link}`;
    }
    return result;
  }

  return (
    <div className={className()}>
      <Link to={link()} className="card-link">
        <div className="card h-100 text-center brighten border-0 rounded shadow-sm">
          {content()}
        </div>
      </Link>
    </div>
  );
}

Tile.propTypes = {
  t: PropTypes.object,
}