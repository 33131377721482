import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { Link, Redirect, useLocation } from "react-router-dom";
import MembershipAdvantages from "./components/MembershipAdvantages";

export default function MembersOnly(props) {
  const location = useLocation();
  const [waiting, setWaiting] = useState(true);
  const [redirect, setRedirect] = useState(false);

  useEffect(() => {
    setTimeout(() => {
      setWaiting(false);
    }, 2000);
  }, []);

  function main() {
    if (redirect) {
      return (
        <Redirect
          to={{
            pathname: "/sign-in",
            state: { from: location },
          }}
        />
      );
    } else {
      if (props.authenticated) {
        return <MembershipAdvantages title="Activate a membership to view this content" isAuthenticated={true} />;
      } else {
        return <MembershipAdvantages title="Become a member to view this content" isAuthenticated={false} />;
      }
    }
  }

  return waiting ? (
    <main className="text-center">
      <span className="spinner-border" />
    </main>
  ) : (
    main()
  );
}

MembersOnly.propTypes = {
  authenticated: PropTypes.bool,
};
